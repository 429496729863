import { useMemo, useState } from 'react';

interface IAlertState {
    type: string;
    message: string;
}

export const useAlert = () => {
    const [alert, setAlert] = useState<IAlertState | null>(null);
    const showAlert = (type: string, message: string) => {
        setAlert({ type, message });
    };

    const clearAlert = () => {
        setAlert(null);
    };

    return useMemo(
        () => ({
            alert,
            showAlert,
            clearAlert
        }),
        [alert]
    );
};
