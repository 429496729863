import React from 'react';
import type { ISelect } from './type';

const SelectAll = ({ title, name, value, options, handleChange }: ISelect) => {
    return (
        <div className="mb-3">
            <label className="form-label">{title}</label>
            <select
                className="form-select"
                name={name}
                value={value}
                onChange={handleChange}
                size={options.length > 0 ? options.length : 1}>
                {options.map((option) => {
                    return (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};

export default SelectAll;
