import React, { useMemo } from 'react';
import AdvancedPage from '../../components/Table/AdvancedPage/AdvancedPage';
import { COLUMNS } from './TenantColumns';
import { Permissions } from '../../utils/rules';
import AllowLayout from '../../Layouts/AllowLayout';

const Tenants = () => {
    const columns = useMemo(() => COLUMNS, []);

    return (
        <main className="content">
            <AllowLayout
                children={
                    <AdvancedPage
                        pageHeader={'Тенанты'}
                        btnAddUrl={'/admin/tenants/create'}
                        btnAddPermissions={Permissions.WRITE_CLIENT_TENANT}
                        btnDelPermissions={Permissions.DELETE_CLIENT_TENANT}
                        url={window.env.REACT_APP_API_URL + '/api/tenants'}
                        columns={columns}
                    />
                }
                permissions={Permissions.READ_CLIENT_TENANT}
            />
        </main>
    );
};

export default Tenants;
