import { IPageOptions, IDataClientsTree } from '../types';

export const DATA = (data: IDataClientsTree[]) => {
    //   let data = [
    //     {
    //       id: 1,
    //       name: num,
    //     },
    //     {
    //       id: 2,
    //       name: "B",
    //     },
    //   ];

    //   return data;

    let clientTree: IPageOptions[] = [];

    const buildTree = (
        item: IDataClientsTree,
        treeStr: string,
        arrParents: boolean[],
        upd: IDataClientsTree[]
    ) => {
        clientTree = [
            ...clientTree,
            { id: item.id, name: treeStr + (treeStr ? ' ' : '') + item.name }
        ];

        // setClientTree((clientTree) => [
        //   ...clientTree,
        //   { id: item.id, name: treeStr + (treeStr ? " " : "") + item.name },
        // ]);

        if (arrParents[item.id as number] != null) {
            treeStr = '----' + treeStr;

            for (const m in upd) {
                if (item.id === upd[m].parent_id) {
                    buildTree(upd[m], treeStr, arrParents, upd);
                }
            }
        }
    };

    const upd = data.sort((a, b) => {
        // return a.parent_id > b.parent_id ? 1 : -1;
        return a.parent_id > b.parent_id
            ? 1
            : a.parent_id < b.parent_id
            ? -1
            : a.name > b.name
            ? 1
            : a.name < b.name
            ? -1
            : 0;
    });
    // setClientTree(upd);

    const arrParents: boolean[] = [];
    upd.map((item) => {
        arrParents[item.parent_id] = true;
        return item;
    });

    const treeStr = '';
    upd.map((item) => {
        if (!item.parent_id) {
            buildTree(item, treeStr, arrParents, upd);
        }
        return item;
    });

    return clientTree;
};
