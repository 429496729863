import React, { useMemo } from 'react';
import AdvancedPage from '../../components/Table/AdvancedPage/AdvancedPage';
import { COLUMNS } from './TroubleColumns';
import { Permissions } from '../../utils/rules';
import AllowLayout from '../../Layouts/AllowLayout';

const Troubles = () => {
    const columns = useMemo(() => COLUMNS, []);

    return (
        <main className="content">
            <AllowLayout
                children={
                    <AdvancedPage
                        pageHeader={'Списки проблем'}
                        btnAddUrl={'/admin/troubles/create'}
                        btnAddPermissions={Permissions.WRITE_SUPPORT_TROUBLE}
                        btnDelPermissions={Permissions.DELETE_SUPPORT_TROUBLE}
                        url={window.env.REACT_APP_API_URL + '/api/troubles'}
                        columns={columns}
                    />
                }
                permissions={Permissions.WRITE_SUPPORT_SOLUTION}
            />
        </main>
    );
};

export default Troubles;
