import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Input from '../../components/Ui/components-form/Input';
import Select from '../../components/Ui/components-form/Select';
import Alert from '../../components/Ui/components-ui/Alert';
import { DATA } from '../clients/ClientTree';
import { Eventinfo, IHeaders, IPageOptions, ITabs, TId } from '../types';
import { getFetchData, postFetchData, updateFetchData } from '../../api';
import Tab from '../../components/Ui/components-ui/Tab';
import Tabs from '../../components/Ui/components-ui/Tabs';
import { loadingData } from '../../utils/utils';
import { TTab } from '../sensors/type';
import TabContent from '../../components/Ui/components-ui/TabContent';
import ConnectorsHeader from './connectorsHeader/ConnectorsHeader';
import { uid } from '../../utils/id';
import { clients, connectors } from '../../api/mockUrls';
import AllowLayout from '../../Layouts/AllowLayout';
import { Permissions } from '../../utils/rules';
import JsonDisplay from './body/JsonDisplay';
import JsonEditor from './JsonEditor';

const connectorTabs: ITabs[] = [
    { id: 0, name: 'Main' },
    { id: 1, name: 'Headers' },
    { id: 2, name: 'Body' },
    { id: 3, name: 'Token' }
];

interface IConnectors {
    name: string;
    client_id: number;
    url: string;
    comment: string;
    token: string;
    headers: IHeaders[];
    matching: IHeaders[];
    body: '';
    flag: boolean;
    dump_flag: null;
}

const ConnectorEdit = () => {
    const history = useHistory();
    const location = useLocation();
    const initialTab = location.hash.replace('#tab-', '');
    const [activeTab, setActiveTab] = useState<string | null>(initialTab);

    const { id } = useParams<TId>();
    const [isLoaded, setIsLoaded] = useState(true);
    const [connectorHeaders, setConnectorHeaders] = useState<IHeaders>({
        id: uid(),
        header: '',
        value: ''
    });

    const [matchingHeaders, setMatchingHeaders] = useState<IHeaders>({
        id: uid(),
        header: '',
        value: ''
    });

    const [connector, setConnector] = useState<IConnectors>({
        name: '',
        client_id: 0,
        url: '',
        comment: '',
        token: '',
        headers: [],
        matching: [],
        body: '',
        flag: false,
        dump_flag: null
    });

    const [bodies, setBodies] = useState('');
    const [example, setExample] = useState('');
    const [checkConnector, setCheckConnector] = useState({});
    const [clientTree, setClientTree] = useState<IPageOptions[]>([]);
    const clientOptions = useMemo(() => DATA, []);
    const [errors, setErrors] = useState<string[]>([]);
    const [jsonError, setJsonError] = useState('');
    const [alert, setAlert] = useState({
        type: 'd-none',
        message: ''
    });

    const [data, setData] = useState('');

    const transferToArray = (headers: string) => {
        if (headers === null) return;
        const array: IHeaders[] = [];
        const newArrValues: string[] = Object.values(headers);
        const newArrHeaders: string[] = Object.keys(headers);
        for (let i = 0; i <= newArrHeaders.length - 1; i++) {
            array.push({ id: uid(), header: newArrHeaders[i], value: newArrValues[i] });
        }

        return array;
    };

    const transferToObject = (obj: IHeaders, keys: any[]) => {
        let head = [];

        if (obj.header !== '' && obj.value !== '') {
            head = !keys ? [obj] : [...keys, obj];
        } else {
            head = keys;
        }

        const headers = head?.reduce((result: { [key: string]: string }, { header, value }) => {
            result[header] = value;
            return result;
        }, {});

        return JSON.stringify(headers);
    };

    // Preloader
    useEffect(() => {
        loadingData(isLoaded);
    }, [isLoaded]);

    useEffect(() => {
        setIsLoaded(false);
        getFetchData(`${connectors}/${id}/`)
            .then((response) => {
                const headers = response.headers && JSON.parse(response.headers.split(', '));
                const matching = response.matching && JSON.parse(response.matching.split(', '));
                const body = response.body;
                const preview = response.preview;

                setConnector({
                    ...response,
                    headers: transferToArray(headers),
                    matching: transferToArray(matching),
                    body,
                    flag: response.flag
                });
                setBodies(body);
                setCheckConnector(JSON.parse(preview));
                setExample(JSON.parse(response.example));
                setData(response?.comment ?? '');
            })
            .then(() => setIsLoaded(true));

        getFetchData(`${clients}`)
            .then((response) => {
                if (!response?.length) {
                    setClientTree([{ id: 0, name: 'Не выбран' }]);
                    return;
                }
                setClientTree(clientOptions(response));
            })
            .then(() => setIsLoaded(true));
    }, [clientOptions, id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        e.preventDefault();
        const value = e.target.value;
        const name = e.target.name;
        setConnector({ ...connector, [name]: value });
    };

    const commentChange = (event: Eventinfo, editor: ClassicEditor) => {
        const data = editor.getData();
        setConnector({ ...connector, comment: data });
    };

    const checkboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked;
        const name = event.target.name;
        setConnector({ ...connector, [name]: value });
    };

    const handleChangeNewInput = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        setState: React.Dispatch<React.SetStateAction<IHeaders>>,
        state: IHeaders
    ) => {
        e.preventDefault();
        const value = e.target.value;
        const name = e.target.name;

        const updatedState = {
            ...state,
            id: uid(),
            [name]: value
        };

        setState(updatedState);

        if (value !== '') {
            setErrors([]);
        }
    };

    const handleAddHeaderInput = (
        e: React.MouseEvent<HTMLButtonElement>,
        headersState: IHeaders,
        setHeadersState: React.Dispatch<React.SetStateAction<IHeaders>>,
        headersKey: 'headers' | 'matching'
    ) => {
        e.preventDefault();

        if (headersState.header !== '' && headersState.value !== '') {
            setConnector((prevConnector) => ({
                ...prevConnector,
                [headersKey]: prevConnector[headersKey]
                    ? prevConnector[headersKey].concat(headersState)
                    : [headersState]
            }));

            setHeadersState({
                id: '',
                header: '',
                value: ''
            });
        }

        const errs = [];
        if (!headersState.header) errs.push('header');
        if (!headersState.value) errs.push('value');
        setErrors(errs);
        if (errs.length > 0) return false;
    };
    const handleEditHeaders = (
        e: React.ChangeEvent<HTMLInputElement>,
        name: string,
        id: string
    ) => {
        setConnector({
            ...connector,
            headers: connector.headers.map((item) => {
                if (item.id === id && e.target.name === name) {
                    return { ...item, [name]: e.target.value };
                } else {
                    return item;
                }
            })
        });
    };

    const handleEditMatching = (
        e: React.ChangeEvent<HTMLInputElement>,
        name: string,
        id: string
    ) => {
        setConnector({
            ...connector,
            matching: connector.matching.map((item) => {
                if (item.id === id && e.target.name === name) {
                    return { ...item, [name]: e.target.value };
                } else {
                    return item;
                }
            })
        });
    };

    const deleteHeaders = async (
        ids: string,
        itemsState: IHeaders[],
        setItemsState: (value: ((prevState: IHeaders) => IHeaders) | IHeaders) => void,
        key: 'headers' | 'matching'
    ) => {
        setConnector((prevConnector) => ({
            ...prevConnector,
            [key]: prevConnector[key].filter((item) => item.id !== ids)
        }));
    };

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!jsonError) {
            const errs = [];
            if (!connector.name) errs.push('name');
            if (!Number(connector.client_id)) errs.push('client_id');
            if (!connector.url) errs.push('url');

            setErrors(errs);
            if (errs.length > 0) return false;

            const date = new Date();
            const formattedDate = date.toISOString();

            const data = {
                ...connector,
                created_at: formattedDate,
                updated_at: formattedDate,
                headers: transferToObject(connectorHeaders, connector.headers),
                matching: transferToObject(matchingHeaders, connector.matching),
                body: bodies,
                preview: JSON.stringify(checkConnector),
                example: JSON.stringify(example)
            };

            updateFetchData(`${connectors}/${id}/`, data)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        history.push({
                            pathname: '/admin/connectors/'
                        });
                    }
                })
                .catch(() => {
                    setAlert({
                        type: 'alert-danger',
                        message: 'Не удалось создать запись'
                    });
                });

            setAlert({
                type: 'd-none',
                message: ''
            });
        } else {
            setAlert({
                type: 'alert-danger',
                message: 'Не удалось создать запись'
            });
        }
    };

    const onClickTab = (tab: TTab) => {
        setActiveTab(tab.target);
    };

    const hasError = (key: number | string) => {
        return errors.indexOf(String(key as number)) !== -1;
    };

    const onPreview = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (!jsonError) {
            setIsLoaded(false);

            const data = {
                matching: transferToObject(matchingHeaders, connector.matching),
                body: bodies,
                example: JSON.stringify(example)
            };

            postFetchData('/api/connectors/check/json', data)
                .then((response) => response.json())
                .then((res) => {
                    if (res) {
                        setCheckConnector(res);
                        setIsLoaded(true);
                    }
                })
                .catch(() => {
                    setAlert({
                        type: 'alert-danger',
                        message: 'Не удалось создать запись'
                    });
                    setIsLoaded(true);
                });
            setAlert({
                type: 'd-none',
                message: ''
            });
        } else {
            setAlert({
                type: 'alert-danger',
                message: 'Не удалось создать запись'
            });
        }
    };

    const handleDataClick = (data: string) => {
        setJsonError(data);
    };

    return (
        <main className="content">
            <AllowLayout
                children={
                    <>
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-sm-block">
                                    <h3>Коннектор</h3>
                                </div>
                                <div className="col-auto ms-auto text-end mt-n1" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-7 col-xl-7">
                                <div className="tab">
                                    <Tabs>
                                        {connectorTabs.map((tab) => {
                                            return (
                                                <Tab
                                                    key={tab.id}
                                                    onClickTab={onClickTab}
                                                    label={tab.name}
                                                    tab={tab.id}
                                                    param={location.search}
                                                    activeTab={Number(activeTab)}
                                                />
                                            );
                                        })}
                                    </Tabs>
                                    <form onSubmit={onSubmit}>
                                        <Alert
                                            type={`mt-3 ${alert.type}`}
                                            message={alert.message}
                                        />
                                        <TabContent activeContent={Number(activeTab)}>
                                            <div className="tab-pane" id="tab-0" role="tabpanel">
                                                <Input
                                                    title=""
                                                    name="comment"
                                                    type="hidden"
                                                    value={connector.comment || ''}
                                                    errorDiv={'d-none'}
                                                    errorMsg={''}
                                                />
                                                <Input
                                                    className={hasError('name') ? 'is-invalid' : ''}
                                                    title="Название"
                                                    name="name"
                                                    type="text"
                                                    value={connector.name || ''}
                                                    handleChange={handleChange}
                                                    errorDiv={
                                                        hasError('name') ? 'text-danger' : 'd-none'
                                                    }
                                                    errorMsg={'Поле не может быть пустым'}
                                                    required={true}
                                                />
                                                <Select
                                                    title="Клиент"
                                                    name="client_id"
                                                    value={connector.client_id || 0}
                                                    options={clientTree}
                                                    handleChange={handleChange}
                                                    className={
                                                        hasError('client_id') ? 'is-invalid' : ''
                                                    }
                                                    required={true}
                                                />
                                                <Input
                                                    className={hasError('url') ? 'is-invalid' : ''}
                                                    title="Url"
                                                    name="url"
                                                    type="text"
                                                    value={connector.url || ''}
                                                    handleChange={handleChange}
                                                    errorDiv={
                                                        hasError('url') ? 'text-danger' : 'd-none'
                                                    }
                                                    errorMsg={'Поле не может быть пустым'}
                                                    required={true}
                                                />
                                                <div className="mb-3">
                                                    <label className="form-label">
                                                        Комментарий
                                                    </label>
                                                    <CKEditor
                                                        id="comment"
                                                        name="comment"
                                                        data={data}
                                                        editor={ClassicEditor}
                                                        config={{
                                                            toolbar: [
                                                                'Bold',
                                                                'Italic',
                                                                '|',
                                                                'NumberedList',
                                                                'BulletedList',
                                                                '|',
                                                                'Link',
                                                                '|',
                                                                'Undo',
                                                                'Redo'
                                                            ]
                                                        }}
                                                        onReady={(editor) => {
                                                            editor.editing.view.change((writer) => {
                                                                writer.setStyle(
                                                                    'height',
                                                                    '100px',
                                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                                    // @ts-ignore
                                                                    editor.editing.view.document.getRoot()
                                                                );
                                                            });
                                                        }}
                                                        onChange={commentChange}
                                                    />
                                                </div>
                                                <div className="row">
                                                    <span className="col-auto pr-0">
                                                        <input
                                                            name="flag"
                                                            className="form-check"
                                                            type="checkbox"
                                                            id="flag"
                                                            checked={connector.flag ?? false}
                                                            onChange={checkboxChange}
                                                        />
                                                    </span>
                                                    <label
                                                        className="form-label col-9"
                                                        htmlFor="flag">
                                                        <span className="pr-1">Активна</span>
                                                    </label>
                                                </div>
                                                <div className="row mb-3">
                                                    <span className="col-auto pr-0">
                                                        <input
                                                            name="dump_flag"
                                                            className="form-check"
                                                            type="checkbox"
                                                            id="dump_flag"
                                                            checked={
                                                                Boolean(connector.dump_flag) ??
                                                                false
                                                            }
                                                            onChange={checkboxChange}
                                                        />
                                                    </span>
                                                    <label
                                                        className="form-label col-9"
                                                        htmlFor="dump_flag">
                                                        <span className="pr-1">
                                                            Дамп ответа сервера
                                                        </span>
                                                    </label>
                                                </div>
                                                * — обязательные поля
                                            </div>
                                            <div className="tab-pane" id="tab-1" role="tabpanel">
                                                <ConnectorsHeader
                                                    connectorHeaders={connectorHeaders}
                                                    addHeaders={(e) =>
                                                        handleAddHeaderInput(
                                                            e,
                                                            connectorHeaders,
                                                            setConnectorHeaders,
                                                            'headers'
                                                        )
                                                    }
                                                    deleteHeaders={(ids) =>
                                                        deleteHeaders(
                                                            ids,
                                                            connector.headers,
                                                            setConnectorHeaders,
                                                            'headers'
                                                        )
                                                    }
                                                    handleChangeNewInput={(
                                                        e: React.ChangeEvent<
                                                            HTMLInputElement | HTMLSelectElement
                                                        >
                                                    ) =>
                                                        handleChangeNewInput(
                                                            e,
                                                            setConnectorHeaders,
                                                            connectorHeaders
                                                        )
                                                    }
                                                    hasError={hasError}
                                                    handleEditInput={handleEditHeaders}
                                                    connector={connector.headers}
                                                />
                                            </div>
                                            <div className="tab-pane" id="tab-2" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h4 className="mb-3">1. Body:</h4>
                                                        <JsonDisplay
                                                            body={bodies}
                                                            setBodies={setBodies}
                                                            hasError={hasError}
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <h4 className="mt-2 mb-3">2. Matching:</h4>
                                                        <ConnectorsHeader
                                                            connectorHeaders={matchingHeaders}
                                                            addHeaders={(e) =>
                                                                handleAddHeaderInput(
                                                                    e,
                                                                    matchingHeaders,
                                                                    setMatchingHeaders,
                                                                    'matching'
                                                                )
                                                            }
                                                            deleteHeaders={(ids) =>
                                                                deleteHeaders(
                                                                    ids,
                                                                    connector.matching,
                                                                    setMatchingHeaders,
                                                                    'matching'
                                                                )
                                                            }
                                                            handleChangeNewInput={(
                                                                e: React.ChangeEvent<
                                                                    | HTMLInputElement
                                                                    | HTMLSelectElement
                                                                >
                                                            ) =>
                                                                handleChangeNewInput(
                                                                    e,
                                                                    setMatchingHeaders,
                                                                    matchingHeaders
                                                                )
                                                            }
                                                            hasError={hasError}
                                                            handleEditInput={handleEditMatching}
                                                            connector={connector.matching}
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <h4
                                                            className={`mt-2 mb-3 ${
                                                                hasError('example')
                                                                    ? 'is-invalid'
                                                                    : ''
                                                            }`}>
                                                            3. Example:
                                                        </h4>
                                                        <JsonEditor
                                                            value={example}
                                                            setValue={setExample}
                                                            readOnly={false}
                                                            setError={handleDataClick}
                                                        />
                                                        {jsonError && (
                                                            <div className="text-danger mt-3">
                                                                {jsonError}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-12 mt-3">
                                                        <button
                                                            onClick={(e) => onPreview(e)}
                                                            className="btn btn-primary">
                                                            preview
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane" id="tab-3" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <Input
                                                            title="Токен"
                                                            name="token"
                                                            type="text"
                                                            value={connector?.token || ''}
                                                            handleChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabContent>
                                        <div className="mt-3">
                                            <button type="submit" className="btn btn-primary">
                                                Сохранить
                                            </button>{' '}
                                            <Link
                                                to="/admin/connectors"
                                                className="btn btn-outline-secondary">
                                                Назад
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-5 col-xl-5 mt-5">
                                {checkConnector && (
                                    <JsonEditor value={checkConnector} readOnly={true} />
                                )}
                            </div>
                        </div>
                    </>
                }
                permissions={Permissions.READ_INTEGRATION_CONNECTOR}
            />
        </main>
    );
};

export default ConnectorEdit;
