import React, { useState, useEffect } from 'react';
import './toast.css';
import { IAlert } from '../../../types/contextType';

const Toast = ({ message, type }: IAlert) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(true);
        const timeout = setTimeout(() => {
            setVisible(false);
        }, 3000);
        return () => clearTimeout(timeout);
    }, [message]);

    return (
        <>
            <div className={`notification ${visible ? 'show' : ''} alert ${type}`}>
                <div className="alert-message">{message}</div>
            </div>
        </>
    );
};

export default Toast;
