import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import { IColumnFilterBattery } from './type';

export const ColumnFilter = ({ column, onChangeFilter }: IColumnFilterBattery) => {
    const [value, setValue] = useState<string>();

    const onChangeInput = useAsyncDebounce((value) => {
        onChangeFilter(value);
    }, 2000);

    return (
        <div>
            <input
                placeholder="Поиск по всем устройствам"
                type="text"
                className="form-control"
                value={value || ''}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChangeInput({ key: column.id, value: e.target.value });
                }}
            />
        </div>
    );
};
