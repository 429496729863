import React, { FC, memo } from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Input from '../../components/Ui/components-form/Input';
import { Eventinfo, IRules } from '../types';

interface IRulesEditMainProps {
    rule: IRules;
    setRule: (arr: IRules) => void;
    hasError: (key: string) => boolean;
}

const RulesEditMain: FC<IRulesEditMainProps> = ({ rule, setRule, hasError }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        e.preventDefault();
        const value = e.target.value;
        const name = e.target.name;
        setRule({ ...rule, [name]: value });
    };

    const commentChange = (event: Eventinfo, editor: ClassicEditor) => {
        const data = editor.getData();
        setRule({ ...rule, comment: data });
    };

    return (
        <>
            <Input
                title=""
                name="comment"
                type="hidden"
                value={rule?.comment || ''}
                errorDiv={'d-none'}
                errorMsg={''}
            />
            <Input
                className={hasError('name') ? 'is-invalid' : ''}
                title="Название"
                name="name"
                type="text"
                value={rule?.name || ''}
                handleChange={handleChange}
                errorDiv={hasError('name') ? 'text-danger' : 'd-none'}
                errorMsg={'Поле не может быть пустым'}
                required={true}
            />
            <div className="mb-3">
                <label className="form-label">Комментарий</label>
                <CKEditor
                    id="comment"
                    name="comment"
                    data={rule?.comment || ''}
                    editor={ClassicEditor}
                    config={{
                        toolbar: [
                            'Bold',
                            'Italic',
                            '|',
                            'NumberedList',
                            'BulletedList',
                            '|',
                            'Link',
                            '|',
                            'Undo',
                            'Redo'
                        ]
                    }}
                    onReady={(editor) => {
                        editor.editing.view.change((writer) => {
                            writer.setStyle(
                                'height',
                                '100px',
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                editor.editing.view.document.getRoot()
                            );
                        });
                    }}
                    onChange={commentChange}
                />
            </div>
            * — обязательные поля
        </>
    );
};

export default memo(RulesEditMain);
