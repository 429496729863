import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ProtectedRoute } from './components/ProtectedRoute';
import LoginLayout from './Layouts/LoginLayout';
import auth from './components/auth';
import DefaultLayout from './Layouts/DefaultLayout';
import ReconnectingWebSocket from 'reconnecting-websocket';

const ws = new ReconnectingWebSocket(`${window.env.REACT_APP_WS_URL}`, [], {
    connectionTimeout: window.env.REACT_APP_WS_RECONNECT
});

function App() {
    auth.checkJwtToken();

    useEffect(() => {
        const handleUnload = () => {
            console.log('Leaving');

            ws.send(
                JSON.stringify({
                    action: 'left'
                })
            );
        };

        window.addEventListener('beforeunload', handleUnload);

        ws.onopen = () => console.log('Successfully connected');
        ws.onclose = () => console.log('Connection closed');
        ws.onerror = () => console.log('There was an error');
        ws.onmessage = (msg) => {
            const data = JSON.parse(msg.data);
            console.log('Action is', data.action);

            switch (data.action) {
                case 'list_users':
                    if (data.connected_users) {
                        if (data.connected_users.length > 0) {
                            console.log(data.connected_users);
                        }
                    }
                    break;

                case 'broadcast':
                    console.log(data.message);
                    break;

                default:
                    //
                    break;
            }
        };

        return () => window.removeEventListener('beforeunload', handleUnload);
    }, []);

    return (
        <Router>
            <Switch>
                <Route path="/login" exact component={() => <LoginLayout />} />
                <ProtectedRoute path={'/admin/'} component={DefaultLayout} ws={ws} />
                <Redirect from="*" to="/login" />
            </Switch>
        </Router>
    );
}

export default App;
