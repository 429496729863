import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Input from '../../components/Ui/components-form/Input';
import Select from '../../components/Ui/components-form/Select';
import Alert from '../../components/Ui/components-ui/Alert';
import { Permissions, User, Rules } from '../../utils/rules';
import { AbacProvider, AllowedTo } from 'react-abac';
import { ticketsCreate } from '../../api/mockUrls';
import { ITickets } from './types';
import { Eventinfo } from '../types';
import { getFetchData, postFetchData } from '../../api';

const TicketCreate = () => {
    const [role, setRole] = useState('');
    const [isLoaded, setIsLoaded] = useState(true);

    useEffect(() => {
        const storage = localStorage.getItem('datanorm.user');
        setRole(storage ? storage.toUpperCase() : 'USER');

        setIsLoaded(false);
        getFetchData('/api/tickets/statuses/list').then((response) => {
            setIsLoaded(true);
            setStatusesOptions(response);
        });
    }, []);

    const history = useHistory();
    const [ticket, setTicket] = useState<ITickets>({
        uid: '',
        status_id: '1',
        action: 'create_ticket',
        address: 'г. Москва, Бережковская набережная, д. 20, стр. 35'
    });
    const [errors, setErrors] = useState<string[]>([]);
    const [alert, setAlert] = useState({
        type: 'd-none',
        message: ''
    });
    const [statusesOptions, setStatusesOptions] = useState([]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setTicket({ ...ticket, [name]: value });
    };

    const commentChange = (event: Eventinfo, editor: ClassicEditor) => {
        const data = editor.getData();
        setTicket({ ...ticket, comment: data });
    };

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errs: string[] = [];
        if (ticket.uid === '') errs.push('uid');

        setErrors(errs);
        if (errs.length > 0) return false;

        const data = new FormData(e.target);
        const payload = Object.fromEntries(data.entries());

        postFetchData(ticketsCreate, payload)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    history.goBack();
                }
            })
            .catch(() => {
                setAlert({
                    type: 'alert-danger',
                    message: 'Не удалось создать запись'
                });
            });
    };

    const hasError = (key: number | string) => {
        return errors.indexOf(String(key as number)) !== -1;
    };

    return (
        <main className="content">
            <div className="container-fluid p-0">
                <div className="row mb-2 mb-xl-3">
                    <div className="col-auto d-sm-block">
                        <h3>Новая заявка</h3>
                    </div>
                    <div className="col-auto ms-auto text-end mt-n1" />
                </div>
            </div>
            <form onSubmit={onSubmit}>
                <div className="col-md-6 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <Alert type={alert.type} message={alert.message} />
                            <Input name="comment" type="hidden" value={ticket.comment || ''} />
                            <Input name="action" type="hidden" value={ticket.action || ''} />
                            <Input
                                className={hasError('uid') ? 'is-invalid' : ''}
                                title="Номер заявки"
                                name="uid"
                                type="text"
                                value={ticket.uid || ''}
                                handleChange={handleChange}
                                errorDiv={hasError('uid') ? 'text-danger' : 'd-none'}
                                errorMsg={'Поле не может быть пустым'}
                                required={true}
                            />
                            <Input
                                className={hasError('address') ? 'is-invalid' : ''}
                                title="Адрес"
                                name="address"
                                type="text"
                                value={ticket.address || ''}
                                handleChange={handleChange}
                                errorDiv={hasError('address') ? 'text-danger' : 'd-none'}
                                errorMsg={'Поле не может быть пустым'}
                            />
                            <Select
                                title="Статус"
                                name="status_id"
                                value={ticket.status_id || ''}
                                options={statusesOptions}
                                handleChange={handleChange}
                                className={hasError('status') ? 'is-invalid' : ''}
                                isLoaded={isLoaded}
                            />
                            <div className="mb-3">
                                <label className="form-label">Комментарий</label>
                                <CKEditor
                                    id="comment"
                                    name="comment"
                                    data=""
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: [
                                            'Bold',
                                            'Italic',
                                            '|',
                                            'NumberedList',
                                            'BulletedList',
                                            '|',
                                            'Link',
                                            '|',
                                            'Undo',
                                            'Redo'
                                        ]
                                    }}
                                    onReady={(editor) => {
                                        editor.editing.view.change((writer) => {
                                            writer.setStyle(
                                                'height',
                                                '100px',
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                editor.editing.view.document.getRoot()
                                            );
                                        });
                                    }}
                                    onChange={commentChange}
                                />
                            </div>
                            * — обязательные поля
                        </div>
                    </div>
                </div>

                <AbacProvider user={User} roles={[role]} rules={Rules}>
                    <AllowedTo perform={Permissions.WRITE_SUPPORT_TICKET}>
                        <button type="submit" className="btn btn-primary">
                            Сохранить
                        </button>{' '}
                    </AllowedTo>
                </AbacProvider>

                <Link to="/admin/tickets/list" className="btn btn-outline-secondary">
                    Назад
                </Link>
            </form>
        </main>
    );
};

export default TicketCreate;
