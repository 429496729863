import React from 'react';

interface IChild {
    children: JSX.Element | JSX.Element[];
}

const Tabs = ({ children }: IChild) => {
    return (
        <ul className="nav nav-tabs" role="tablist">
            {children}
        </ul>
    );
};

export default Tabs;
