import { Cell } from 'react-table';
import { Link } from 'react-router-dom';
import { ColumnFilter } from '../../../components/ColumnFilter';
import { ColumnFilterSource } from '../../../components/ColumnFilterSource';
import { getFormatDate } from '../../../utils/utils';
import type { IRow } from '../../types';

export const COLUMNS = [
    {
        Header: 'Id',
        accessor: 'id',
        Filter: ColumnFilter,
        disableFilters: true
    },
    {
        Header: 'DevEUI',
        accessor: 'deveui',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ row }: IRow) => {
            return (
                <Link to={`/admin/sensors/${row.original.deveui}/edit`}>{row.original.deveui}</Link>
            );
        }
    },
    {
        Header: 'Data',
        accessor: 'data',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ row }: IRow) => {
            return <span className="history-data">{row.original.data}</span>;
        }
    },
    {
        Header: 'Источник',
        accessor: 'source',
        Cell: ({ value }: Cell) => {
            return value ? value : '—';
        },
        Filter: ColumnFilterSource
    },
    {
        Header: 'Дата активации',
        accessor: 'source_at',
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        },
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'Дата создания',
        accessor: 'created_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        }
    }
];
