import { Link } from 'react-router-dom';
import { ColumnFilter } from '../../components/ColumnFilter';
import { ColumnFilterStatus } from './Filters/ColumnFilterStatus';
import { ColumnFilterUsers } from './Filters/ColumnFilterUsers';
import { Cell } from 'react-table';
import { IRow } from '../types';
import { getFormatDate } from '../../utils/utils';

export const COLUMNS = [
    {
        Header: 'Id',
        accessor: 'id',
        Filter: ColumnFilter,
        disableFilters: true
    },
    {
        Header: '№ заявки',
        accessor: 'uid',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ row }: IRow) => {
            return (
                <Link to={`/admin/tickets/${row.original.id}/subtickets`}>{row.original.uid}</Link>
            );
        }
    },
    {
        Header: 'Статус',
        accessor: 'status',
        Filter: ColumnFilterStatus
    },
    {
        Header: 'Адрес',
        accessor: 'address',
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'Автор',
        accessor: 'user_id',
        Filter: ColumnFilterUsers,
        Cell: ({ row }: IRow) => {
            return row.original.lastname + ' ' + row.original.firstname;
        }
    },
    {
        Header: 'Дата создания',
        accessor: 'created_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        }
    },
    {
        Header: 'Дата изменения',
        accessor: 'updated_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        }
    }
];
