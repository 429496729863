import Input from '../../components/Ui/components-form/Input';
import Select from '../../components/Ui/components-form/Select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import React, { FormEvent } from 'react';
import { IDriver, IPageOptions } from '../types';

interface IDriverForm {
    driver: IDriver;
    data: string;
    hasError: (key: string) => boolean;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    driverOptions: IPageOptions[];
    commentChange: any;
    onSubmit: (e: FormEvent<HTMLFormElement>) => void;
    validateField: (fieldName: string, value: string) => void;
    getErrorMessage: any;
    handleSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    checkboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DriverForm = ({
    driver,
    hasError,
    handleChange,
    driverOptions,
    commentChange,
    data,
    onSubmit,
    validateField,
    getErrorMessage,
    handleSelectChange,
    checkboxChange
}: IDriverForm) => {
    return (
        <form onSubmit={onSubmit}>
            <Input name="comment" type="hidden" value={driver?.comment || ''} />
            <Input
                className={hasError('name') ? 'is-invalid' : ''}
                title="Название"
                name="name"
                type="text"
                value={driver?.name || ''}
                handleChange={handleChange}
                errorDiv={hasError('name') ? 'text-danger' : 'd-none'}
                errorMsg={getErrorMessage('name')}
                required={true}
                onBlur={(e) => validateField(e.target.name, e.target.value)}
            />
            <Select
                title="Вендор"
                name="vendor_id"
                value={driver?.vendor_id || 0}
                options={driverOptions}
                handleChange={handleSelectChange}
                className={hasError('vendor_id') ? 'is-invalid' : ''}
                required={true}
            />
            <Input
                className={hasError('version') ? 'is-invalid' : ''}
                title="Версия драйвера"
                name="version"
                type="text"
                value={driver?.version || ''}
                handleChange={handleChange}
                required={true}
                onBlur={(e) => validateField(e.target.name, e.target.value)}
                errorDiv={hasError('version') ? 'text-danger' : 'd-none'}
                errorMsg={getErrorMessage('version')}
            />
            <Input
                className={''}
                title="Контактное лицо (ФИО)"
                name="fio"
                type="text"
                value={driver?.fio || ''}
                handleChange={handleChange}
                errorDiv={'d-none'}
                errorMsg={''}
                required={false}
            />
            <div className="mb-3">
                <label className="form-label">Комментарий</label>
                <CKEditor
                    id="comment"
                    name="comment"
                    data={data}
                    editor={ClassicEditor}
                    config={{
                        toolbar: [
                            'Bold',
                            'Italic',
                            '|',
                            'NumberedList',
                            'BulletedList',
                            '|',
                            'Link',
                            '|',
                            'Undo',
                            'Redo'
                        ]
                    }}
                    onReady={(editor) => {
                        editor.editing.view.change((writer) => {
                            writer.setStyle(
                                'height',
                                '100px',
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                editor.editing.view.document.getRoot()
                            );
                        });
                    }}
                    onChange={commentChange}
                />
            </div>
            <div className="row">
                <span className="col-auto pr-0">
                    <input
                        name="flag"
                        className="form-check"
                        type="checkbox"
                        id="flag"
                        checked={driver.flag ?? false}
                        onChange={checkboxChange}
                    />
                </span>
                <label className="form-label col-9" htmlFor="flag">
                    <span className="pr-1">Активна</span>
                </label>
            </div>
            <div className="row mb-3">
                <span className="col-auto pr-0">
                    <input
                        name="go_flag"
                        className="form-check"
                        type="checkbox"
                        id="dump_flag"
                        checked={Boolean(driver.go_flag) ?? false}
                        onChange={checkboxChange}
                    />
                </span>
                <label className="form-label col-9" htmlFor="dump_flag">
                    <span className="pr-1">Go-драйвер</span>
                </label>
            </div>
            * — обязательные поля
        </form>
    );
};

export default DriverForm;
