import React, { useState, useMemo, useCallback } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ProtectedRoute } from '../../components/ProtectedRoute';
import Tickets from './Tickets';
import TicketList from './TicketList';
import TicketCreate from './TicketCreate';
import TicketEdit from './TicketEdit';
import SubTickets from './Subticket/SubTickets';
import TicketComments from '../ticket-comments/TicketComments';
import SubTicketsEdit from './Subticket/SubTicketsEdit';
import { fetchEmployees, fetchUsers } from '../../api';
import { SubTicketContext } from '../../Context/SubTicketContext';
import SubTicketCreate from './Subticket/SubTicketCreate';
import { solutions, support, troubles } from '../../api/mockUrls';
import Solutions from '../solutions/Solutions';
import SolutionCreate from '../solutions/SolutionCreate';
import SolutionEdit from '../solutions/SolutionEdit';
import Troubles from '../troubles/Troubles';
import TroubleCreate from '../troubles/TroubleCreate';
import TroubleEdit from '../troubles/TroubleEdit';
import TicketStatuses from '../ticket-statuses/TicketStatuses';
import TicketStatusCreate from '../ticket-statuses/TicketStatusCreate';
import TicketStatusEdit from '../ticket-statuses/TicketStatusEdit';
import Users from '../users/Users';
import UserCreate from '../users/UserCreate';
import UserEdit from '../users/UserEdit';
import Employees from '../employees/Employees';
import EmployeeCreate from '../employees/EmployeeCreate';
import EmployeeEdit from '../employees/EmployeeEdit';
import { ISubTicketContext } from '../../types/contextType';

const TicketRoute = () => {
    const [troubleOptions, setTroubleOptions] = useState([]);
    const [solutionOptions, setSolutionOptions] = useState([]);
    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [isLoadedSolution, setIsLoadedSolution] = useState(true);
    const [isLoadedTrouble, setIsLoadedTrouble] = useState(true);
    const [isLoadedEmployee, setIsLoadedEmployee] = useState(true);

    const troublesUpdate = useCallback(() => {
        setIsLoadedSolution(false);
        fetchUsers(troubles)
            .then((response) => setTroubleOptions(response))
            .then(() => setIsLoadedTrouble(true));
    }, []);

    const solutionsUpdate = useCallback(() => {
        setIsLoadedSolution(false);
        fetchUsers(solutions)
            .then((response) => setSolutionOptions(response))
            .then(() => setIsLoadedSolution(true));
    }, []);

    const employeeUpdate = useCallback(() => {
        setIsLoadedEmployee(false);
        fetchEmployees()
            .then((response) => setEmployeeOptions(response))
            .then(() => setIsLoadedEmployee(true));
    }, []);

    const userUpdate = useCallback(() => {
        fetchUsers(support).then((response) => setUserOptions(response));
    }, []);

    const value: ISubTicketContext = useMemo(
        () => ({
            troubleOptions,
            solutionOptions,
            employeeOptions,
            userOptions,
            isLoadedSolution,
            isLoadedTrouble,
            isLoadedEmployee,
            troublesUpdate,
            solutionsUpdate,
            employeeUpdate,
            userUpdate
        }),
        [
            troubleOptions,
            solutionOptions,
            employeeOptions,
            userOptions,
            isLoadedSolution,
            isLoadedTrouble,
            isLoadedEmployee,
            troublesUpdate,
            solutionsUpdate,
            employeeUpdate,
            userUpdate
        ]
    );

    return (
        <SubTicketContext.Provider value={value}>
            <Switch>
                <ProtectedRoute path="/admin/tickets/" exact component={Tickets} />
                <ProtectedRoute path="/admin/tickets/list" exact component={TicketList} />
                <ProtectedRoute path="/admin/tickets/create" exact component={TicketCreate} />
                <ProtectedRoute path="/admin/tickets/:id/edit" exact component={TicketEdit} />
                <ProtectedRoute path="/admin/tickets/:id/subtickets" exact component={SubTickets} />
                <ProtectedRoute
                    path="/admin/tickets/:id/subtickets/:subticketId/edit"
                    exact
                    component={SubTicketsEdit}
                />
                <ProtectedRoute
                    path="/admin/tickets/:id/subtickets/create"
                    exact
                    component={SubTicketCreate}
                />
                <ProtectedRoute
                    path="/admin/tickets/:id/comments"
                    exact
                    component={TicketComments}
                />
                <Route path="/admin/solutions/" exact component={Solutions} />
                <Route path="/admin/solutions/create" exact component={SolutionCreate} />
                <Route path="/admin/solutions/:id/edit" exact component={SolutionEdit} />
                {/*  */}
                <Route path="/admin/troubles/" exact component={Troubles} />
                <Route path="/admin/troubles/create" exact component={TroubleCreate} />
                <Route path="/admin/troubles/:id/edit" exact component={TroubleEdit} />
                {/*  */}
                <Route path="/admin/ticket-statuses/" exact component={TicketStatuses} />
                <Route path="/admin/ticket-statuses/create" exact component={TicketStatusCreate} />
                <Route path="/admin/ticket-statuses/:id/edit" exact component={TicketStatusEdit} />
                {/*  */}
                <Route path="/admin/users/" exact component={Users} />
                <Route path="/admin/users/create" exact component={UserCreate} />
                <Route path="/admin/users/:id/edit" exact component={UserEdit} />
                {/*  */}
                <Route path="/admin/employees/" exact component={Employees} />
                <Route path="/admin/employees/create" exact component={EmployeeCreate} />
                <Route path="/admin/employees/:id/edit" exact component={EmployeeEdit} />
                {/*  */}
            </Switch>
        </SubTicketContext.Provider>
    );
};

export default TicketRoute;
