import React, { Ref, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AbacProvider, AllowedTo } from 'react-abac';
import {
    Column,
    Row,
    SortingRule,
    useFilters,
    useRowSelect,
    useSortBy,
    useTable
} from 'react-table';
import { MoreHorizontal, Trash2 } from 'react-feather';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';
import { Checkbox } from '../../Ui/components-form/Checkbox';
import { Rules, User } from '../../../utils/rules';
import RowTable from './RowTable';
import SensorsGroupsSelect from '../../../pages/sensors/SensorsGroup/SensorsGroupsSelect';
import { IOptionsFilter, ISelectOptionsEdit, IUserOptions } from '../../../pages/tickets/types';
import { IColumnFromLocalStorage, IField, ISort } from '../type';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ISensorGroup } from '../../../pages/sensors/type';
import ReconnectingWebSocket from 'reconnecting-websocket';

type TId = {
    id?: string;
};

interface IListTable {
    columns: Column[];
    data: ISensorGroup[];
    total: number | string;
    page: number;
    pageSize: number;
    pageSizes: number[];
    totalPages: number;
    onPageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
    onPageSizeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    onChangeFilter: (field: IField) => Promise<void>;
    onSort: (sortByElement: SortingRule<ISort>) => void;
    onDelete: (selectedFlatRows: Array<Row<TId>>) => void;
    pageHeader?: string;
    subMenu?: JSX.Element;
    onExport: () => void;
    idBtnExport: Ref<unknown> | undefined;
    csvFilename?: string;
    pathname: string;
    columnFromLocalStorage: IColumnFromLocalStorage;
    btnAddUrl?: string;
    btnAddPermissions?: string;
    btnDelPermissions?: string;
    troubleOptions: ISelectOptionsEdit[];
    solutionOptions: ISelectOptionsEdit[];
    employeeOptions: IOptionsFilter[];
    userOptions?: IUserOptions[];
    selectGroups?: boolean;
    isWs?: ReconnectingWebSocket;
    isLoaded?: boolean;
}

const ListTable = ({
    columns,
    data,
    total,
    page,
    pageSize,
    pageSizes,
    totalPages,
    onPageChange,
    onPageSizeChange,
    onChangeFilter,
    onSort,
    onDelete,
    pageHeader,
    subMenu,
    onExport,
    idBtnExport,
    csvFilename,
    pathname,
    columnFromLocalStorage,
    btnAddUrl,
    btnAddPermissions,
    btnDelPermissions,
    troubleOptions,
    solutionOptions,
    employeeOptions,
    userOptions,
    selectGroups,
    isWs,
    isLoaded
}: IListTable) => {
    const [role, setRole] = useState('');
    const firstPageItem = page * pageSize + 1 - pageSize;
    const lastPageItem = page === totalPages ? total : page * pageSize;

    const onChangeHiddenColumn = () => {
        const dataFromLS = columnFromLocalStorage.userSettings;
        const columnsFromLS = columnFromLocalStorage.hiddenColumnsFromLocalStorage;
        const pathnameExist = columnFromLocalStorage.pathnameExist;

        if (!columnsFromLS.length && !pathnameExist) {
            const newUserColumns = {
                pathname,
                hiddenColumns
            };
            localStorage.setItem(
                'datanorm.userSettings',
                JSON.stringify([...dataFromLS, newUserColumns])
            );
        } else {
            const updColumn = dataFromLS.map((item: { pathname: string }) =>
                item.pathname === pathname ? { ...item, hiddenColumns: hiddenColumns } : item
            );
            localStorage.setItem('datanorm.userSettings', JSON.stringify(updColumn));
        }
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        allColumns,
        state: { sortBy, hiddenColumns },
        selectedFlatRows
    } = useTable(
        {
            columns,
            data,
            initialState: {
                hiddenColumns: columnFromLocalStorage.hiddenColumnsFromLocalStorage
            },
            manualSortBy: true,
            onChangeFilter,
            onChangeHiddenColumn,
            solutionOptions,
            employeeOptions,
            userOptions,
            troubleOptions
        },
        useFilters,
        useSortBy,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <Checkbox {...getToggleAllRowsSelectedProps()} />
                    ),
                    Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />
                },
                ...columns
            ]);
        }
    );

    // server-side by sort
    useEffect(() => {
        onSort(sortBy[0]);
        const storage = localStorage.getItem('datanorm.user');
        setRole(storage ? storage.toUpperCase() : 'USER');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortBy]); // onSort будет вызывать бесконечность
    // onSort будет давать столько дублей загрузок, на сколько уровней проброшен вызов

    // toDo: many calls now, need 1 call by onChange or onClick
    onChangeHiddenColumn();

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row mb-2 mb-xl-3">
                    <div className="col-auto d-sm-block">
                        <h3>{pageHeader}</h3>
                    </div>
                    <div className="col-auto ms-auto text-end mt-n1">
                        {csvFilename && (
                            <button className="btn btn-primary me-2" onClick={onExport}>
                                Экспорт{' '}
                                <CircularProgress
                                    size={14}
                                    className="d-none"
                                    ref={idBtnExport}
                                    style={{ display: 'inline-block', color: '#fffff' }}
                                />
                            </button>
                        )}

                        {btnAddPermissions && (
                            <AbacProvider user={User} roles={[role]} rules={Rules}>
                                {btnAddUrl && (
                                    <AllowedTo perform={btnAddPermissions}>
                                        <Link to={btnAddUrl} className="btn btn-primary">
                                            <i className="fas fa-plus" />{' '}
                                            {pathname.includes('sensors/groups')
                                                ? 'Добавить группу'
                                                : 'Добавить'}
                                        </Link>
                                    </AllowedTo>
                                )}
                            </AbacProvider>
                        )}
                    </div>
                </div>
            </div>

            {subMenu && subMenu}
            {!isLoaded && isWs ? <LinearProgress /> : <div style={{ height: '4px' }} />}
            <div className="card">
                <div className="card-title">
                    <div className="card-actions float-end">
                        <div className="nav-item dropdown">
                            <div className="dropdown position-relative more-horizontal">
                                <Link
                                    className="nav-icon dropdown-toggle"
                                    to="#"
                                    data-bs-toggle="dropdown">
                                    <MoreHorizontal />
                                </Link>
                                <div
                                    className="dropdown-menu dropdown-menu-end"
                                    data-bs-propper="static">
                                    {allColumns.map((column, i) => {
                                        // если не 0-я колонка (чекбоксы), то выводим
                                        return i ? (
                                            <div className="checkbox-item" key={column.id}>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-inline"
                                                    {...column.getToggleHiddenProps()}
                                                />
                                                {column.Header}
                                            </div>
                                        ) : (
                                            ''
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-3 pl-3 pb-2">
                        <div className="wrapper-sizepages">
                            Показывать по{' '}
                            <select
                                className="form-select form-select-sm"
                                onChange={onPageSizeChange}
                                value={pageSize}>
                                {pageSizes.map((size: number) => (
                                    <option key={size} value={size}>
                                        {size}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table
                        className="table table-sm dataTable more-horizontal"
                        {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup, index) => (
                                <React.Fragment key={index}>
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column, idx) => {
                                            return (
                                                <React.Fragment key={idx}>
                                                    <th
                                                        className={
                                                            column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? 'sorting sorting_desc'
                                                                    : 'sorting sorting_asc'
                                                                : 'sorting'
                                                        }
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}>
                                                        {column.render('Header')}
                                                    </th>
                                                </React.Fragment>
                                            );
                                        })}
                                    </tr>
                                </React.Fragment>
                            ))}
                            {headerGroups.map((headerGroup, index) => (
                                <React.Fragment key={index}>
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => {
                                            return (
                                                <td key={'t' + column.id}>
                                                    {column.canFilter
                                                        ? column.render('Filter')
                                                        : null}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </React.Fragment>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <RowTable
                                        key={row.id}
                                        row={row}
                                        getRowProps={row.getRowProps}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-5 col-lg-3">
                            <div className="my-3 pl-2">
                                Показано c {firstPageItem} по {lastPageItem} из {total}
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-9">
                            <div className="float-right">
                                <Pagination
                                    className="my-3"
                                    count={totalPages}
                                    page={page}
                                    siblingCount={2}
                                    boundaryCount={2}
                                    color="primary"
                                    shape="rounded"
                                    onChange={onPageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-sm-block">
                        {btnDelPermissions && (
                            <AbacProvider user={User} roles={[role]} rules={Rules}>
                                <AllowedTo
                                    perform={btnDelPermissions}
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    no={() => ''}>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => onDelete(selectedFlatRows)}>
                                        <Trash2 size={14} /> Удалить
                                    </button>
                                </AllowedTo>
                            </AbacProvider>
                        )}
                    </div>
                    {selectGroups && <SensorsGroupsSelect rows={selectedFlatRows} />}
                </div>
            </div>
        </>
    );
};

export default ListTable;
