import { Link } from 'react-router-dom';
import { ColumnFilter } from '../../components/ColumnFilter';
import { Cell } from 'react-table';
import { IRow } from '../types';
import { getFormatDate } from '../../utils/utils';
import { ColumnFilterTypeByte } from './Filter/ColumnFilterTypeByte';
import { ColumnFilterTypeMetric } from './Filter/ColumnFilterTypeMetric';

export const COLUMNS = [
    {
        Header: 'Id',
        accessor: 'id',
        Filter: ColumnFilter,
        disableFilters: true
    },
    {
        Header: 'Name',
        accessor: 'name',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ row }: IRow) => {
            return (
                <Link to={`/admin/metric-list/${row.original.id}/edit`}>{row.original.name}</Link>
            );
        }
    },
    {
        Header: 'Типы Метрик',
        accessor: 'type',
        Filter: ColumnFilterTypeMetric,
        FilterLike: true,
        Cell: ({ row }: IRow) => {
            return row.original.type_metric ? <span>{row.original.type_metric}</span> : '—';
        }
    },
    {
        Header: 'Типы Байта',
        accessor: 'type_bytes',
        Filter: ColumnFilterTypeByte,
        Cell: ({ row }: IRow) => {
            return row.original.type_bytes ? <span>{row.original.type_byte}</span> : '—';
        }
    },
    {
        Header: 'Дата создания',
        accessor: 'created_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        }
    },
    {
        Header: 'Дата изменения',
        accessor: 'updated_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        }
    }
];
