import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Input from '../../components/Ui/components-form/Input';
import Select from '../../components/Ui/components-form/Select';
import Alert from '../../components/Ui/components-ui/Alert';
import { Eventinfo, TId } from '../types';
import { getFetchData, updateFetchData } from '../../api';
import { isEmail } from '../../utils/utils';
import { SubTicketContext } from '../../Context/SubTicketContext';
import AllowLayout from '../../Layouts/AllowLayout';
import { Permissions } from '../../utils/rules';

const UserEdit = () => {
    const { userUpdate } = useContext(SubTicketContext);
    const history = useHistory();
    const { id } = useParams<TId>();

    const [isLoaded, setIsLoaded] = useState(true);
    const [user, setUser] = useState({
        name: '',
        last_name: '',
        middle_name: '',
        role: '',
        email: '',
        comment: ''
    });
    const [roleOptions, setRoleOptions] = useState([]);

    const [errors, setErrors] = useState<string[]>([]);
    const [alert, setAlert] = useState({
        type: 'd-none',
        message: ''
    });
    const [data, setData] = useState(''); // Потребовалась инициализировать отдельно,

    useEffect(() => {
        setIsLoaded(false);
        getFetchData(`/api/users/${id}/`)
            .then((response) => {
                setAlert({
                    type: 'd-none',
                    message: ''
                });
                setUser(response);
                setData(response.comment ?? '');
            })
            .then(() => setIsLoaded(true))
            .catch((error) => {
                const data = { comment: '' };
                setAlert({
                    type: 'alert-danger',
                    message: 'Invalid response code: ' + error.status
                });
                return data;
            });

        getFetchData(`/api/users/roles`)
            .then((response) => {
                setRoleOptions(response);
            })
            .then(() => setIsLoaded(true));
    }, [id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setUser({ ...user, [name]: value });
    };

    const commentChange = (event: Eventinfo, editor: ClassicEditor) => {
        const data = editor.getData();
        setUser({ ...user, comment: data });
    };

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errs = [];
        if (user.name === '') errs.push('name');
        if (user.last_name === '') errs.push('last_name');
        if (user.middle_name === '') errs.push('middle_name');
        if (user.role === '0') errs.push('role');
        if (!isEmail(user.email)) errs.push('email');
        setErrors(errs);
        if (errs.length > 0) return false;

        const data = new FormData(e.target);
        const payload = Object.fromEntries(data.entries());

        updateFetchData(`/api/users/${id}/`, payload)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    userUpdate();
                    history.push({
                        pathname: '/admin/users/'
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    type: 'alert-danger',
                    message: 'Invalid response code: ' + error.status
                });
            });
    };

    const hasError = (key: number | string) => {
        return errors.indexOf(String(key as number)) !== -1;
    };

    // Preloader
    if (!isLoaded) {
        const elem = document.getElementById('preloader');
        if (elem) elem.classList.remove('d-none');
    } else if (isLoaded) {
        const elem = document.getElementById('preloader');
        if (elem) elem.classList.add('d-none');
    }

    return (
        <main className="content">
            <AllowLayout
                children={
                    <>
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-sm-block">
                                    <h3>Пользователь</h3>
                                </div>
                                <div className="col-auto ms-auto text-end mt-n1" />
                            </div>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className="col-md-6 col-xl-6">
                                <div className="card">
                                    <div className="card-body">
                                        <Alert type={alert.type} message={alert.message} />
                                        <Input
                                            title=""
                                            name="comment"
                                            type="hidden"
                                            value={user.comment || ''}
                                            errorDiv={'d-none'}
                                            errorMsg={''}
                                        />
                                        <Input
                                            className={hasError('email') ? 'is-invalid' : ''}
                                            title="Email"
                                            name="email"
                                            type="text"
                                            value={user.email || ''}
                                            handleChange={handleChange}
                                            errorDiv={hasError('email') ? 'text-danger' : 'd-none'}
                                            errorMsg={'не корректный email'}
                                            required={true}
                                        />
                                        <Input
                                            className={hasError('last_name') ? 'is-invalid' : ''}
                                            title="Фамилия"
                                            name="last_name"
                                            type="text"
                                            value={user.last_name || ''}
                                            handleChange={handleChange}
                                            errorDiv={
                                                hasError('last_name') ? 'text-danger' : 'd-none'
                                            }
                                            errorMsg={'Поле не может быть пустым'}
                                            required={true}
                                        />
                                        <Input
                                            className={hasError('name') ? 'is-invalid' : ''}
                                            title="Имя"
                                            name="name"
                                            type="text"
                                            value={user.name || ''}
                                            handleChange={handleChange}
                                            errorDiv={hasError('name') ? 'text-danger' : 'd-none'}
                                            errorMsg={'Поле не может быть пустым'}
                                            required={true}
                                        />
                                        <Input
                                            className={hasError('middle_name') ? 'is-invalid' : ''}
                                            title="Отчество"
                                            name="middle_name"
                                            type="text"
                                            value={user.middle_name || ''}
                                            handleChange={handleChange}
                                            errorDiv={
                                                hasError('middle_name') ? 'text-danger' : 'd-none'
                                            }
                                            errorMsg={'Поле не может быть пустым'}
                                            required={true}
                                        />
                                        <Select
                                            title="Роль"
                                            name="role"
                                            value={user.role || ''}
                                            options={roleOptions}
                                            handleChange={handleChange}
                                            className={hasError('role') ? 'is-invalid' : ''}
                                            required={true}
                                        />
                                        <div className="mb-3">
                                            <label className="form-label">Комментарий</label>
                                            <CKEditor
                                                id="comment"
                                                name="comment"
                                                data={data}
                                                editor={ClassicEditor}
                                                config={{
                                                    toolbar: [
                                                        'Bold',
                                                        'Italic',
                                                        '|',
                                                        'NumberedList',
                                                        'BulletedList',
                                                        '|',
                                                        'Link',
                                                        '|',
                                                        'Undo',
                                                        'Redo'
                                                    ]
                                                }}
                                                onReady={(editor) => {
                                                    editor.editing.view.change((writer) => {
                                                        writer.setStyle(
                                                            'height',
                                                            '100px',
                                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                            // @ts-ignore
                                                            editor.editing.view.document.getRoot()
                                                        );
                                                    });
                                                }}
                                                onChange={commentChange}
                                            />
                                        </div>
                                        * — обязательные поля
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Сохранить
                            </button>{' '}
                            <Link to="/admin/users" className="btn btn-outline-secondary">
                                Назад
                            </Link>
                        </form>
                    </>
                }
                permissions={Permissions.READ_USER}
            />
        </main>
    );
};

export default UserEdit;
