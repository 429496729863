import React, { useEffect, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import Alert from '../../components/Ui/components-ui/Alert';
import { IMessages, TId } from '../types';
import { getFetchData } from '../../api';

const Messages = () => {
    const { id } = useParams<TId>();

    const [messages, setMessages] = useState<IMessages[]>([]);

    const [alert, setAlert] = useState({
        type: 'd-none',
        message: ''
    });

    useEffect(() => {
        getFetchData(`/api/tickets/${id}/comments`)
            .then((response) => {
                setAlert({
                    type: 'd-none',
                    message: ''
                });
                setMessages(response);
            })
            .catch((error) => {
                const data = { comment: '' };
                setAlert({
                    type: 'alert-danger',
                    message: 'Invalid response code: ' + error.status
                });
                return data;
            });
    }, [id]);

    return (
        <>
            <Alert type={alert.type} message={alert.message} />

            {messages.length === 0 ? (
                'Нет данных'
            ) : (
                <div className="card">
                    <table className="table table-sm">
                        <thead>
                            <tr style={{ borderBottom: '2px solid #000' }}>
                                <th>Комментарий</th>
                                <th>Действие</th>
                                <th>ФИО</th>
                                <th>Статус</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={4} style={{ background: '#f7f7fc' }} />
                            </tr>
                            {messages.map((message, index: number) => (
                                <Fragment key={index}>
                                    <tr style={{ fontSize: '11px' }}>
                                        <td>
                                            {format(
                                                new Date(message.created_at),
                                                'dd-MM-yyyy HH:mm:ss'
                                            )}
                                        </td>
                                        <td>{message.action}</td>
                                        <td>{message.lastname + ' ' + message.firstname}</td>
                                        <td>{message.status ? message.status : '—'}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}>
                                            <div
                                                dangerouslySetInnerHTML={{ __html: message.body }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4} style={{ background: '#f7f7fc' }} />
                                    </tr>
                                </Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};

export default Messages;
