import React, { FC } from 'react';
import Input from '../../../components/Ui/components-form/Input';
import { ICondArr, IKeyArr, IRule } from '../../types';

interface IOptionProps {
    alarmRules: IRule;
    conditionArr: ICondArr[];
    keyArr: IKeyArr[];
    handleAddNewField: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    handleAddHeaderInput: (e: React.MouseEvent<HTMLButtonElement>) => void;
    hasError: (key: string) => boolean;
}

const RulesConditionAddField: FC<IOptionProps> = ({
    alarmRules,
    conditionArr,
    keyArr,
    handleAddNewField,
    handleAddHeaderInput,
    hasError
}) => {
    return (
        <>
            <div className="col-sm-5">
                <select
                    className={`form-select form-select-sm form-control ${
                        hasError('key') ? 'is-invalid' : ''
                    }`}
                    name="key"
                    onChange={handleAddNewField}
                    value={alarmRules.key || ''}>
                    <option value="0">Не выбран</option>
                    {keyArr.map((option) => (
                        <option key={option.id} value={option.key}>
                            {option.key}
                        </option>
                    ))}
                </select>
                {hasError('key') && (
                    <div className={hasError('key') ? 'text-danger danger-rules' : 'd-none'}>
                        {'Поле не может быть пустым'}
                    </div>
                )}
            </div>
            <div className="col-sm-3">
                <select
                    className={`form-select form-select-sm form-control ${
                        hasError('condition') ? 'is-invalid' : ''
                    }`}
                    name="condition"
                    onChange={handleAddNewField}
                    value={alarmRules.condition || ''}>
                    <option value="0">Не выбран</option>
                    {conditionArr.map((option) => (
                        <option key={option.id} value={option.condition}>
                            {option.condition}
                        </option>
                    ))}
                </select>
                {hasError('condition') && (
                    <div className={hasError('condition') ? 'text-danger danger-rules' : 'd-none'}>
                        {'Поле не может быть пустым'}
                    </div>
                )}
            </div>
            <div className="col-sm-3">
                <Input
                    className={hasError('value') ? 'is-invalid' : ''}
                    name="value"
                    type="text"
                    handleChange={handleAddNewField}
                    value={alarmRules.value || ''}
                    errorDiv={hasError('value') ? 'text-danger danger-rules' : 'd-none'}
                    errorMsg={'Поле не может быть пустым'}
                />
            </div>
            <div className="col-sm-1 text-end pl-0 mb-3">
                <button className="btn btn-primary" onClick={handleAddHeaderInput}>
                    <i className="fas fa-plus" />
                </button>
            </div>
        </>
    );
};
export default RulesConditionAddField;
