import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import AdvancedPage from '../../components/Table/AdvancedPage/AdvancedPage';
import { COLUMNS } from './TicketColumns';
import { Permissions } from '../../utils/rules';
import AllowLayout from '../../Layouts/AllowLayout';

const Tickets = () => {
    const columns = useMemo(() => COLUMNS, []);

    const Submenu = (
        <div className="col-md-12 col-xl-12">
            <ul className="nav mb-3" role="tablist" style={{ borderBottom: '1px solid #e4e4e4' }}>
                <li className="nav-item">
                    <Link
                        className="nav-link"
                        style={{ color: '#000', fontSize: '12px' }}
                        to="/admin/tickets/list">
                        Список
                    </Link>
                </li>
                <li className="nav-item" style={{ borderBottom: '3px solid #3b7ddd' }}>
                    <Link
                        className="nav-link"
                        style={{ color: '#000', fontSize: '12px' }}
                        to="/admin/tickets/">
                        Подробно
                    </Link>
                </li>
            </ul>
        </div>
    );

    return (
        <main className="content">
            <AllowLayout
                children={
                    <AdvancedPage
                        pageHeader={'Заявки'}
                        subMenu={Submenu}
                        btnAddUrl={'/admin/tickets/create'}
                        btnAddPermissions={Permissions.WRITE_SUPPORT_TICKET}
                        btnDelPermissions={Permissions.DELETE_SUPPORT_TICKET}
                        url={window.env.REACT_APP_API_URL + '/api/tickets'}
                        columns={columns}
                    />
                }
                permissions={Permissions.WRITE_SUPPORT_SOLUTION}
            />
        </main>
    );
};

export default Tickets;
