import React, { useState, useEffect, useContext, memo } from 'react';
import { Link } from 'react-router-dom';
import {
    Bell,
    Maximize,
    User as UserIcon,
    PieChart,
    Settings,
    HelpCircle,
    Search
} from 'react-feather';
import { AbacProvider, AllowedTo } from 'react-abac';
import { Avatar } from '@material-ui/core';
import auth from './auth';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { getData } from '../api';
import { Permissions, User, Rules } from '../utils/rules';
import { AvatarContext } from '../Context/AvatarContext';
import Alert from '../components/Ui/components-ui/Alert';
import { getSettings } from '../redux/settings';
import PopupWebsocket from './Ui/PopupWebsocket/PopupWebsocket';
import imgAva from '../assets/img/ava.jpeg';
import { TStore } from '../redux/store';
import type { INotification } from '../types/contextType';
import ReconnectingWebSocket from 'reconnecting-websocket';

interface INavigation {
    onSidebar: () => void;
    onFullscreen: () => void;
    ws: ReconnectingWebSocket;
}

const Navigation = ({ onSidebar, onFullscreen, ws }: INavigation) => {
    const { isPost } = useContext(AvatarContext);
    const [role, setRole] = useState('');
    const [bells, setBells] = useState<INotification[]>([]);
    const { alert, avatar } = useAppSelector((state: TStore) => state.settings?.settings);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const storage = localStorage.getItem('datanorm.user');
        setRole(storage ? storage.toUpperCase() : 'USER');
    }, []);

    useEffect(() => {
        const getMessageMonitor = (msg: MessageEvent) => {
            const data = JSON.parse(msg.data);
            if (data.action === 'action-bell') {
                getDataWs();
            }
        };

        ws?.addEventListener('message', getMessageMonitor);
        return () => ws?.removeEventListener('message', getMessageMonitor);
    }, [ws]);

    useEffect(() => {
        getDataWs();
    }, []);

    useEffect(() => {
        dispatch(getSettings());
    }, [dispatch, isPost]);

    const getDataWs = () => {
        getData('/api/alerts/bells')
            .then((response) => setBells(response))
            .catch((e) => {
                return <Alert type={'alert-danger'} message={e} />;
            });
    };

    const handleDelete = (id: number) => {
        const updatedItems = bells.filter((item) => item.id !== id);
        setBells(updatedItems);
        return;
    };

    const handleDeleteAllBells = () => {
        setBells([]);
    };

    return (
        <nav className="navigation navbar navbar-expand navbar-light navbar-bg">
            <Link to="#" className="sidebar-toggle d-flex" onClick={onSidebar}>
                <i className="hamburger align-self-center" />
            </Link>
            <AbacProvider user={User} roles={[role]} rules={Rules}>
                <AllowedTo perform={Permissions.READ_TOP_GLOBAL_SEARCH}>
                    <form className="d-none d-sm-inline-block">
                        <div className="input-group input-group-navbar">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Поиск…"
                                aria-label="Search"
                            />
                            <button className="btn" type="button">
                                <Search strokeWidth={2} size={20} />
                            </button>
                        </div>
                    </form>
                </AllowedTo>
            </AbacProvider>

            <div className="navbar-collapse collapse">
                <ul className="navbar-nav navbar-align">
                    {Boolean(alert) && (
                        <li className="nav-item dropdown">
                            <Link
                                className="nav-icon dropdown-toggle"
                                to="#"
                                id="alertsDropdown"
                                data-bs-toggle="dropdown">
                                <div className="position-relative">
                                    <Bell strokeWidth={1.5} />
                                    {bells.length > 0 && (
                                        <span className="indicator">{bells.length}</span>
                                    )}
                                </div>
                            </Link>
                            <PopupWebsocket
                                messages={bells}
                                handleDelete={handleDelete}
                                handleDeleteAllBells={handleDeleteAllBells}
                            />
                        </li>
                    )}
                    <li className="nav-item">
                        <Link
                            className="nav-icon js-fullscreen d-none d-lg-block"
                            to="#"
                            onClick={onFullscreen}>
                            <div className="position-relative">
                                <Maximize strokeWidth={1.5} />
                            </div>
                        </Link>
                    </li>
                    <li className="nav-item dropdown">
                        <Link
                            className="nav-icon pe-md-0 dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown">
                            <Avatar
                                className="avatar-img"
                                src={avatar || imgAva}
                                alt="avatar"
                                style={{ width: '36px', height: '36px' }}
                            />
                        </Link>
                        <div
                            className="dropdown-menu dropdown-menu-end"
                            aria-labelledby="alertsDropdown"
                            data-bs-popper="none">
                            <AbacProvider user={User} roles={[role]} rules={Rules}>
                                <AllowedTo perform={Permissions.READ_PROFILE_URL}>
                                    <Link className="dropdown-item" to="/admin/profile">
                                        <UserIcon size={18} strokeWidth={1.5} /> Профиль
                                    </Link>
                                </AllowedTo>
                                <AllowedTo perform={Permissions.READ_PROFILE_ANALYTICS}>
                                    <Link className="dropdown-item" to="#">
                                        <PieChart size={18} strokeWidth={1.5} /> Аналитика
                                    </Link>
                                </AllowedTo>
                                <AllowedTo perform={Permissions.READ_PROFILE_DELIMETER_1}>
                                    <div className="dropdown-divider" />
                                </AllowedTo>
                                <AllowedTo perform={Permissions.READ_PROFILE_SETUP}>
                                    <Link className="dropdown-item" to="/admin/settings">
                                        <Settings size={18} strokeWidth={1.5} /> Настройки
                                    </Link>
                                </AllowedTo>
                                <AllowedTo perform={Permissions.READ_PROFILE_HELP}>
                                    <Link className="dropdown-item" to="#">
                                        <HelpCircle size={18} strokeWidth={1.5} /> Помощь
                                    </Link>
                                </AllowedTo>
                                <AllowedTo perform={Permissions.READ_PROFILE_DELIMETER_2}>
                                    <div className="dropdown-divider" />
                                </AllowedTo>
                                <Link
                                    className="dropdown-item"
                                    to="#"
                                    onClick={() => {
                                        auth.logout();
                                    }}>
                                    Выйти
                                </Link>
                            </AbacProvider>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default memo(Navigation);
