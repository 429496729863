import React, { memo, useCallback } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import { createTheme } from '@uiw/codemirror-themes';
import { tags as t } from '@lezer/highlight';

const myTheme = createTheme({
    theme: 'light',
    settings: {
        background: 'inherit',
        backgroundImage: '',
        foreground: '#000',
        caret: '#5d00ff',
        selection: '#036dd626',
        selectionMatch: '#036dd626',
        lineHighlight: '#8a91991a',
        gutterBackground: 'inherit',
        gutterForeground: '#c3c0c0'
    },
    styles: [
        { tag: t.comment, color: '#787b8099' },
        { tag: t.variableName, color: '#0080ff' },
        { tag: [t.string, t.special(t.brace)], color: 'rgb(203, 75, 22)' },
        { tag: t.number, color: 'rgb(133, 153, 0)' },
        { tag: t.bool, color: '#5c6166' },
        { tag: t.null, color: '#5c6166' },
        { tag: t.keyword, color: '#5c6166' },
        { tag: t.operator, color: '#5c6166' },
        { tag: t.className, color: '#5c6166' },
        { tag: t.definition(t.typeName), color: '#5c6166' },
        { tag: t.typeName, color: '#5c6166' },
        { tag: t.angleBracket, color: '#5c6166' },
        { tag: t.tagName, color: '#5c6166' },
        { tag: t.attributeName, color: '#5c6166' }
    ]
});

interface IJsonEditorProps {
    value: any;
    setValue?: (value: any) => void;
    readOnly: boolean;
    setError?: (error: string) => void;
}
const JsonEditor = ({ value, setValue, readOnly, setError }: IJsonEditorProps) => {
    const onChange = useCallback(
        (value) => {
            try {
                const parsedJson = JSON.parse(value);
                setValue && setValue(parsedJson);
                setError && setError('');
            } catch (error) {
                setError && setError(`Invalid JSON format: ${error}`);
            }
        },
        [setError, setValue]
    );

    return (
        <div>
            <CodeMirror
                value={JSON.stringify(value, null, 2)}
                theme={myTheme}
                extensions={[json()]}
                onChange={onChange}
                readOnly={readOnly}
                editable={!readOnly}
                minHeight="200px"
            />
        </div>
    );
};

export default memo(JsonEditor);
