import React, { useEffect, useState, ChangeEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { AbacProvider, AllowedTo } from 'react-abac';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Input from '../../components/Ui/components-form/Input';
import Select from '../../components/Ui/components-form/Select';
import Alert from '../../components/Ui/components-ui/Alert';
import { Permissions, User, Rules } from '../../utils/rules';
import { Eventinfo, TId } from '../types';
import { getFetchData, updateFetchData } from '../../api';

interface ITicket {
    uid: string;
    status: string;
    firstname: string;
    lastname: string;
    created_at: string;
    users?: string;
    comment?: string;
    action?: string;
    status_id?: string;
}
interface ITicketEdit {
    onTicketSubmit: () => void;
    ticket: ITicket;
    statusOldID: string;
    setTicket: (arg: ITicket) => void;
}

const TicketEdit = ({ onTicketSubmit, ticket, setTicket, statusOldID }: ITicketEdit) => {
    const history = useHistory();
    const { id } = useParams<TId>();

    const [role, setRole] = useState('');
    const [isLoaded, setIsLoaded] = useState(true);
    const [errors, setErrors] = useState<string[]>([]);
    const [alert, setAlert] = useState({
        type: 'd-none',
        message: ''
    });
    const [data, setData] = useState('');
    const [statusesOptions, setStatusesOptions] = useState([]);

    useEffect(() => {
        const storage = localStorage.getItem('datanorm.user');
        setRole(storage ? storage.toUpperCase() : 'USER');

        setIsLoaded(false);
        getFetchData('/api/tickets/statuses/list')
            .then((response) => {
                setStatusesOptions(response);
                setData('');
            })
            .then(() => setIsLoaded(true));
    }, [id]);

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setTicket({ ...ticket, [name]: value });
    };

    const commentChange = (event: Eventinfo, editor: ClassicEditor) => {
        const data = editor.getData();
        setTicket({ ...ticket, comment: data });
    };

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errs = [];
        if (ticket.status_id === '0') errs.push('status_id');

        setErrors(errs);
        if (errs.length > 0) return false;

        const data = new FormData(e.target);
        const payload = Object.fromEntries(data.entries());

        // если комментарий пустой и статус не изменился, то возращаемся к странице без изменений
        if (payload.comment === '' && Number(statusOldID) === Number(payload.status_id)) {
            onTicketSubmit();
            return true;
        }
        // иначе
        payload.action = 'change_status';
        if (payload.comment !== '') payload.action = 'comment';

        updateFetchData(`/api/tickets/${id}/`, payload).then((response) => {
            if (response.status === 200 || response.status === 201) {
                history.push({
                    pathname: `/admin/tickets/${id}/subtickets`
                });
            } else {
                setAlert({
                    type: 'alert-danger',
                    message: 'Invalid response code: ' + response.status
                });
            }
        });

        // чтобы скрыть форму в родительнском компоненте TicketShow
        onTicketSubmit();
    };

    const hasError = (key: number | string) => {
        return errors.indexOf(String(key as number)) !== -1;
    };

    return (
        <form onSubmit={onSubmit}>
            <div className="mb-6">
                <div className="col-md-4 col-xl-4">
                    <div className="card">
                        <div className="card-body">
                            <Alert type={alert.type} message={alert.message} />
                            <Input name="action" type="hidden" value={ticket.action || ''} />
                            <Input name="comment" type="hidden" value={ticket.comment || ''} />
                            <Input name="users" type="hidden" value={ticket.users || ''} />
                            <Select
                                title="Статус"
                                name="status_id"
                                value={ticket.status_id || ''}
                                options={statusesOptions}
                                handleChange={handleChange}
                                className={hasError('status') ? 'is-invalid' : ''}
                                isLoaded={isLoaded}
                            />
                            <div className="mb-3">
                                <label className="form-label">Комментарий</label>
                                <CKEditor
                                    id="comment"
                                    name="comment"
                                    data={data}
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: [
                                            'Bold',
                                            'Italic',
                                            '|',
                                            'NumberedList',
                                            'BulletedList',
                                            '|',
                                            'Link',
                                            '|',
                                            'Undo',
                                            'Redo'
                                        ]
                                    }}
                                    onReady={(editor) => {
                                        editor.editing.view.change((writer) => {
                                            writer.setStyle(
                                                'height',
                                                '100px',
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                editor.editing.view.document.getRoot()
                                            );
                                        });
                                    }}
                                    onChange={commentChange}
                                />
                            </div>
                            * — обязательные поля
                        </div>
                    </div>
                </div>

                <AbacProvider user={User} roles={[role]} rules={Rules}>
                    <AllowedTo perform={Permissions.WRITE_SUPPORT_TICKET}>
                        <button type="submit" className="btn btn-primary">
                            Сохранить
                        </button>{' '}
                    </AllowedTo>
                </AbacProvider>
            </div>
        </form>
    );
};

export default TicketEdit;
