import React, { memo } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { Column } from 'react-table';
import { IOnChangeFilter } from '../../tickets/types';
import { TStore } from '../../../redux/store';

interface IColumnFilterStatus {
    column: Column;
    onChangeFilter: (arg: IOnChangeFilter) => void;
}

interface Option {
    id: string;
    name: string;
}

interface HeaderObject {
    Клиент?: string;
    Группы?: string;
}

const headerObject: HeaderObject = {
    Клиент: 'clientList',
    Группы: 'group'
};

const ColumnFilterGroups = ({ column, onChangeFilter }: IColumnFilterStatus) => {
    const { Header } = column;
    const sensorColumn = useAppSelector(
        (state: TStore) => state?.sensors as unknown as Record<string, Option[]>
    );

    const key = headerObject[Header as keyof HeaderObject];
    const options = key ? sensorColumn[key] : undefined;

    return (
        <div>
            <select
                className="form-select"
                onChange={(e) => {
                    onChangeFilter({ key: column.id, value: e.target.value });
                }}>
                <option value="">Не выбрано</option>
                <option disabled value="0">
                    ---
                </option>
                {options?.map((option: Option) => {
                    return (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};

export default memo(ColumnFilterGroups);
