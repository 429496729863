import React from 'react';
import { Column } from 'react-table';

interface IValue {
    [key: string]: string | undefined;
}

interface IColumnFilterSource {
    column: Column;
    onChangeFilter: (value: IValue) => void;
}

export const ColumnFilterSource = ({ column, onChangeFilter }: IColumnFilterSource) => {
    return (
        <div>
            <select
                className="form-select"
                onChange={(e) => onChangeFilter({ key: column.id, value: e.target.value })}>
                <option value="">Не выбрано</option>
                <option value="lorawan">LoRaWAN</option>
                <option value="nbiot">NB-IoT</option>
            </select>
        </div>
    );
};
