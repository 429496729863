import React, { FC, memo } from 'react';
import { IRules } from '../types';

interface IChannelProps {
    name: string;
    rule: IRules;
    setRule: (arr: IRules) => void;
}

const RulesEditChannel: FC<IChannelProps> = ({ name, rule, setRule }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;

        setRule({
            ...rule,
            body: [
                {
                    rules: rule.body[0].rules,
                    channel: rule.body[0].channel.map((channel) => {
                        if (channel.key === name) {
                            return { ...channel, key: name, value: checked };
                        }
                        return channel;
                    })
                }
            ]
        });
    };

    const channel = rule.body[0]?.channel.find((channel) => channel.key === name);

    return (
        <>
            <div className="row">
                <span className="col-auto pr-0">
                    <input
                        name={name}
                        className="form-check"
                        type="checkbox"
                        checked={channel?.value || false}
                        onChange={handleChange}
                    />
                </span>
                <div className="form-label col-9">
                    <span className="pr-1">{name}</span>
                </div>
            </div>
        </>
    );
};

export default memo(RulesEditChannel);
