import React, { useEffect, useState } from 'react';
import { Column } from 'react-table';
import { IOnChangeFilter, ISelectOptionsEdit } from '../types';
import { getFetchData } from '../../../api';

interface IColumnFilterStatus {
    column: Column;
    onChangeFilter: (arg: IOnChangeFilter) => void;
}

type TOptions = Pick<ISelectOptionsEdit, 'id' | 'user_id' | 'name' | 'created_at' | 'updated_at'>;

export const ColumnFilterStatus = ({ column, onChangeFilter }: IColumnFilterStatus) => {
    const [options, setOptions] = useState<TOptions[]>([]);

    useEffect(() => {
        getFetchData('/api/tickets/statuses/list').then((response) => {
            setOptions(response);
        });
    }, []);

    return (
        <div>
            <select
                className="form-select"
                onChange={(e) => {
                    onChangeFilter({ key: column.id, value: e.target.value });
                }}>
                <option value="0">Не выбрано</option>
                <option disabled value="0">
                    ---
                </option>
                {options?.map((option) => {
                    return (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};
