import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Input from '../components/Ui/components-form/Input';
import auth from '../components/auth';
import { Rules } from '../utils/rules';

const LoginLayout = () => {
    const storage = localStorage.getItem('datanorm.user');
    const [role, setRole] = useState(storage ? storage.toUpperCase() : 'USER');

    const history = useHistory();
    const [user, setUser] = useState({
        email: '',
        password: ''
    });
    const [errors, setErrors] = useState<string[]>([]);
    const [errorCred, setErrorCred] = useState('');
    const [pathname, setPathname] = useState(Rules[role !== null ? role : '']?.defaultRoute);

    useEffect(() => {
        setRole((role) => role);

        if (auth.isAuthenticated()) {
            history.push({
                pathname: pathname
            });
        }
    }, [history, pathname]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setUser({ ...user, [name]: value });
    };

    const hasError = (key: string | number) => {
        return errors.indexOf(key as string) !== -1;
    };

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        setErrorCred('');

        const errs = [];
        if (user.email === '') errs.push('email');
        if (user.password === '') errs.push('password');
        setErrors(errs);
        if (errs.length > 0) return false;

        const data = new FormData(e.target);
        const payload = Object.fromEntries(data.entries());

        const res = await fetch(`${window.env.REACT_APP_API_URL}/api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });

        const dataFromAPI = await res.json();

        if (dataFromAPI.error) {
            errs.push('email');
            setErrorCred(dataFromAPI.error);
        } else {
            localStorage.setItem('datanorm.jwt', dataFromAPI.token);
            localStorage.setItem('datanorm.user', dataFromAPI.user.role);
            localStorage.setItem('datanorm.timezone', dataFromAPI.user.timezone);
            auth.checkJwtToken();
            auth.login(dataFromAPI);
            setPathname(Rules[dataFromAPI.user.role.toUpperCase()]?.defaultRoute);
            history.push({
                pathname: pathname
            });
        }
    };

    return (
        <main className="d-flex w-100 h-100 login-layout">
            <div className="container d-flex flex-column">
                <div className="row vh-100">
                    <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                        <div className="d-table-cell align-middle">
                            <div className="text-center mt-4">
                                <h1 className="h2"># ERCeler</h1>
                                <p className="lead" />
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <div className="m-sm-4">
                                        <form onSubmit={onSubmit}>
                                            <Input
                                                className={hasError('email') ? 'is-invalid' : ''}
                                                title="Email"
                                                name="email"
                                                type="text"
                                                value={user.email || ''}
                                                handleChange={handleChange}
                                                errorDiv={
                                                    hasError('email') ? 'text-danger' : 'd-none'
                                                }
                                                errorMsg={errorCred || 'Необходимо указать Email'}
                                                required={true}
                                                requiredWithoutStar={true}
                                            />
                                            <Input
                                                className={hasError('password') ? 'is-invalid' : ''}
                                                title="Пароль"
                                                name="password"
                                                type="password"
                                                value={user.password || ''}
                                                handleChange={handleChange}
                                                errorDiv={
                                                    hasError('password') ? 'text-danger' : 'd-none'
                                                }
                                                errorMsg={'Необходимо указать пароль'}
                                                required={true}
                                                requiredWithoutStar={true}
                                            />

                                            <div className="text-center mt-3">
                                                <button
                                                    type="submit"
                                                    className="btn btn-lg btn-primary">
                                                    Войти
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default LoginLayout;
