import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { ColumnFilter } from '../../components/ColumnFilter';
import { ColumnFilterState } from './Filters/ColumnFilterState';
import { ColumnFilterStatus } from './Filters/ColumnFilterStatus';
import { ColumnFilterBattery } from './Filters/ColumnFilterBattery';
import { ColumnFilterUsetype } from './Filters/ColumnFilterUsetype';
import { ColumnFilterSource } from '../../components/ColumnFilterSource';
import { getFormatDate } from '../../utils/utils';
import Badge from '../../components/Ui/components-ui/Badge';
import Battery from '../../components/Ui/components-ui/Battery';
import type { IRow } from '../types';
import ColumnFilterGroups from './Filters/ColumnFilterGroups';

export const COLUMNS = [
    {
        Header: 'Id',
        accessor: 'id',
        Filter: ColumnFilter,
        disableFilters: true
    },
    {
        Header: 'Name',
        accessor: 'name',
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'DevEUI',
        accessor: 'deveui',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ row }: IRow) => {
            return row.original.deveui ? (
                <Link to={`/admin/sensors/${row.original.deveui}/edit`}>{row.original.deveui}</Link>
            ) : (
                '—'
            );
        }
    },
    {
        Header: 'Группы',
        accessor: 'group_id',
        Filter: ColumnFilterGroups,
        Cell: ({ row }: IRow) => {
            return row.original.group_name ? (
                <Link to={`/admin/sensors/groups#tab-${row.original.group_id}`}>
                    {row.original.group_name}
                </Link>
            ) : (
                '—'
            );
        }
    },
    {
        Header: 'DeviceID',
        accessor: 'did',
        Cell: ({ value }: Cell) => {
            return value ? value : '—';
        },
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'ObjectID',
        accessor: 'oid',
        Cell: ({ value }: Cell) => {
            return value ? value : '—';
        },
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'Статус',
        accessor: 'status',
        Cell: ({ value }: Cell) => {
            return value ? <Badge value={value} color={value} /> : '—';
        },
        Filter: ColumnFilterStatus
    },
    {
        Header: 'Состояние',
        accessor: 'state',
        Cell: ({ value }: Cell) => {
            return value ? <Badge value={value} color={value} /> : '—';
        },
        Filter: ColumnFilterState
    },
    {
        Header: 'Батарея,%',
        accessor: 'battery',
        Cell: ({ value }: Cell) => {
            return value ? <Battery strokeWidth={1.5} value={value} /> : '—';
        },
        Filter: ColumnFilterBattery
    },
    {
        Header: 'Напряж,В',
        accessor: 'voltage_s',
        Cell: ({ value }: Cell) => {
            return value ? value : '—';
        },
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'Версия ПО',
        accessor: 'reserve_2',
        Cell: ({ value }: Cell) => {
            return value ? value : '—';
        },
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'Источник',
        accessor: 'use_name',
        Cell: ({ value }: Cell) => {
            return value ? value : '—';
        },
        Filter: ColumnFilterSource
    },
    {
        Header: 'Применение',
        accessor: 'oid_type',
        Filter: ColumnFilterUsetype
    },
    {
        Header: 'RSSI',
        accessor: 'rssi',
        Cell: ({ value }: Cell) => {
            return value ? value / 100 : '—';
        },
        Filter: ColumnFilter,
        disableFilters: true
    },
    {
        Header: 'SNR',
        accessor: 'snr',
        Cell: ({ value }: Cell) => {
            return value ? value / 100 : '—';
        },
        Filter: ColumnFilter,
        disableFilters: true
    },
    {
        Header: 'Клиент',
        accessor: 'client_id',
        Filter: ColumnFilterGroups,
        Cell: ({ row }: IRow) => {
            return row.original.deveui ? (
                <Link to={`/admin/clients/${row.original.client_id}/edit`}>
                    {row.original.client}
                </Link>
            ) : (
                '—'
            );
        },
        disableFilters: false
    },
    {
        Header: 'Дата активации',
        accessor: 'started_at',
        Cell: ({ value }: Cell) => {
            return value ? getFormatDate(value) : '—';
        },
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'Дата блокировки',
        accessor: 'stopped_at',
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        },
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'Дата создания',
        accessor: 'created_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return value ? getFormatDate(value) : '—';
        }
    },
    {
        Header: 'Дата изменения',
        accessor: 'updated_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return value ? getFormatDate(value) : '—';
        }
    }
];
