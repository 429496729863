import React from 'react';
import { IColumnFilterBattery } from './type';

export const ColumnFilterUsetype = ({ column, onChangeFilter }: IColumnFilterBattery) => {
    return (
        <div>
            <select
                className="form-select"
                onChange={(e) => {
                    onChangeFilter({ key: column.id, value: e.target.value });
                }}>
                <option value="">Не выбрано</option>
                <option value="bike">Велосипед</option>
                <option value="human">Человек</option>
            </select>
        </div>
    );
};
