import { configureStore } from '@reduxjs/toolkit';

import { sensorsReducer } from './sensors';
import { settingsReducer } from './settings';

export const store = configureStore({
    reducer: {
        sensors: sensorsReducer,
        settings: settingsReducer
    }
});

export type TAppDispatch = typeof store.dispatch;
export type TStore = ReturnType<typeof store.getState>;
