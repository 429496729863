import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row text-muted">
                    <div className="col-6 text-left" />
                    <div className="col-6 text-right">
                        <p className="mb-0">
                            <small className="text-muted">
                                Version {window.env.REACT_APP_VERSION}
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
