import React, { useEffect, useState, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Input from '../../components/Ui/components-form/Input';
import Select from '../../components/Ui/components-form/Select';
import Alert from '../../components/Ui/components-ui/Alert';
import { DATA } from '../clients/ClientTree';
import { Permissions, User, Rules } from '../../utils/rules';
import { AbacProvider, AllowedTo } from 'react-abac';
import PageLayout from '../../Layouts/PageLayout';
import { IDriver, ISensor } from './type';
import { IPageOptions, Eventinfo } from '../types';
import { getFetchData, postFetchData } from '../../api';
import { vendorsLink } from '../../api/mockUrls';

const sensorDefault: ISensor = {
    name: '',
    deveui: '',
    comment: ''
};

const SensorCreate = () => {
    const [role, setRole] = useState('');

    const history = useHistory();
    const [sensor, setSensor] = useState(sensorDefault);
    const [clientTree, setClientTree] = useState<IPageOptions[]>([]);
    const [driverOptions, setDriverOptions] = useState([]);
    const [driverVersionOptions, setDriverVersionOptions] = useState<IPageOptions[]>([]);
    const clientOptions = useMemo(() => DATA, []);
    const [errors, setErrors] = useState<string[]>([]);
    const [alert, setAlert] = useState({
        type: 'd-none',
        message: ''
    });

    useEffect(() => {
        const storage = localStorage.getItem('datanorm.user');
        setRole(storage ? storage.toUpperCase() : 'USER');

        getFetchData('/api/clients/list').then((response) => {
            if (!response?.length) {
                setClientTree([{ id: 0, name: 'Не выбран' }]);
                return;
            }
            setClientTree(clientOptions(response));
        });

        getFetchData(`${vendorsLink}/list`).then((response) => {
            setDriverOptions(response);
        });
    }, [clientOptions]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setSensor({ ...sensor, [name]: value });
    };

    const fetchChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setSensor({ ...sensor, [name]: value });

        getFetchData(`${vendorsLink}/${value}/drivers`).then((response) => {
            if (response.drivers.length) {
                response.drivers.map((item: IDriver) => {
                    item.name = item.version;
                    return item;
                });
                setDriverVersionOptions(response.drivers);
            } else {
                setDriverVersionOptions([{ id: 0, name: 'Нет версий' }]);
                if (value === '0') return;
            }
        });
    };

    const commentChange = (event: Eventinfo, editor: ClassicEditor) => {
        const data = editor.getData();
        setSensor({ ...sensor, comment: data });
    };

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        // client side validation
        const errs = [];
        if (sensor.name === '') errs.push('name');
        if (sensor.deveui === '') errs.push('deveui');
        if (sensor.vendor_id === undefined) errs.push('vendor_id');
        setErrors(errs);
        if (errs.length > 0) return false;

        const data = new FormData(e.target);
        const payload: { [p: string]: FormDataEntryValue | number } = Object.fromEntries(
            data.entries()
        );

        // TODO: delete mock data using real backend
        payload.signal = 5;
        const date = new Date();
        const formattedDate = date.toISOString();
        payload.created_at = formattedDate;
        payload.updated_at = formattedDate;
        payload.started_at = formattedDate;
        payload.user_id = 1;
        payload.battery = 100;
        payload.state = 'Норма';
        payload.status = 'В обработке';

        postFetchData('/api/sensors/', { ...payload, client_id: +payload.client_id })
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    history.push({
                        pathname: '/admin/sensors'
                    });
                }
            })
            .catch(() => {
                setAlert({
                    type: 'alert-danger',
                    message: 'Не удалось создать запись'
                });
            });
    };

    const hasError = (key: number | string) => {
        return errors.indexOf(String(key as number)) !== -1;
    };

    return (
        <PageLayout title={'Новое устройство'}>
            <form onSubmit={onSubmit}>
                <div className="col-md-6 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <Alert type={alert.type} message={alert.message} />
                            <Input
                                title=""
                                name="comment"
                                type="hidden"
                                value={sensor.comment || ''}
                                errorDiv={'d-none'}
                                errorMsg={''}
                            />
                            <Input
                                className={hasError('name') ? 'is-invalid' : ''}
                                title="Название"
                                name="name"
                                type="text"
                                value={sensor.name || ''}
                                handleChange={handleChange}
                                errorDiv={hasError('name') ? 'text-danger' : 'd-none'}
                                errorMsg={'Укажите название'}
                                required={true}
                            />
                            <Input
                                className={hasError('deveui') ? 'is-invalid' : ''}
                                title="DevEUI"
                                name="deveui"
                                type="text"
                                value={sensor.deveui || ''}
                                handleChange={handleChange}
                                errorDiv={hasError('deveui') ? 'text-danger' : 'd-none'}
                                errorMsg={'Укажите DevEUI'}
                                required={true}
                            />
                            <div className="row">
                                <div className="col-lg-12 col-xl-7">
                                    <Select
                                        title="Драйвер"
                                        name="vendor_id"
                                        value={sensor.vendor_id || ''}
                                        options={driverOptions}
                                        handleChange={fetchChange}
                                        className={hasError('vendor_id') ? 'is-invalid' : ''}
                                        required={true}
                                    />
                                </div>
                                <div className="col-lg-12 col-xl-5">
                                    <Select
                                        title="Версия драйвера"
                                        name="driver_id"
                                        value={sensor.driver_id || ''}
                                        options={driverVersionOptions}
                                        handleChange={handleChange}
                                    />
                                </div>
                            </div>
                            <Select
                                title="Клиент"
                                name="client_id"
                                value={sensor.client_id || ''}
                                options={clientTree}
                                handleChange={handleChange}
                            />
                            <div className="mb-3">
                                <label className="form-label">Комментарий</label>
                                <CKEditor
                                    id="comment"
                                    name="comment"
                                    data=""
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: [
                                            'Bold',
                                            'Italic',
                                            '|',
                                            'NumberedList',
                                            'BulletedList',
                                            '|',
                                            'Link',
                                            '|',
                                            'Undo',
                                            'Redo'
                                        ]
                                    }}
                                    onReady={(editor) => {
                                        editor.editing.view.change((writer) => {
                                            writer.setStyle(
                                                'height',
                                                '100px',
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                editor.editing.view.document.getRoot()
                                            );
                                        });
                                    }}
                                    onChange={commentChange}
                                />
                            </div>
                            * — обязательные поля
                        </div>
                    </div>
                </div>

                <AbacProvider user={User} roles={[role]} rules={Rules}>
                    <AllowedTo perform={Permissions.WRITE_SUPPORT_EMPLOYEE}>
                        <button type="submit" className="btn btn-primary">
                            Сохранить
                        </button>{' '}
                    </AllowedTo>
                </AbacProvider>

                <Link to="/admin/sensors" className="btn btn-outline-secondary">
                    Назад
                </Link>
            </form>
        </PageLayout>
    );
};

export default SensorCreate;
