import { Link } from 'react-router-dom';
import { ColumnFilter } from '../../components/ColumnFilter';
import { Cell } from 'react-table';
import { IRow } from '../types';
import { getFormatDate } from '../../utils/utils';

export const COLUMNS = [
    {
        Header: 'Id',
        accessor: 'id',
        Filter: ColumnFilter,
        disableFilters: true
    },
    {
        Header: 'Фамилия',
        accessor: 'last_name',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ row }: IRow) => {
            return (
                <Link to={`/admin/employees/${row.original.id}/edit`}>
                    {row.original.last_name}
                </Link>
            );
        }
    },
    {
        Header: 'Имя',
        accessor: 'name',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ row }: IRow) => {
            return <Link to={`/admin/employees/${row.original.id}/edit`}>{row.original.name}</Link>;
        }
    },
    {
        Header: 'Отчество',
        accessor: 'middle_name',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ row }: IRow) => {
            return (
                <Link to={`/admin/employees/${row.original.id}/edit`}>
                    {row.original.middle_name}
                </Link>
            );
        }
    },
    {
        Header: 'Email',
        accessor: 'email',
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'Телефон',
        accessor: 'phone',
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'Дата создания',
        accessor: 'created_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        }
    },
    {
        Header: 'Дата изменения',
        accessor: 'updated_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        }
    }
];
