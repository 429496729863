import { ChangeEvent } from 'react';
import fetchMethod from '../utils/fetchMethod';
import { employees, subticketsEvent } from './mockUrls';

interface IPostData {
    [k: string]: FormDataEntryValue | (string | number | [] | undefined)[] | number | Date;
}

interface IUpdateData {
    [k: string]:
        | FormDataEntryValue
        | Date
        | string
        | number
        | readonly string[]
        | undefined
        | boolean;
}

export const fetchEmployees = async () => {
    const res = await fetchMethod({
        url: `${window.env.REACT_APP_API_URL + employees}`,
        method: 'GET'
    });

    const data = await res.json();
    return data.map((obj: { last_name: string; name: string }) => {
        return { id: obj.last_name + ' ' + obj.name, name: obj.last_name + ' ' + obj.name };
    });
};

export const fetchUsers = async (url: string) => {
    const res = await fetchMethod({
        url: `${window.env.REACT_APP_API_URL}${url}`,
        method: 'GET'
    });

    return res.json();
};

export const changeInputValue = (event: ChangeEvent<HTMLInputElement> | any) => {
    const typeEvent =
        event.type === 'paste' ? event.clipboardData.getData('text') : event.target.value;

    let newUrl = '';
    const value = typeEvent.replace(/\s/g, '').split(',');
    value.forEach((tags: string, index: number) => {
        if (event.type === 'paste' && tags.length < 5) return;
        newUrl += `&oid_like[${index}]=${tags}`;
    });

    return fetchMethod({
        url: `${window.env.REACT_APP_API_URL + subticketsEvent}${newUrl}`,
        method: 'GET'
    })
        .then((response) => response.json())
        .catch((e) => e);
};

export const getPageList = (url: string, params: string) => {
    return fetchMethod({
        url: url + params,
        method: 'GET'
    });
};

export const getFetchData = (url: string) => {
    return fetchMethod({
        url: `${window.env.REACT_APP_API_URL}${url}`,
        method: 'GET'
    })
        .then((response) => response.json())
        .catch((e) => console.error(e, 'error'));
};

export const postFetchData = (url: string, data: IPostData | any) => {
    return fetchMethod({
        url: `${window.env.REACT_APP_API_URL}${url}`,
        method: 'POST',
        params: data
    });
};

export const updateFetchData = async (url: string, data: IUpdateData | any) => {
    return await fetchMethod({
        url: `${window.env.REACT_APP_API_URL}${url}`,
        method: 'PATCH',
        params: data
    });
};

export const updateSensorsData = (url: string, data: IUpdateData) => {
    return fetchMethod({
        url: `${window.env.REACT_APP_API_URL}${url}`,
        method: 'PUT',
        params: data
    });
};

export const deleteFetchData = (url: string) => {
    return fetchMethod({
        url: url,
        method: 'DELETE'
    });
};

export const deleteSensorsData = (id: string) => {
    return fetchMethod({
        url: `${window.env.REACT_APP_API_URL}/api/sensor-groups/${id}`,
        method: 'DELETE'
    });
};

export const getTimeZoneList = async (url: string) => {
    const res = await fetchMethod({
        url: `${window.env.REACT_APP_API_URL}${url}`,
        method: 'GET'
    });

    return res.json();
};

export const getData = async (url: string) => {
    const res = await fetchMethod({
        url: `${window.env.REACT_APP_API_URL}${url}`,
        method: 'GET'
    });

    return res.json();
};

export const updateData = (url: string, data: IUpdateData) => {
    return fetchMethod({
        url: `${window.env.REACT_APP_API_URL + url}`,
        method: 'PUT',
        params: data
    });
};

export const deleteData = (url: string) => {
    return fetchMethod({
        url: `${window.env.REACT_APP_API_URL + url}`,
        method: 'DELETE'
    });
};
