import React, { useContext, useEffect, useState } from 'react';
import { getFetchData } from '../../api';
import Alert from '../../components/Ui/components-ui/Alert';
import AvatarDialog from './Avatar';
import ProfileEdit from './ProfileEdit';
import { loadingData } from '../../utils/utils';
import { AvatarContext } from '../../Context/AvatarContext';
import { profileApi } from '../../api/mockUrls';

const initialProfile = {
    avatar: '',
    id: 0,
    name: '',
    email: '',
    email_verified_at: '',
    created_at: '',
    updated_at: '',
    user_id: 0,
    role: '',
    last_name: '.',
    middle_name: '.',
    phone: '',
    timezone: '',
    timezone_id: 1
};

const Profile = () => {
    const { isPost, setIsPost } = useContext(AvatarContext);
    const [profile, setProfile] = useState(initialProfile);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState<null | string>(null);

    useEffect(() => {
        setIsLoaded(false);
        getFetchData(profileApi)
            .then((response) => {
                if (response) {
                    setError(null);
                    setProfile(response);
                    setIsLoaded(true);
                } else {
                    setError('Internal server error');
                }
            })
            .catch(() => {
                setError('Internal server error');
            })
            .finally(() => {
                setIsLoaded(true);
            });
    }, [isPost]);

    useEffect(() => {
        loadingData(isLoaded);
    }, [isLoaded, isPost]);

    return (
        <main className="content">
            {error ? (
                <div className="col-auto d-sm-block">
                    <h3>Профиль</h3>
                    <Alert type={'alert-danger'} message={error} />
                </div>
            ) : (
                <>
                    <div className="container-fluid p-0">
                        <div className="row mb-2 mb-xl-3">
                            <div className="col-auto d-sm-block">
                                <h3>Профиль</h3>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-9 col-xl-9">
                                <div className="card p-3">
                                    <ProfileEdit
                                        profile={profile}
                                        setProfile={setProfile}
                                        setIsLoaded={setIsLoaded}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 col-xl-3">
                                <AvatarDialog profile={profile} setIsPost={setIsPost} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </main>
    );
};

export default Profile;
