import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { format } from 'date-fns';
import Alert from '../../components/Ui/components-ui/Alert';
import Messages from './Messages';
import { TId } from '../types';
import { getFetchData } from '../../api';

const TicketComments = () => {
    const { id } = useParams<TId>();

    const [isLoaded, setIsLoaded] = useState(true);
    const [ticket, setTicket] = useState({
        uid: '',
        lastname: '',
        status: '',
        firstname: '',
        created_at: ''
    });
    const [alert, setAlert] = useState({
        type: 'd-none',
        message: ''
    });

    useEffect(() => {
        getFetchData(`/api/tickets/${id}/`)
            .then((response) => {
                setAlert({
                    type: 'd-none',
                    message: ''
                });
                setTicket(response);
            })
            .then(() => setIsLoaded(true))
            .catch((error) => {
                const data = { comment: '' };
                setAlert({
                    type: 'alert-danger',
                    message: 'Invalid response code: ' + error.status
                });
                return data;
            });
    }, [id]);

    if (!isLoaded) {
        const elem = document.getElementById('preloader');
        if (elem) elem.classList.remove('d-none');
    } else if (isLoaded) {
        const elem = document.getElementById('preloader');
        if (elem) elem.classList.add('d-none');
    }

    return (
        <main className="content">
            <div className="container-fluid p-0 mb-4">
                <div className="row mb-2 mb-xl-3">
                    <div className="col-auto d-sm-block">
                        <h3>Заявка {ticket.uid}</h3>
                        <div style={{ position: 'relative', top: '-5px' }}>
                            <span className="badge bg-info">{ticket.status}</span>&nbsp;
                            <span className="badge bg-light text-dark">
                                {ticket.lastname ? ticket.lastname + ' ' + ticket.firstname : ''}
                            </span>
                            <span
                                className="badge bg-light text-dark"
                                style={{ fontWeight: 'normal' }}>
                                {ticket.created_at
                                    ? format(new Date(ticket.created_at), 'dd-MM-yyyy HH:mm:ss')
                                    : ''}
                            </span>
                        </div>
                    </div>
                    <div className="col-auto ms-auto text-end mt-n1" />
                </div>
            </div>

            <div className="col-md-12 col-xl-12">
                <Alert type={alert.type} message={alert.message} />
                <ul
                    className="nav mb-3"
                    role="tablist"
                    style={{ borderBottom: '1px solid #e4e4e4' }}>
                    <li className="nav-item">
                        <Link
                            className="nav-link"
                            style={{ color: '#000', fontSize: '12px' }}
                            to={`/admin/tickets/${id}/subtickets`}>
                            Состав
                        </Link>
                    </li>
                    <li className="nav-item" style={{ borderBottom: '3px solid #3b7ddd' }}>
                        <Link
                            className="nav-link"
                            style={{ color: '#000', fontSize: '12px' }}
                            to={`/admin/tickets/${id}/comments`}>
                            Комментарии
                        </Link>
                    </li>
                </ul>
            </div>

            <div className="col-md-12 col-xl-12">
                <Messages />
            </div>
        </main>
    );
};

export default TicketComments;
