import React, { useState } from 'react';
import Input from '../../../components/Ui/components-form/Input';
import { Dialog } from '@material-ui/core';
import CreateDecoder from './CreateDecoder';
import { IDecoderData, IDecoderMetric, IDriver, IMetric, ITypeList } from '../../types';
import { postFetchData } from '../../../api';
import { decoderCheck } from '../../../api/mockUrls';
import JsonEditor from '../../connectors/JsonEditor';
import useError from '../../../hooks/useError';
import { Edit2, Trash2 } from 'react-feather';
import { uid } from '../../../utils/id';
import EditDecoder from './EditDecoder';
import { confirmAlert } from 'react-confirm-alert';

interface IDecoder {
    driver: IDriver;
    metric: IMetric[];
    formula: ITypeList[];
    decoder: IDecoderData;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    setResult: any;
    setDecoder: (decoder: IDecoderData) => void;
    isLoaded: boolean;
    showAlert: (type: string, message: string) => void;
}

interface IDecoderFormCreate {
    metric: string;
    pos: string;
}

const initialMetric: IDecoderMetric = {
    id: '',
    metric: '',
    typeBytes: '',
    typeMetric: '',
    formula: '',
    pos: '',
    metricSelected: '',
    selectedFormula: ''
};

const getJsonData = (data: IDecoderMetric[]) =>
    data
        .map(({ metricSelected, selectedFormula, id, ...rest }) => rest)
        .sort((a, b) => a.metric.localeCompare(b.metric));

const Decoder = ({
    driver,
    handleChange,
    metric,
    formula,
    showAlert,
    setResult,
    isLoaded,
    decoder,
    setDecoder
}: IDecoder) => {
    const {
        clearError,
        getErrorMessage,
        hasError,
        hasErrors,
        validateField,
        clearAllErrors,
        setError
    } = useError();

    const [decoderMetrics, setDecoderMetrics] = useState(initialMetric);
    const [isDecoder, setIsDecoder] = useState(false);
    const [isDecoderEdit, setIsDecoderEdit] = useState(false);
    const [selectedDecoder, setSelectedDecoder] = useState(initialMetric);

    const validateForm = (): boolean => {
        clearAllErrors();
        let isValid = true;

        if (!driver.payload?.trim()) {
            setError('payload', 'Это поле необходимо заполнить');
            isValid = false;
        }

        return isValid;
    };

    const validateFormCreate = (): boolean => {
        clearAllErrors();

        const validations: {
            [K in keyof IDecoderFormCreate]: (value: IDecoderFormCreate[K]) => boolean;
        } = {
            metric: (value) => value.trim() === '',
            pos: (value) => value === ''
        };

        return Object.entries(validations).reduce((isValid: boolean, [key, validate]) => {
            const value = decoderMetrics[key as keyof IDecoderFormCreate];
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (validate(value)) {
                setError(key, 'Это поле необходимо заполнить');
                return false;
            }
            return isValid;
        }, true);
    };

    const handleChangeDecoder = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        setDecoderMetrics((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;

        if (value !== '0') {
            clearError(name);
            setDecoderMetrics((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const addDecoder = () => {
        const isFormValid = validateFormCreate();
        if (hasErrors() || !isFormValid) {
            console.log('Форма содержит ошибки. Отправка данных отменена.');
            return;
        } else {
            setIsDecoder(!isDecoder);
        }

        const typeMetricName = metric.find((item: IMetric) => item.id === +decoderMetrics.metric);
        const typeFormulaName = formula.find(
            (item: ITypeList) => decoderMetrics?.formula && item.id === +decoderMetrics?.formula
        )?.name;

        const decoderObject = {
            ...decoderMetrics,
            id: uid(),
            metricSelected: decoderMetrics.metric,
            selectedFormula: decoderMetrics.formula,
            metric: typeMetricName ? typeMetricName.name : '',
            typeBytes: typeMetricName?.type_byte,
            typeMetric: typeMetricName?.type_metric,
            formula: typeFormulaName,
            pos: +decoderMetrics.pos
        };

        if (!decoderObject.metric && !decoderMetrics.pos) return;

        if (decoder.decoder_metrics?.length) {
            setDecoder({
                ...decoder,
                decoder_metrics: [...decoder.decoder_metrics, decoderObject]
            });
        } else {
            setDecoder({
                ...decoder,
                decoder_metrics: [decoderObject]
            });
        }

        setDecoderMetrics(initialMetric);
    };

    const editCurrentDecoder = (item: IDecoderMetric) => {
        const isFormValid = validateFormCreate();
        if (hasErrors() || !isFormValid) {
            console.log('Форма содержит ошибки. Отправка данных отменена.');
            return;
        } else {
            setIsDecoderEdit(!isDecoderEdit);
        }

        const typeMetricName = metric.find((item: IMetric) => item.id === +decoderMetrics.metric);
        const typeFormulaName = formula.find(
            (item: ITypeList) => decoderMetrics?.formula && item.id === +decoderMetrics?.formula
        )?.name;
        const updatedDecoderSaveArray = decoder.decoder_metrics.map((dec: IDecoderMetric) => {
            if (dec.id === item.id) {
                return {
                    ...item,
                    id: uid(),
                    metricSelected: decoderMetrics.metric,
                    selectedFormula: decoderMetrics.formula,
                    metric: typeMetricName ? typeMetricName.name : '',
                    typeBytes: typeMetricName?.type_byte,
                    typeMetric: typeMetricName?.type_metric,
                    formula: typeFormulaName,
                    pos: +decoderMetrics.pos
                };
            }
            return dec;
        });

        setDecoder({ ...decoder, decoder_metrics: updatedDecoderSaveArray });
    };

    const removeItemById = (id: string) => {
        confirmAlert({
            title: 'Удаление',
            message: 'Вы действительно желаете отправить данные?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const filteredItems = decoder.decoder_metrics.filter(
                            (item) => item.id !== id
                        );
                        setDecoder({ ...decoder, decoder_metrics: filteredItems });
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log('Отправка данных отменена')
                }
            ]
        });
    };

    const editDecoder = (item: IDecoderMetric) => {
        setSelectedDecoder(item);
    };

    const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const isFormValid = validateForm();

        if (hasErrors() || !isFormValid) {
            console.log('Форма содержит ошибки. Отправка данных отменена.');
            return;
        }

        const postData = {
            decoder_name: `${driver.name} ${driver.version}`,
            decoder_metrics: decoder.decoder_metrics
        };

        console.log(postData, 'postData');

        try {
            const response = await postFetchData(`${decoderCheck}${driver.payload}`, postData);

            if (response.status === 200 || response.status === 201) {
                const result = await response.json();
                setResult(result);
            } else {
                showAlert('alert-danger', 'Unexpected response status: ' + response.status);
            }
        } catch (error: any) {
            showAlert('alert-danger', 'Invalid response code: ' + error.status);
        }
    };

    return (
        <section>
            <div className="row align-items-center mb-3">
                <h5 className="col">
                    <b>Декодер</b>
                </h5>
                <div className="col text-end">
                    <button className="btn btn-primary" onClick={() => setIsDecoder(!isDecoder)}>
                        <i className="fas fa-plus" />
                    </button>
                </div>
            </div>
            {isLoaded && (
                <ul className="metrics-list mt-3">
                    {decoder.decoder_metrics?.length ? (
                        decoder.decoder_metrics
                            ?.sort((a, b) => a.metric.localeCompare(b.metric))
                            .map((item: IDecoderMetric) => {
                                console.log(item, 'item');
                                return (
                                    <li key={item.id} className="metrics-list__item">
                                        <div className="metrics-list__content">
                                            <span>{item.metric}</span>
                                            <span>{`[${item?.typeMetric}]`}</span>{' '}
                                            <span>{item?.typeBytes}</span>
                                        </div>
                                        <div className="metrics-list__action">
                                            <span
                                                onClick={() => {
                                                    editDecoder(item);
                                                    setIsDecoderEdit(true);
                                                }}>
                                                <Edit2
                                                    className="metrics-list__edit mr-2"
                                                    size={18}
                                                />
                                            </span>{' '}
                                            <span onClick={() => removeItemById(item.id)}>
                                                {' '}
                                                <Trash2
                                                    className="metrics-list__delete"
                                                    size={18}
                                                />{' '}
                                            </span>
                                        </div>
                                    </li>
                                );
                            })
                    ) : (
                        <li>Нет заданных метрик</li>
                    )}
                </ul>
            )}
            <Input
                className={hasError('payload') ? 'is-invalid' : ''}
                title="Укажите пример пейлоада"
                name="payload"
                type="text"
                placeholder="Введите мтерику"
                handleChange={handleChange}
                value={driver.payload || ''}
                errorDiv={hasError('payload') ? 'text-danger' : 'd-none'}
                errorMsg={getErrorMessage('payload')}
                required={true}
                onBlur={(e) => validateField(e.target.name, e.target.value)}
            />
            {isDecoder && (
                <Dialog
                    onClose={() => setIsDecoder(false)}
                    className="dialog-decoder"
                    open={isDecoder}
                    PaperProps={{
                        style: { width: '600px', fontFamily: 'Inter' }
                    }}>
                    <CreateDecoder
                        metric={metric}
                        formula={formula}
                        decoderMetrics={decoderMetrics}
                        setDecoderMetrics={setDecoderMetrics}
                        isLoaded={isLoaded}
                        addDecoder={addDecoder}
                        hasError={hasError}
                        handleChange={handleChangeDecoder}
                        handleSelectChange={handleSelectChange}
                        getErrorMessage={getErrorMessage}
                        validateField={validateField}
                        setIsDecoder={setIsDecoder}
                        clearAllErrors={clearAllErrors}
                    />
                </Dialog>
            )}
            {isDecoderEdit && (
                <Dialog
                    onClose={() => setIsDecoderEdit(false)}
                    className="dialog-decoder"
                    open={isDecoderEdit}
                    PaperProps={{
                        style: { width: '600px', fontFamily: 'Inter' }
                    }}>
                    <EditDecoder
                        selectedDecoder={selectedDecoder}
                        decoderMetrics={decoderMetrics}
                        metric={metric}
                        formula={formula}
                        handleSelectChange={handleSelectChange}
                        handleChange={handleChangeDecoder}
                        getErrorMessage={getErrorMessage}
                        validateField={validateField}
                        hasError={hasError}
                        setDecoderMetrics={setDecoderMetrics}
                        editCurrentDecoder={editCurrentDecoder}
                        isLoaded={isLoaded}
                        clearAllErrors={clearAllErrors}
                        setIsDecoderEdit={setIsDecoderEdit}
                    />
                </Dialog>
            )}
            <JsonEditor
                value={getJsonData(decoder.decoder_metrics)}
                setValue={setDecoder}
                readOnly={true}
            />
            <button onClick={onSubmit} className="btn btn-primary mt-3">
                preview
            </button>
        </section>
    );
};

export default Decoder;
