import { useEffect, useMemo, useState } from 'react';
import { deleteData, getFetchData } from '../api';
import { formulaLink, metricListLink, typeByteLink, typeMetricLink } from '../api/mockUrls';
import { IDecoderData, IDriver, IMetric, IPageOptions, ITypeList, TId } from '../pages/types';
import { useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';

const initialDriver: IDriver = {
    id: 0,
    user_id: 0,
    vendor_id: 0,
    name: '',
    version: '',
    fio: '',
    comment: '',
    metrics: [],
    preview: null,
    go_flag: false,
    flag: false,
    created_at: '',
    updated_at: ''
};

const initialDecoderData: IDecoderData = {
    decoder_name: '',
    decoder_metrics: []
};

export const useDriver = () => {
    const { id } = useParams<TId>();
    const [isLoaded, setIsLoaded] = useState(true);
    const [driver, setDriver] = useState(initialDriver);
    const [data, setData] = useState('');
    const [driverOptions, setDriverOptions] = useState<IPageOptions[]>([]);
    const [metric, setMetric] = useState<IMetric[]>([]);
    const [formula, setFormula] = useState<ITypeList[]>([]);
    const [typeMetric, setTypeMetric] = useState<ITypeList[]>([]);
    const [typeByte, setTypeByte] = useState<ITypeList[]>([]);
    const [decoder, setDecoder] = useState(initialDecoderData);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        setIsLoaded(false);
        getFetchData(`${metricListLink}`)
            .then((response) => {
                setMetric(response);
            })
            .finally(() => setIsLoaded(true));
    }, [reload]);

    useEffect(() => {
        setIsLoaded(false);
        getFetchData(`${formulaLink}`)
            .then((response) => {
                setFormula(response);
            })
            .finally(() => setIsLoaded(true));
    }, []);

    useEffect(() => {
        setIsLoaded(false);
        getFetchData(`${typeMetricLink}`)
            .then((response) => {
                setTypeMetric(response);
            })
            .finally(() => setIsLoaded(true));
    }, []);

    useEffect(() => {
        setIsLoaded(false);
        getFetchData(`${typeByteLink}`)
            .then((response) => {
                setTypeByte(response);
            })
            .finally(() => setIsLoaded(true));
    }, []);

    useEffect(() => {
        setIsLoaded(false);
        getFetchData(`/api/drivers/${id}/`)
            .then((response) => {
                if (response) {
                    const decoders = response?.metrics
                        ? JSON.parse(response.metrics.split(', '))
                        : [];
                    setDriver(response);
                    setDecoder(decoders);
                    setData(response?.comment || '');
                }
            })
            .finally(() => setIsLoaded(true));

        getFetchData('/api/vendors/list')
            .then((response) => {
                if (!response?.length) {
                    setDriverOptions([{ id: 0, name: 'Не выбран' }]);
                    return;
                }
                setDriverOptions(response);
            })
            .finally(() => setIsLoaded(true));
    }, [id]);

    const deleteMetric = async (id: number) => {
        confirmAlert({
            title: 'Удаление',
            message: 'Вы действительно желаете отправить данные?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            const response = await deleteData(`${metricListLink}/${id}`);
                            if (response.status === 200 || response.status === 201) {
                                const updatedMetrics = metric.filter(
                                    (item: IMetric) => item.id !== id
                                );
                                setMetric(updatedMetrics);
                            }
                        } catch (error: any) {
                            console.error('Ошибка при удалении метрики: ', error.status);
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log('Отправка данных отменена')
                }
            ]
        });
    };

    return useMemo(
        () => ({
            isLoaded,
            driver,
            data,
            driverOptions,
            metric,
            formula,
            typeMetric,
            typeByte,
            setDriver,
            setMetric,
            deleteMetric,
            setReload,
            reload,
            decoder,
            setDecoder
        }),
        [
            isLoaded,
            driver,
            data,
            driverOptions,
            metric,
            formula,
            typeMetric,
            typeByte,
            reload,
            decoder
        ]
    );
};
