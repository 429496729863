import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Input from '../../components/Ui/components-form/Input';
import Alert from '../../components/Ui/components-ui/Alert';
import { Permissions, User, Rules } from '../../utils/rules';
import { AbacProvider, AllowedTo } from 'react-abac';
import PageLayout from '../../Layouts/PageLayout';
import { Eventinfo } from '../types';
import { postFetchData } from '../../api';
import { SubTicketContext } from '../../Context/SubTicketContext';

const EmployeeCreate = () => {
    const { employeeUpdate } = useContext(SubTicketContext);
    const [role, setRole] = useState('');

    useEffect(() => {
        const storage = localStorage.getItem('datanorm.user');
        setRole(storage ? storage.toUpperCase() : 'USER');
    }, []);

    const history = useHistory();
    const [employee, setEmployee] = useState({
        name: '',
        last_name: '',
        middle_name: '',
        comment: '',
        email: '',
        phone: ''
    });
    const [errors, setErrors] = useState<string[]>([]);
    const [alert, setAlert] = useState({
        type: 'd-none',
        message: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setEmployee({ ...employee, [name]: value });
    };

    const commentChange = (event: Eventinfo, editor: ClassicEditor) => {
        const data = editor.getData();
        setEmployee({ ...employee, comment: data });
    };

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        // employee side validation
        const errs = [];
        if (employee.name === '') errs.push('name');
        if (employee.last_name === '') errs.push('last_name');
        if (employee.middle_name === '') errs.push('middle_name');
        setErrors(errs);
        if (errs.length > 0) return false;

        const data = new FormData(e.target);
        const payload = Object.fromEntries(data.entries());

        postFetchData('/api/employees/', payload)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    employeeUpdate();
                    history.push({
                        pathname: '/admin/employees'
                    });
                }
            })
            .catch(() => {
                setAlert({
                    type: 'alert-danger',
                    message: 'Не удалось создать запись'
                });
            });
    };

    const hasError = (key: string | number) => {
        return errors.indexOf(String(key as number)) !== -1;
    };

    return (
        <PageLayout title={'Новый ремонтник'}>
            <form onSubmit={onSubmit}>
                <div className="col-md-6 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <Alert type={alert.type} message={alert.message} />
                            <Input
                                title=""
                                name="comment"
                                type="hidden"
                                value={employee.comment || ''}
                                errorDiv={'d-none'}
                                errorMsg={''}
                            />
                            <Input
                                className={hasError('last_name') ? 'is-invalid' : ''}
                                title="Фамилия"
                                name="last_name"
                                type="text"
                                value={employee.last_name || ''}
                                handleChange={handleChange}
                                errorDiv={hasError('last_name') ? 'text-danger' : 'd-none'}
                                errorMsg={'Поле не может быть пустым'}
                                required={true}
                            />
                            <Input
                                className={hasError('name') ? 'is-invalid' : ''}
                                title="Имя"
                                name="name"
                                type="text"
                                value={employee.name || ''}
                                handleChange={handleChange}
                                errorDiv={hasError('name') ? 'text-danger' : 'd-none'}
                                errorMsg={'Поле не может быть пустым'}
                                required={true}
                            />
                            <Input
                                className={hasError('middle_name') ? 'is-invalid' : ''}
                                title="Отчество"
                                name="middle_name"
                                type="text"
                                value={employee.middle_name || ''}
                                handleChange={handleChange}
                                errorDiv={hasError('middle_name') ? 'text-danger' : 'd-none'}
                                errorMsg={'Поле не может быть пустым'}
                                required={true}
                            />
                            <Input
                                className={hasError('email') ? 'is-invalid' : ''}
                                title="Email"
                                name="email"
                                type="text"
                                value={employee.email || ''}
                                handleChange={handleChange}
                            />
                            <Input
                                className={''}
                                title="Телефон"
                                name="phone"
                                type="text"
                                value={employee.phone || ''}
                                handleChange={handleChange}
                                errorDiv={'d-none'}
                                errorMsg={''}
                            />
                            <div className="mb-3">
                                <label className="form-label">Комментарий</label>
                                <CKEditor
                                    id="comment"
                                    name="comment"
                                    data=""
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: [
                                            'Bold',
                                            'Italic',
                                            '|',
                                            'NumberedList',
                                            'BulletedList',
                                            '|',
                                            'Link',
                                            '|',
                                            'Undo',
                                            'Redo'
                                        ]
                                    }}
                                    onReady={(editor) => {
                                        editor.editing.view.change((writer) => {
                                            writer.setStyle(
                                                'height',
                                                '100px',
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                editor.editing.view.document.getRoot()
                                            );
                                        });
                                    }}
                                    onChange={commentChange}
                                />
                            </div>
                            * — обязательные поля
                        </div>
                    </div>
                </div>

                <AbacProvider user={User} roles={[role]} rules={Rules}>
                    <AllowedTo perform={Permissions.WRITE_SUPPORT_EMPLOYEE}>
                        <button type="submit" className="btn btn-primary">
                            Сохранить
                        </button>{' '}
                    </AllowedTo>
                </AbacProvider>

                <Link to="/admin/employees" className="btn btn-outline-secondary">
                    Назад
                </Link>
            </form>
        </PageLayout>
    );
};

export default EmployeeCreate;
