import type { Dispatch } from 'redux';
import { setSettings, setSettingsError, setSettingsLoading, setSettingsSuccess } from './reducer';
import { getData, updateData } from '../../api';
import { profileApi } from '../../api/mockUrls';

export const getSettings = () => (dispatch: Dispatch) => {
    dispatch(setSettingsLoading(true));
    dispatch(setSettingsError(null));
    return getData(profileApi)
        .then((response) => {
            dispatch(setSettings(response));
        })
        .catch((e) => {
            dispatch(setSettingsError(e));
        })
        .finally(() => {
            dispatch(setSettingsLoading(false));
        });
};

export const updateSettings = (data: { alert: number }) => (dispatch: Dispatch) => {
    dispatch(setSettingsLoading(true));
    dispatch(setSettingsError(null));
    dispatch(setSettingsSuccess(false));
    return updateData(`${profileApi}/settings/alert`, data)
        .then((response) => {
            return response.json();
        })
        .then((res) => {
            dispatch(setSettings(res));
            dispatch(setSettingsSuccess(true));
        })
        .catch((e) => {
            dispatch(setSettingsError(e));
            dispatch(setSettingsSuccess(false));
        })
        .finally(() => {
            dispatch(setSettingsLoading(false));
        });
};
