import React from 'react';
import Input from '../../../components/Ui/components-form/Input';

interface IConnectorsHeaderProps {
    header: string;
    value: string;
    id: string;
    deleteHeaders: (id: string) => void;
    handleEditInput: (e: React.ChangeEvent<HTMLInputElement>, name: string, id: string) => void;
}

const ConnectorsHeaderItem = ({
    header,
    value,
    id,
    deleteHeaders,
    handleEditInput
}: IConnectorsHeaderProps) => {
    return (
        <>
            <div className="col-sm-5">
                <Input
                    name="header"
                    type="text"
                    handleChange={(e) => handleEditInput(e, 'header', id)}
                    value={header || ''}
                />
            </div>
            <div className="col-sm-6">
                <Input
                    name="value"
                    type="text"
                    handleChange={(e) => handleEditInput(e, 'value', id)}
                    value={value || ''}
                />
            </div>
            <div className="col-sm-1 text-end pl-0 mb-3">
                <button className="btn btn-primary" onClick={() => deleteHeaders(id)}>
                    <i className="fas fa-minus" />
                </button>
            </div>
        </>
    );
};

export default ConnectorsHeaderItem;
