import React, { useMemo } from 'react';
import AdvancedPage from '../../../components/Table/AdvancedPage/AdvancedPage';
import { Permissions } from '../../../utils/rules';
import { COLUMNS } from './SensorGroupsListColumns';

const SensorsGroupList = () => {
    const columns = useMemo(() => COLUMNS, []);

    return (
        <main className="content">
            <AdvancedPage
                pageHeader={'Список групп'}
                btnAddUrl={'/admin/sensors/groups/create'}
                btnAddPermissions={Permissions.WRITE_SUPPORT_SENSORS_GROUPS}
                btnDelPermissions={Permissions.WRITE_SUPPORT_SENSORS_GROUPS}
                url={window.env.REACT_APP_API_URL + '/api/sensor-groups'}
                columns={columns}
                dltUrl={`${window.env.REACT_APP_API_URL}/api/sensor-groups`}
            />
        </main>
    );
};

export default SensorsGroupList;
