import React, { ChangeEvent, useEffect } from 'react';

interface IStyle {
    cursor: string;
}

interface IRest {
    checked?: boolean;
    onChange: (e: ChangeEvent) => void;
    style: IStyle;
    title: string;
}

interface ICheckbox {
    indeterminate?: boolean;
    rest?: IRest;
}

export const Checkbox = React.forwardRef(({ indeterminate, ...rest }: ICheckbox, ref: any) => {
    const defaultRef = React.useRef<HTMLInputElement>(null);
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
        if (resolvedRef.current) {
            resolvedRef.current.indeterminate = indeterminate;
        }
    }, [resolvedRef, indeterminate]);

    return (
        <>
            <input
                onClick={(event) => event.stopPropagation()}
                className="form-check-inline"
                type="checkbox"
                ref={resolvedRef}
                {...rest}
            />
        </>
    );
});
