import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { getTimeZoneList, updateSensorsData } from '../../api';
import { isEmail } from '../../utils/utils';
import Input from '../../components/Ui/components-form/Input';
import type { IProfile } from '../types';
import Toast from '../../components/Ui/toast/Toast';
import { profileApi } from '../../api/mockUrls';

interface IProfileEditProps {
    profile: IProfile;
    setProfile: (profile: IProfile) => void;
    setIsLoaded: (isLoaded: boolean) => void;
}

interface ITimezoneOptions {
    [key: string]: string;
}

const ProfileEdit = ({ profile, setProfile, setIsLoaded }: IProfileEditProps) => {
    const [timeZone, setTimeZone] = useState<ITimezoneOptions>({});
    const [errors, setErrors] = useState<string[]>([]);
    const [alert, setAlert] = useState({
        type: '',
        message: ''
    });

    useEffect(() => {
        setIsLoaded(false);
        getTimeZoneList(`${profileApi}/timezone/list`).then((response) => {
            setTimeZone({ 0: 'Не выбрано', ...response });
            setIsLoaded(true);
        });
    }, [setIsLoaded]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setProfile({ ...profile, [name]: value });
    };

    const findKeyByValue = (obj: ITimezoneOptions, value: string): string => {
        const keys = Object.keys(obj);
        for (const key of keys) {
            if (obj[key] === value) {
                return key;
            }
        }
        return 'не выбрано';
    };

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errs = [];
        if (profile.name === '') errs.push('name');
        if (profile.last_name === '') errs.push('last_name');
        if (profile.middle_name === '') errs.push('middle_name');
        if (!isEmail(profile.email)) errs.push('email');
        setErrors(errs);
        if (errs.length > 0) return false;

        const data = new FormData(e.target);
        const payload = Object.fromEntries(data.entries());

        const foundKey = findKeyByValue(timeZone, profile.timezone);
        localStorage.setItem('datanorm.timezone', foundKey);

        const postData = {
            ...payload,
            timezone: foundKey
        };

        setAlert({ type: '', message: '' });

        setIsLoaded(false);
        updateSensorsData(`/api/profile`, postData)
            .then(() => {
                setIsLoaded(true);
                setAlert({
                    type: 'd-none',
                    message: ''
                });
            })
            .catch((error) => {
                setIsLoaded(true);
                setAlert({
                    type: 'alert-danger',
                    message: 'Invalid response code: ' + error.status
                });
            });
    };

    const hasError = (key: number | string) => {
        return errors.indexOf(String(key as number)) !== -1;
    };

    return (
        <div className="container-fluid p-0">
            {alert.type === 'alert-danger' && (
                <Toast message={`Invalid response code:`} type={'alert-danger'} />
            )}
            {alert.type === 'd-none' && (
                <Toast message={'Данные сохранены'} type={'alert-success'} />
            )}
            <div className="row">
                <form onSubmit={onSubmit}>
                    <div className="col-md-9 col-xl-9">
                        <div className="card-body">
                            {/*<Alert type={alert.type} message={alert.message} />*/}
                            <Input
                                className={hasError('email') ? 'is-invalid' : ''}
                                title="Email"
                                name="email"
                                type="text"
                                value={profile.email || ''}
                                handleChange={handleChange}
                                errorDiv={hasError('email') ? 'text-danger' : 'd-none'}
                                errorMsg={'не корректный email'}
                                required={true}
                            />
                            <Input
                                className={hasError('last_name') ? 'is-invalid' : ''}
                                title="Фамилия"
                                name="last_name"
                                type="text"
                                value={profile.last_name || ''}
                                handleChange={handleChange}
                                errorDiv={hasError('last_name') ? 'text-danger' : 'd-none'}
                                errorMsg={'Поле не может быть пустым'}
                                required={true}
                            />
                            <Input
                                className={hasError('name') ? 'is-invalid' : ''}
                                title="Имя"
                                name="name"
                                type="text"
                                value={profile.name || ''}
                                handleChange={handleChange}
                                errorDiv={hasError('name') ? 'text-danger' : 'd-none'}
                                errorMsg={'Поле не может быть пустым'}
                                required={true}
                            />
                            <Input
                                className={hasError('middle_name') ? 'is-invalid' : ''}
                                title="Отчество"
                                name="middle_name"
                                type="text"
                                value={profile.middle_name || ''}
                                handleChange={handleChange}
                                errorDiv={hasError('middle_name') ? 'text-danger' : 'd-none'}
                                errorMsg={'Поле не может быть пустым'}
                                required={true}
                            />
                            {Object.values(timeZone).length ? (
                                <div className="mb-3 select-wrapper timezone-select__wrapper">
                                    <label className="form-label">TimeZone</label>
                                    <Autocomplete
                                        className="timezone-select"
                                        value={timeZone[profile.timezone] || profile.timezone}
                                        onChange={(event, newValue: string | null) => {
                                            setProfile({
                                                ...profile,
                                                timezone: newValue ? newValue : ''
                                            });
                                        }}
                                        id="controllable-states-demo"
                                        renderInput={(params) => (
                                            <TextField variant="outlined" {...params} />
                                        )}
                                        options={Object.values(timeZone)}
                                    />
                                </div>
                            ) : (
                                ''
                            )}
                            * — обязательные поля
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Сохранить
                    </button>{' '}
                </form>
            </div>
        </div>
    );
};

export default ProfileEdit;
