import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Input from '../../components/Ui/components-form/Input';
import Select from '../../components/Ui/components-form/Select';
import SelectAll from '../../components/Ui/components-form/SelectAll';
import Alert from '../../components/Ui/components-ui/Alert';
import { IDriver, IPageOptions, Eventinfo } from '../types';
import { getFetchData, postFetchData } from '../../api';
import AllowLayout from '../../Layouts/AllowLayout';
import { Permissions } from '../../utils/rules';
import { driversLink, vendorsLink } from '../../api/mockUrls';

const DriverCreate = () => {
    const history = useHistory();
    const [driver, setDriver] = useState({
        name: '',
        fio: '',
        vendor_id: '',
        version: '',
        versions: '',
        comment: ''
    });
    const [driverOptions, setDriverOptions] = useState<IPageOptions[]>([]);
    const [driverVersionOptions, setDriverVersionOptions] = useState<IPageOptions[]>([
        { id: 0, name: 'Нет версий' }
    ]);
    const [errors, setErrors] = useState<string[]>([]);
    const [alert, setAlert] = useState({
        type: 'd-none',
        message: ''
    });

    useEffect(() => {
        getFetchData(`${vendorsLink}/list`).then((response) => {
            if (!response?.length) {
                setDriverOptions([{ id: 0, name: 'Не выбран' }]);
                return;
            }
            setDriverOptions(response);
        });
    }, []);

    const fetchChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setDriver({ ...driver, [name]: value });
        console.log(driver, 'driver');

        getFetchData(`${vendorsLink}/${value}/drivers`).then((response) => {
            if (response?.drivers.length) {
                response?.drivers.map((item: IDriver) => {
                    item.name = item.version;
                    return item;
                });
                setDriverVersionOptions(response.drivers);
            } else {
                setDriverVersionOptions([{ id: 0, name: 'Нет версий' }]);
                if (value === '0') return;
            }
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setDriver({ ...driver, [name]: value });
    };

    const commentChange = (event: Eventinfo, editor: ClassicEditor) => {
        const data = editor.getData();
        setDriver({ ...driver, comment: data });
    };

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errs = [];
        if (driver.name === '') errs.push('name');
        if (driver.version === '') errs.push('version');
        if (driver.vendor_id === '') errs.push('vendor_id');
        setErrors(errs);
        if (errs.length > 0) return false;

        const data = new FormData(e.target);
        const payload = Object.fromEntries(data.entries());
        delete payload.versions;

        postFetchData(`${driversLink}/`, payload)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    history.push({
                        pathname: '/admin/drivers'
                    });
                }
            })
            .catch(() => {
                setAlert({
                    type: 'alert-danger',
                    message: 'Не удалось создать запись'
                });
            });
    };

    const hasError = (key: number | string) => {
        return errors.indexOf(String(key as number)) !== -1;
    };

    console.log(driver, 'driver');

    return (
        <main className="content">
            <AllowLayout
                children={
                    <>
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-sm-block">
                                    <h3>Новый драйвер</h3>
                                </div>
                                <div className="col-auto ms-auto text-end mt-n1" />
                            </div>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className="col-md-6 col-xl-6">
                                <div className="card">
                                    <div className="card-body">
                                        <Alert type={alert.type} message={alert.message} />
                                        <Input
                                            title=""
                                            name="comment"
                                            type="hidden"
                                            value={driver.comment || ''}
                                            errorDiv={'d-none'}
                                            errorMsg={''}
                                        />
                                        <Input
                                            className={hasError('name') ? 'is-invalid' : ''}
                                            title="Название"
                                            name="name"
                                            type="text"
                                            value={driver.name || ''}
                                            handleChange={handleChange}
                                            errorDiv={hasError('name') ? 'text-danger' : 'd-none'}
                                            errorMsg={'Укажите название'}
                                            required={true}
                                        />
                                        <div className="row">
                                            <div className="col-lg-12 col-xl-6">
                                                <Select
                                                    title="Вендор"
                                                    name="vendor_id"
                                                    value={driver.vendor_id || ''}
                                                    options={driverOptions}
                                                    handleChange={fetchChange}
                                                    className={
                                                        hasError('vendor_id') ? 'is-invalid' : ''
                                                    }
                                                    required={true}
                                                />
                                            </div>
                                            <div className="col-lg-12 col-xl-6">
                                                <SelectAll
                                                    title="Текущие версии"
                                                    name="versions"
                                                    value={driver.versions || ''}
                                                    options={driverVersionOptions}
                                                    handleChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <Input
                                            className={'mb-0'}
                                            title="Новая версия"
                                            name="version"
                                            type="text"
                                            value={driver.version || ''}
                                            handleChange={handleChange}
                                            errorDiv={'d-none'}
                                            errorMsg={''}
                                            required={false}
                                        />
                                        <div className="text-muted mb-3">
                                            Например: 4.7.2.176
                                            <br />
                                            Например: v2 - 6 байт - 06.2021
                                        </div>
                                        <Input
                                            className={''}
                                            title="Контактное лицо (ФИО)"
                                            name="fio"
                                            type="text"
                                            value={driver.fio || ''}
                                            handleChange={handleChange}
                                            errorDiv={'d-none'}
                                            errorMsg={''}
                                            required={false}
                                        />
                                        <div className="mb-3">
                                            <label className="form-label">Комментарий</label>
                                            <CKEditor
                                                id="comment"
                                                name="comment"
                                                data=""
                                                editor={ClassicEditor}
                                                config={{
                                                    toolbar: [
                                                        'Bold',
                                                        'Italic',
                                                        '|',
                                                        'NumberedList',
                                                        'BulletedList',
                                                        '|',
                                                        'Link',
                                                        '|',
                                                        'Undo',
                                                        'Redo'
                                                    ]
                                                }}
                                                onReady={(editor) => {
                                                    editor.editing.view.change((writer) => {
                                                        writer.setStyle(
                                                            'height',
                                                            '100px',
                                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                            // @ts-ignore
                                                            editor.editing.view.document.getRoot()
                                                        );
                                                    });
                                                }}
                                                onChange={commentChange}
                                            />
                                        </div>
                                        * — обязательные поля
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Сохранить
                            </button>{' '}
                            <Link to="/admin/drivers" className="btn btn-outline-secondary">
                                Назад
                            </Link>
                        </form>
                    </>
                }
                permissions={Permissions.READ_SENSOR_DRIVER}
            />
        </main>
    );
};

export default DriverCreate;
