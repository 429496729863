import React from 'react';

interface IChild {
    props: { [key: string]: string };
}

interface ITabContent {
    children: IChild[];
    activeContent: number;
    className?: string;
}

const TabContent = ({ children, activeContent, className = 'tab-content' }: ITabContent) => {
    return (
        <div className={className}>
            {children.map((child, i: number) => {
                if (i !== parseInt(String(activeContent))) return undefined;
                return child.props.children;
            })}
        </div>
    );
};

export default TabContent;
