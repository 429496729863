import React from 'react';
import { IMultiSelect } from './type';

const Multiselect = ({
    title,
    name,
    value,
    className,
    options,
    handleChange,
    required
}: IMultiSelect) => {
    return (
        <div className="mb-3">
            <label className="form-label">
                {title}
                {required ? '*' : ''}
            </label>
            <select
                className={`form-select ${className}`}
                name={name}
                onChange={handleChange}
                size={options.length > 0 ? options.length : 1}
                multiple={true}
                value={value}>
                {options.map((option) => {
                    return (
                        <option key={option.id} value={option.id}>
                            - {option.name}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};

export default Multiselect;
