import React, { useState, useEffect, useContext, ChangeEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';
import { AbacProvider, AllowedTo } from 'react-abac';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '../../../components/Ui/components-form/Input';
import Select from '../../../components/Ui/components-form/Select';
import Alert from '../../../components/Ui/components-ui/Alert';
import { Permissions, User, Rules } from '../../../utils/rules';
import Multiselect from '../../../components/Ui/components-form/Multiselect';

import { changeInputValue, getFetchData, postFetchData } from '../../../api';
import { SubTicketContext } from '../../../Context/SubTicketContext';
import PageLayout from '../../../Layouts/PageLayout';
import { subtickets } from '../../../api/mockUrls';
import { uniqArray } from '../../../utils/utils';
import type { TAlert, ISubTicketsCreate, ITicket, TOids } from '../types';
import { Eventinfo, TId } from '../../types';

const SubTicketCreate = () => {
    const history = useHistory();
    const {
        troubleOptions,
        solutionOptions,
        employeeOptions,
        isLoadedSolution,
        isLoadedTrouble,
        isLoadedEmployee
    } = useContext(SubTicketContext);
    const { id } = useParams<TId>();
    const [isLoadedOid, setIsLoadedOid] = useState(true);
    const [role, setRole] = useState('');
    const [errors, setErrors] = useState<string[]>([]);
    const [alert, setAlert] = useState<TAlert>({
        type: 'd-none',
        message: ''
    });
    const [subTicket, setSubTicket] = useState<ISubTicketsCreate>({
        status: '0',
        oids: '',
        ticket_id: id,
        trouble_id: '0',
        solution_id: '0',
        employees: '',
        action: 'change_ticket'
    });
    const [open, setOpen] = useState(false);
    const [oidOptions, setOidOptions] = useState<TOids[]>([]);
    const [value, setValue] = useState<TOids[]>(oidOptions);
    const [loading, setLoading] = useState(false);
    const [ticket, setTicket] = useState<ITicket>({
        address: '',
        created_at: '',
        firstname: '',
        id: 0,
        lastname: '',
        status: '',
        status_id: 0,
        uid: '',
        updated_at: '',
        user_id: 0,
        users: ''
    });

    useEffect(() => {
        if (!open) {
            setOidOptions([]);
        }
    }, [open]);

    useEffect(() => {
        const storage = localStorage.getItem('datanorm.user');
        setRole(storage ? storage.toUpperCase() : 'USER');

        getFetchData(`/api/tickets/${id}`).then((response) => setTicket(response));
        setIsLoadedOid((isLoadedOid) => isLoadedOid);
    }, [id]);

    useEffect(() => {
        const data = Array.from(new Set(value.map((obj) => obj.oid)));
        setSubTicket({ ...subTicket, oids: data });
    }, [value]);

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setSubTicket({ ...subTicket, [name]: value });
    };

    const multiselectChange = ({ target: { options } }: React.ChangeEvent<HTMLSelectElement>) => {
        const value = [...options].filter((o) => o.selected).map((o) => o.value);

        setSubTicket({ ...subTicket, employees: value.join(', ') });
    };

    const commentChange = (event: Eventinfo, editor: ClassicEditor) => {
        const data = editor.getData();
        setSubTicket({ ...subTicket, comment: data });
    };

    const changeInput = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (event.target.value === '0' || event.target.value.length <= 2) {
            setOidOptions([]);
            return false;
        }

        setLoading(true);

        changeInputValue(event)
            .then((response) => setOidOptions(response))
            .then(() => setLoading(false));
    };

    const onChangeInput = useAsyncDebounce((event) => {
        changeInput(event);
    }, 1000);

    const autocompletePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
        event.preventDefault();
        const past = event.clipboardData?.getData('text').replace(/\s{2,}/g, '');

        if (past?.trim() !== '') {
            setLoading(true);
            changeInputValue(event)
                .then(
                    (response) =>
                        Array.isArray(response) &&
                        setValue((prev) => uniqArray([...prev, ...response]))
                )
                .then(() => setLoading(false));
        }
    };

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errs: string[] = [];
        if (subTicket.oids === '') errs.push('oids');
        if (subTicket.employees === '') errs.push('employee_ids');
        if (subTicket.solution_id === '0') errs.push('solution_id');
        if (subTicket.trouble_id === '0') errs.push('trouble_id');

        setErrors(errs);
        if (errs.length > 0) return false;

        const data = new FormData(e.target);
        const payload = Object.fromEntries(data.entries());
        const postData = { ...payload, ticket_id: id };

        postFetchData(subtickets(id), postData)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    history.goBack();
                }
            })
            .catch((e) => {
                setAlert({
                    type: 'alert-danger',
                    message: 'Не удалось создать запись'
                });
                console.error(e.message, 'message');
            });
    };

    const hasError = (key: number | string) => {
        return errors.indexOf(String(key as number)) !== -1;
    };

    // Preloader
    if (!isLoadedOid) {
        const elem = document.getElementById('oids');
        if (elem) elem.classList.remove('d-none');
    } else if (isLoadedOid) {
        const elem = document.getElementById('oids');
        if (elem) elem.classList.add('d-none');
    }

    return (
        <PageLayout title={'Создание заявки'} id={id} breadcrumbs>
            <form onSubmit={onSubmit}>
                <div className="col-md-6 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <Alert type={alert.type} message={alert.message} />
                            <Input name="comment" type="hidden" value={subTicket.comment || ''} />
                            <Input name="oids" type="hidden" value={subTicket.oids || ''} />
                            <Input
                                name="employees"
                                type="hidden"
                                value={subTicket.employees || ''}
                            />
                            <Input
                                name="ticket_id"
                                type="hidden"
                                value={subTicket.ticket_id || ''}
                            />
                            <Input name="action" type="hidden" value={subTicket.action || ''} />
                            <Input name="users" type="hidden" value={ticket.users || ''} />
                            <div className="mb-3" style={{ position: 'relative' }}>
                                <label className="form-label">Object ID*</label>
                                <Autocomplete
                                    multiple
                                    value={value}
                                    id="tags-outlined"
                                    open={open && oidOptions?.length > 0}
                                    onOpen={() => setOpen(true)}
                                    onClose={() => setOpen(false)}
                                    loading={loading}
                                    options={oidOptions}
                                    getOptionLabel={(option) => option.oid}
                                    filterOptions={(options) => options}
                                    onPaste={(event) => autocompletePaste(event)}
                                    onChange={(event, newValue) => setValue(uniqArray(newValue))}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            onChange={onChangeInput}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? (
                                                            <CircularProgress size={16} />
                                                        ) : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                                <span
                                    className="spinner-border text-primary d-none"
                                    id="oids"
                                    style={{
                                        position: 'absolute',
                                        right: '33px',
                                        top: '38px',
                                        width: '1rem',
                                        height: '1rem'
                                    }}
                                />
                            </div>
                            <Select
                                title="Проблема"
                                name="trouble_id"
                                value={subTicket.trouble_id || ''}
                                options={troubleOptions}
                                handleChange={handleChange}
                                className={hasError('trouble_id') ? 'is-invalid' : ''}
                                required={true}
                                isLoaded={isLoadedTrouble}
                            />
                            <Select
                                title="Решение"
                                name="solution_id"
                                value={subTicket.solution_id || ''}
                                options={solutionOptions}
                                handleChange={handleChange}
                                className={hasError('solution_id') ? 'is-invalid' : ''}
                                required={true}
                                isLoaded={isLoadedSolution}
                            />
                            <Multiselect
                                title="Ремонтники"
                                name="employee_ids"
                                value={[subTicket][0].employees.split(', ') ?? []}
                                options={employeeOptions}
                                handleChange={multiselectChange}
                                className={hasError('employee_ids') ? 'is-invalid' : ''}
                                required={true}
                                isLoaded={isLoadedEmployee}
                            />
                            <div className="mb-3">
                                <label className="form-label">Комментарий</label>
                                <CKEditor
                                    id="comment"
                                    name="comment"
                                    data=""
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: [
                                            'Bold',
                                            'Italic',
                                            '|',
                                            'NumberedList',
                                            'BulletedList',
                                            '|',
                                            'Link',
                                            '|',
                                            'Undo',
                                            'Redo'
                                        ]
                                    }}
                                    onReady={(editor) => {
                                        editor.editing.view.change((writer) => {
                                            writer.setStyle(
                                                'height',
                                                '100px',
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                editor.editing.view.document.getRoot()
                                            );
                                        });
                                    }}
                                    onChange={commentChange}
                                />
                            </div>
                            * — обязательные поля
                        </div>
                    </div>
                </div>
                <AbacProvider user={User} roles={[role]} rules={Rules}>
                    <AllowedTo perform={Permissions.WRITE_SUPPORT_TICKET}>
                        <button type="submit" className="btn btn-primary">
                            Сохранить
                        </button>{' '}
                        <button
                            onClick={() => history.goBack()}
                            className="btn btn-outline-secondary">
                            Назад
                        </button>
                    </AllowedTo>
                </AbacProvider>
            </form>
        </PageLayout>
    );
};

export default SubTicketCreate;
