import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { ColumnFilter } from '../../../components/ColumnFilter';
import { IRow } from '../../types';
import { getFormatDate } from '../../../utils/utils';

export const COLUMNS = [
    {
        Header: 'Id',
        accessor: 'id',
        Filter: ColumnFilter,
        disableFilters: true
    },
    {
        Header: 'Name',
        accessor: 'name',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ row }: IRow) => {
            return (
                <Link to={`/admin/sensors/groups/${row.original.id}/edit`}>
                    {row.original.name}
                </Link>
            );
        }
    },
    {
        Header: 'Дата создания',
        accessor: 'created_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        }
    },
    {
        Header: 'Дата изменения',
        accessor: 'updated_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        }
    }
];
