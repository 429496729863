import React from 'react';

interface IBattery {
    value: number | number[];
    strokeWidth?: string | number;
}

const Battery = ({ value }: IBattery) => {
    const convert = (value: number | number[]) => {
        const level: { [key: number]: number[] } = {
            1: [71, 100],
            2: [51, 70],
            3: [31, 50],
            4: [11, 30],
            5: [0, 10]
        };

        for (const v in level) {
            if (value <= level[v][1] && value >= level[v][0]) return v;
        }

        return '';
    };

    const b: { [key: string]: string } = {};

    b[1] = 'fa-battery-full text-success';
    b[2] = 'fa-battery-three-quarters text-success';
    b[3] = 'fa-battery-half text-warning';
    b[4] = 'fa-battery-quarter text-danger';
    b[5] = 'fa-battery-empty text-danger';

    return (
        <>
            <i className={'align-middle fa ' + b[convert(value)]} />
            {value}
        </>
    );
};

export default Battery;
