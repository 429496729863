import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { AbacProvider, AllowedTo } from 'react-abac';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Input from '../../components/Ui/components-form/Input';
import Select from '../../components/Ui/components-form/Select';
import Alert from '../../components/Ui/components-ui/Alert';
import LightTable from '../../components/Table/LightTable/LightTable';
import Tabs from '../../components/Ui/components-ui/Tabs';
import Tab from '../../components/Ui/components-ui/Tab';
import TabContent from '../../components/Ui/components-ui/TabContent';
import SensorHistory from './SensorsHistory/SensorHistory';
import { Permissions, User, Rules } from '../../utils/rules';
import { DATA } from '../clients/ClientTree';
import { COLUMNS } from './SensorEditColumns';
import { getFetchData, updateFetchData } from '../../api';
import type { IDriver, ISensor, TTab } from './type';
import type { IPageOptions, Eventinfo, TId } from '../types';
import { vendorsLink } from '../../api/mockUrls';

interface IUrl {
    url: string;
}

interface ITabs {
    id: number;
    name: string;
}

const tabArray: ITabs[] = [
    { id: 0, name: 'Подробно' },
    { id: 1, name: 'Метрики' },
    { id: 2, name: 'RawData' },
    { id: 3, name: 'История' }
];

const sensorDefault: ISensor = {
    name: '',
    deveui: '',
    comment: ''
};

const SensorEdit = ({ url }: IUrl) => {
    const [role, setRole] = useState('');

    const location = useLocation();
    const initialTab = location.hash.replace('#tab-', '');
    const [activeTab, setActiveTab] = useState<string | null>(initialTab);
    const columns = useMemo(() => COLUMNS, []);
    const history = useHistory();
    const { id } = useParams<TId>();
    const [isLoaded, setIsLoaded] = useState(true);
    const [sensor, setSensor] = useState(sensorDefault);
    const [oid2, setOid2] = useState(''); // для сравнения с новым значением и повышения rid на backend
    const [sensorUseTypeOptions, setSensorUseTypeOptions] = useState([
        {
            id: 'bike',
            name: 'Велосипед'
        },
        {
            id: 'human',
            name: 'Человек'
        }
    ]);
    const [clientTree, setClientTree] = useState<IPageOptions[]>([]);
    const [driverOptions, setDriverOptions] = useState([]);
    const [driverVersionOptions, setDriverVersionOptions] = useState<IPageOptions[]>([]);
    const clientOptions = useMemo(() => DATA, []);
    const [errors, setErrors] = useState<string[]>([]);
    const [alert, setAlert] = useState({
        type: 'd-none',
        message: ''
    });
    const [data, setData] = useState('');
    const hashHistory = location.hash !== '#tab-3';

    useEffect(() => {
        const storage = localStorage.getItem('datanorm.user');
        setRole(storage ? storage.toUpperCase() : 'USER');

        setSensorUseTypeOptions((sensorUsetypeOptions) => sensorUsetypeOptions);

        setIsLoaded(false);
        getFetchData(`${url}${id}`)
            .then((response) => {
                setSensor(response);
                setData(response?.comment ?? '');
                setOid2(response?.oid);
                fetchDriverVersions(response?.vendor_id).then((r) => r);
            })
            .then(() => setIsLoaded(true));

        getFetchData(`${vendorsLink}/list`)
            .then((response) => {
                setDriverOptions(response);
            })
            .then(() => setIsLoaded(true));

        getFetchData(`/api/clients/list`)
            .then((response) => {
                if (!response?.length) {
                    setClientTree([{ id: 0, name: 'Не выбран' }]);
                    return;
                }
                setClientTree(clientOptions(response));
            })
            .then(() => setIsLoaded(true));
    }, [clientOptions, id, url]);

    const fetchDriverVersions = async (id: number | string) => {
        getFetchData(`/api/vendors/${id}/drivers`).then((response) => {
            if (response?.drivers.length) {
                response.drivers.map((item: IDriver) => {
                    item.name = item.version;
                    return item;
                });
                setDriverVersionOptions(response.drivers);
            } else {
                setDriverVersionOptions([{ id: 0, name: 'Нет версий' }]);
                if (id === '0') return;
            }
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setSensor({ ...sensor, [name]: value });
    };

    const fetchChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setSensor({ ...sensor, [name]: value });
        fetchDriverVersions(value).then((r) => r);
    };

    const commentChange = (event: Eventinfo, editor: ClassicEditor) => {
        const data = editor.getData();
        setSensor({ ...sensor, comment: data });
    };

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errs = [];
        if (sensor.name === '') errs.push('name');
        setErrors(errs);
        if (errs.length > 0) return false;

        const data = new FormData(e.target);
        const payload = Object.fromEntries(data.entries());

        updateFetchData(`/api/sensors/${id}/`, payload)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    history.push({
                        pathname: '/admin/sensors/'
                    });
                }
            })
            .catch(() => {
                setAlert({
                    type: 'alert-danger',
                    message: 'Не удалось создать запись'
                });
            });
    };

    const onClickTab = useCallback(
        async (tab: TTab) => {
            setIsLoaded(false);
            setActiveTab(tab.target);
            if (tab.label === 'История') return;
            getFetchData(`${url}${id}`)
                .then((response) => {
                    setSensor(response);
                })
                .then(() => setIsLoaded(true));
        },
        [id, url]
    );

    const hasError = (key: number | string) => {
        return errors.indexOf(String(key as number)) !== -1;
    };

    // Preloader
    if (!isLoaded) {
        const elem = document.getElementById('preloader');
        if (elem) elem.classList.remove('d-none');
    } else if (isLoaded) {
        const elem = document.getElementById('preloader');
        if (elem) elem.classList.add('d-none');
    }

    return (
        <main className="content">
            <div className="container-fluid p-0">
                <div className="row mb-2 mb-xl-3">
                    <div className="col-auto d-sm-block">
                        <h3>Устройство</h3>
                    </div>
                    <div className="col-auto ms-auto text-end mt-n1" />
                </div>
            </div>

            <div className="row">
                <div
                    className={`col-md-${hashHistory ? '7' : '12'} col-xl-${
                        hashHistory ? '7' : '12'
                    }`}>
                    <div className="tab">
                        <Tabs>
                            {tabArray.map((tab) => {
                                return (
                                    <AbacProvider
                                        key={tab.id}
                                        user={User}
                                        roles={[role]}
                                        rules={Rules}>
                                        <AllowedTo
                                            perform={
                                                tab.name === 'Метрики' || tab.name === 'RawData'
                                                    ? Permissions.READ_CLIENT_TENANT
                                                    : Permissions.READ_SENSOR
                                            }>
                                            <Tab
                                                onClickTab={onClickTab}
                                                label={tab.name}
                                                tab={tab.id}
                                                param={location.search}
                                                activeTab={Number(activeTab)}
                                            />
                                        </AllowedTo>
                                    </AbacProvider>
                                );
                            })}
                        </Tabs>
                        <TabContent
                            className={
                                hashHistory ? 'tab-content' : 'tab-content tab-content__history'
                            }
                            activeContent={Number(activeTab)}>
                            <div className="tab-pane" id="tab-1" role="tabpanel">
                                <form onSubmit={onSubmit}>
                                    <Alert type={alert.type} message={alert.message} />
                                    <Input
                                        title=""
                                        name="comment"
                                        type="hidden"
                                        value={sensor?.comment ?? ''}
                                        errorDiv={'d-none'}
                                        errorMsg={''}
                                    />
                                    <Input
                                        className={hasError('name') ? 'is-invalid' : ''}
                                        title="Название"
                                        name="name"
                                        type="text"
                                        value={sensor?.name ?? ''}
                                        handleChange={handleChange}
                                        errorDiv={hasError('name') ? 'text-danger' : 'd-none'}
                                        errorMsg={'Поле не может быть пустым'}
                                        required={true}
                                    />
                                    <Input
                                        className={hasError('deveui') ? 'is-invalid' : ''}
                                        title="DevEUI"
                                        name="deveui"
                                        type="text"
                                        value={sensor?.deveui ?? ''}
                                        handleChange={handleChange}
                                        errorDiv={hasError('deveui') ? 'text-danger' : 'd-none'}
                                        errorMsg={'Поле не может быть пустым'}
                                        disabled={true}
                                    />

                                    <div className="row">
                                        <div className="col-lg-12 col-xl-7">
                                            <Select
                                                title="Драйвер"
                                                name="vendor_id"
                                                value={sensor?.vendor_id ?? ''}
                                                options={driverOptions}
                                                handleChange={fetchChange}
                                            />
                                        </div>
                                        <div className="col-lg-12 col-xl-5">
                                            <Select
                                                title="Версия драйвера"
                                                name="driver_id"
                                                value={sensor?.driver_id ?? ''}
                                                options={driverVersionOptions}
                                                handleChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <Select
                                        title="Клиент"
                                        name="client_id"
                                        value={sensor?.client_id ?? ''}
                                        options={clientTree}
                                        handleChange={handleChange}
                                    />

                                    <Select
                                        title="Применение"
                                        name="oid_type"
                                        value={sensor?.oid_type ?? ''}
                                        options={sensorUseTypeOptions}
                                        handleChange={handleChange}
                                    />
                                    <Input
                                        className={hasError('oid') ? 'is-invalid' : ''}
                                        title="ObjectID"
                                        name="oid"
                                        type="text"
                                        value={sensor?.oid ?? ''}
                                        handleChange={handleChange}
                                    />
                                    <Input
                                        title=""
                                        name="oid2"
                                        type="hidden"
                                        value={oid2 ?? ''}
                                        errorDiv={'d-none'}
                                        errorMsg={''}
                                    />
                                    <Input
                                        className={hasError('did') ? 'is-invalid' : ''}
                                        title="DeviceID"
                                        name="did"
                                        type="text"
                                        value={sensor?.did ?? ''}
                                        handleChange={handleChange}
                                    />

                                    <div className="mb-3">
                                        <label className="form-label">Комментарий</label>
                                        <CKEditor
                                            id="comment"
                                            name="comment"
                                            data={data}
                                            editor={ClassicEditor}
                                            config={{
                                                toolbar: [
                                                    'Bold',
                                                    'Italic',
                                                    '|',
                                                    'NumberedList',
                                                    'BulletedList',
                                                    '|',
                                                    'Link',
                                                    '|',
                                                    'Undo',
                                                    'Redo'
                                                ]
                                            }}
                                            onReady={(editor) => {
                                                // You can redux the "editor" and use when it is needed.
                                                // console.log("Editor is ready to use!", editor);
                                                editor.editing.view.change((writer) => {
                                                    writer.setStyle(
                                                        'height',
                                                        '100px',
                                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                        // @ts-ignore
                                                        editor.editing.view.document.getRoot()
                                                    );
                                                });

                                                // editor.data.set(data1);
                                            }}
                                            onChange={commentChange}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <AbacProvider user={User} roles={[role]} rules={Rules}>
                                            <AllowedTo perform={Permissions.WRITE_SENSOR}>
                                                <button type="submit" className="btn btn-primary">
                                                    Сохранить
                                                </button>{' '}
                                            </AllowedTo>
                                        </AbacProvider>
                                        <Link
                                            to="/admin/sensors"
                                            className="btn btn-outline-secondary">
                                            Назад
                                        </Link>
                                    </div>
                                </form>
                            </div>
                            <div className="tab-pane" id="tab-2" role="tabpanel">
                                <pre>
                                    {sensor?.metrics
                                        ? JSON.stringify(JSON.parse(sensor.metrics), null, 4)
                                        : 'Нет данных'}
                                </pre>
                            </div>
                            <div className="tab-pane" id="tab-3" role="tabpanel">
                                <pre>
                                    {sensor?.rawdata
                                        ? JSON.stringify(JSON.parse(sensor.rawdata), null, 4)
                                        : 'Нет данных'}
                                </pre>
                            </div>
                            <div className="tab-pane" id="tab-4" role="tabpanel">
                                {sensor.deveui ? <SensorHistory deveui={sensor.deveui} /> : ''}
                            </div>
                        </TabContent>
                    </div>
                </div>
                {hashHistory && (
                    <div className="col-md-5 col-xl-5">
                        <div style={{ marginTop: '44px' }}>
                            <LightTable
                                url={window.env.REACT_APP_API_URL + '/api/sensors'}
                                columns={columns}
                            />
                        </div>
                    </div>
                )}
            </div>
        </main>
    );
};

export default SensorEdit;
