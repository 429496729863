import React, { useEffect, useState } from 'react';
import { fetchUsers } from '../../../api';
import { Column } from 'react-table';
import { IOnChangeFilter, IUserOptions } from '../types';
import { support } from '../../../api/mockUrls';

interface IColumnFilterUsersLike {
    column: Column;
    onChangeFilter: (arg: IOnChangeFilter) => void;
}

export const ColumnFilterUsersLike = ({ column, onChangeFilter }: IColumnFilterUsersLike) => {
    const [options, setOptions] = useState<IUserOptions[]>([]);

    useEffect(() => {
        fetchUsers(support).then((response) => setOptions(response));
    }, []);

    return (
        <div>
            <select
                className="form-select"
                onChange={(e) => {
                    onChangeFilter({ key: column.id, value: e.target.value });
                }}>
                <option value="0">Не выбран</option>
                <option disabled value="0">
                    ---
                </option>
                {options.map((option) => {
                    return (
                        <option key={option.id} value={option.last_name + ' ' + option.name}>
                            {option.last_name + ' ' + option.name}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};
