import React, { useState } from 'react';
import { Edit2 } from 'react-feather';
import { Link, useParams } from 'react-router-dom';
import { Row } from 'react-table';
import Accordion from '../../Ui/Accordion/Accordion';

type TId = {
    id?: string;
    data?: string;
    deveui?: string;
};

interface ICell extends Row {
    original: TId;
}

interface IRowTable {
    row: ICell;
    getRowProps: () => void;
}

const RowTable = ({ row, getRowProps }: IRowTable) => {
    const { id } = useParams<TId>();
    const [active, setActive] = useState(false);

    const handleClickRow = () => {
        setActive(!active);
    };

    return (
        <>
            <tr style={{ cursor: 'pointer' }} onClick={handleClickRow} {...getRowProps}>
                {row.cells.map((cell, index: number) => {
                    return (
                        <React.Fragment key={index}>
                            <td {...cell.getCellProps()}>
                                {cell.column.id === 'Edit' && (
                                    <Link
                                        to={`/admin/tickets/${id}/subtickets/${row.original.id}/edit`}>
                                        <Edit2 size={18} />
                                    </Link>
                                )}
                                {cell.render('Cell')}
                            </td>
                        </React.Fragment>
                    );
                })}
            </tr>
            {row.original.data ? <Accordion active={active} data={row.original.data} /> : <tr />}
        </>
    );
};

export default RowTable;
