import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Column, Row, SortingRule } from 'react-table';
import { confirmAlert } from 'react-confirm-alert';
import fileDownload from 'js-file-download';
import { deleteFetchData, deleteSensorsData } from '../api';
import { loadingData, setParamsForSensorsGroup } from '../utils/utils';
import fetchMethod from '../utils/fetchMethod';
import auth from '../components/auth';
import { ISensorGroup } from '../pages/sensors/type';
import { IColumn, IField, ISort } from '../components/Table/type';
import ReconnectingWebSocket from 'reconnecting-websocket';

type TId = {
    id?: string;
};

const getFilterParams = (
    filters: [],
    filtersLike: any,
    sort?: SortingRule<ISort>,
    page?: number,
    limit?: number,
    activeTab?: string | null,
    startDate?: string,
    endDate?: string
) => {
    let filterString = '';
    for (const key in filters) {
        if (filtersLike[key]) {
            filterString += `&${key}_like=${filters[key]}`;
        } else {
            filterString += `&${key}=${filters[key]}`;
        }
    }

    let params = '';

    if (activeTab) {
        params = `?_page=${page}&_limit=${limit}${filterString}&group_id=${activeTab}`;
    } else {
        params = `?_page=${page}&_limit=${limit}${filterString}`;
    }

    if (endDate) {
        params = `?limit=${limit}&page=${page}${filterString}&startDate=${startDate}&endDate=${endDate}`;
    }

    params += `&page=${page}&limit=${limit}${filterString}`;

    if (sort) {
        params += `&_sort=${sort.id}&_order=${sort.desc ? 'desc' : 'asc'}`;
        params += `&sort=${sort.id}&order=${sort.desc ? 'desc' : 'asc'}`;
    }

    return params;
};

export const useFiltersForTable = (
    url: string,
    activeTab?: string | null,
    columns?: Column[],
    csvFilename?: string,
    isWs?: ReconnectingWebSocket,
    dltIdArray?: boolean,
    dltUrl?: string,
    startDate?: string,
    endDate?: string
) => {
    const [list, setList] = useState<ISensorGroup[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState<null | string>(null);
    const [filtersLike, setFiltersLike] = useState<[]>([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [sort, setSort] = useState<SortingRule<ISort>>();
    const [filters, setFilters] = useState<[]>([]);

    const setAllSetters = (
        pageValue: React.SetStateAction<number>,
        pageSizeValue: number,
        totalValue: number,
        sortValue: React.SetStateAction<SortingRule<ISort> | undefined>,
        filtersValue: []
    ) => {
        const totalPages = Math.ceil(totalValue / Number(pageSizeValue));
        setTotalPages(totalPages);
        setTotal(totalValue);
        setPage(pageValue);
        setPageSize(Number(pageSizeValue));
        setSort(sortValue);
        setFilters(filtersValue);
    };

    const fetchList = useCallback(
        (page: number, limit: number, sort: SortingRule<ISort> | undefined, filters: []) => {
            const params = getFilterParams(
                filters,
                filtersLike,
                sort,
                page,
                limit,
                activeTab,
                startDate,
                endDate
            );

            setIsLoaded(false);

            return fetchMethod({
                url: url + params,
                method: 'GET'
            })
                .then((response) => {
                    setIsLoaded(true);
                    setError(null);
                    const count = response.headers.get('X-Total-Count');
                    return response.json().then((response: ISensorGroup[]) => {
                        return {
                            total: count,
                            data: response
                        };
                    });
                })
                .catch((error) => {
                    setIsLoaded(true);
                    if (error.status === 401) {
                        auth.logout();
                    } else {
                        setError('Internal server error');
                        return {
                            total: 0,
                            data: []
                        };
                    }
                });
        },
        [filtersLike, activeTab, endDate, url]
    );

    const onSort = async (sort: SortingRule<ISort>) => {
        if (sort === undefined) return;
        const listFromServer = await fetchList(1, pageSize, sort, filters);
        setList(listFromServer?.data);
        setAllSetters(1, pageSize, listFromServer?.total, sort, filters);
    };

    const onPageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        fetchList(value, pageSize, sort, filters).then((response) => {
            setList(response.data);
            setAllSetters(value, pageSize, response.total, sort, filters);
        });
    };

    const onPageSizeChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const limit = event.target.value;

        fetchList(1, Number(limit), sort, filters).then((response) => {
            setList(response.data);
            setAllSetters(1, Number(limit), response.total, sort, filters);
        });
    };

    const onChangeFilter = async (field: IField) => {
        filters[field.key] = field.value;
        if (!filters[field.key]) delete filters[field.key];
        fetchList(1, pageSize, sort, filters).then((response) => {
            setList(response?.data);
            setAllSetters(1, pageSize, response.total, sort, filters);
        });
    };

    const deleteList = async (id: (string | undefined)[] | string) => {
        const params = dltIdArray ? setParamsForSensorsGroup(id) : id;
        const urlList = dltUrl ? dltUrl : url;

        const res = activeTab
            ? await deleteSensorsData(`${activeTab}/sensors${params}`)
            : await deleteFetchData(`${urlList}/${params}`);

        if (res.status === 200) {
            const listFromServer = await fetchList(1, pageSize, sort, filters);
            setList(listFromServer?.data);
            setAllSetters(1, pageSize, listFromServer?.total, sort, filters);
        } else if (res.status === 401) {
            auth.logout();
        } else {
            console.log('AP');
        }
    };

    const onDelete = async (rows: Array<Row<TId>>) => {
        const rowsOriginalId = rows.map((row) => row.original.id);

        confirmAlert({
            title: 'Удаление',
            message: 'Вы действительно желаете удалить?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dltIdArray
                            ? deleteList(rowsOriginalId)
                            : rows.map((row) => {
                                  deleteList(row.original.id ?? '');
                              });
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log('No')
                }
            ]
        });
    };

    const idBtnExport = useRef<HTMLDivElement>(null);
    let BtnExport = null !== idBtnExport.current && idBtnExport.current.className;

    const onExport = async () => {
        const page = 1;
        const limit = 50;

        // собираем фильтры в строку
        let filterString = '';
        for (const key in filters) {
            if (filtersLike[key]) {
                filterString += `&${key}_like=${filters[key]}`;
            } else {
                filterString += `&${key}=${filters[key]}`;
            }
        }
        // дефолтный урл
        let params = `?_page=${page}&_limit=${limit}${filterString}`;
        params += `&page=${page}&limit=${limit}${filterString}`;

        if (sort !== undefined) {
            params += `&_sort=${sort.id}&_order=${sort.desc ? 'desc' : 'asc'}`;
            params += `&sort=${sort.id}&order=${sort.desc ? 'desc' : 'asc'}`;
        }

        BtnExport = 'inherit';

        const res = await fetch(url + '/export' + params, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.jwt()
            }
        });

        if (res.status === 200) {
            setError(null);
            const data = await res.blob();
            const date = new Date();
            const filename =
                csvFilename +
                '-' +
                date.toLocaleDateString('sv-SE').replace(/-/g, '') +
                '-' +
                date.getHours() +
                date.getMinutes() +
                date.getSeconds() +
                '.csv';
            fileDownload(data, filename);
        } else if (res.status === 401) {
            auth.logout();
        } else {
            setError('Invalid response code: ' + res.status);
        }

        // eslint-disable-next-line
        BtnExport = 'd-none inline';
    };

    useEffect(() => {
        const filterLikeArr: any = [];
        columns?.map((column: IColumn<any>) => {
            if (column.FilterLike) return (filterLikeArr[column.accessor] = true);
            return column;
        });

        setFiltersLike(filterLikeArr);

        url !== '' &&
            fetchList(1, pageSize, sort, filters).then((response) => {
                setList(response?.data);
                setAllSetters(1, pageSize, response.total, sort, filters);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, columns, activeTab, endDate, startDate, url]);

    useEffect(() => {
        const getMessageMonitor = (msg: MessageEvent) => {
            const data = JSON.parse(msg.data);
            if (data.action === 'sensors-refresh-list') {
                fetchList(page, pageSize, sort, filters).then((response) => {
                    setList(response?.data);
                    setAllSetters(page, pageSize, response.total, sort, filters);
                });
            }
        };

        isWs?.addEventListener('message', getMessageMonitor);
        return () => isWs?.removeEventListener('message', getMessageMonitor);
    }, [fetchList, filters, page, pageSize, sort, isWs]);

    useEffect(() => {
        loadingData(isLoaded);
    }, [isLoaded]);

    return useMemo(
        () => ({
            list,
            isLoaded,
            error,
            total,
            page,
            pageSize,
            totalPages,
            idBtnExport,
            onPageChange,
            onPageSizeChange,
            onChangeFilter,
            onSort,
            onDelete,
            onExport
        }),
        [
            error,
            isLoaded,
            list,
            onDelete,
            onExport,
            onPageChange,
            onPageSizeChange,
            onSort,
            page,
            pageSize,
            total,
            totalPages
        ]
    );
};
