import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import type { ISelect } from './type';

const Select = ({
    title,
    name,
    value,
    className,
    options,
    handleChange,
    required,
    isLoaded
}: ISelect) => {
    if (!isLoaded) {
        const elem = document.getElementById(name);
        if (elem) elem.classList.remove('d-none');
    } else if (isLoaded) {
        const elem = document.getElementById(name);
        if (elem) elem.classList.add('d-none');
    }

    return (
        <div className="mb-3 select-wrapper" style={{ position: 'relative' }}>
            <label className="form-label">
                {title}
                {required ? '*' : ''}
            </label>
            <select
                name={name}
                value={value}
                onChange={handleChange}
                className={`form-select ${className}`}>
                {options?.length > 0 && options[0].id !== 0 ? (
                    <>
                        <option value="0">Не выбран</option>
                        <option disabled value="0">
                            ---
                        </option>
                    </>
                ) : (
                    ''
                )}
                {options?.map((option) => {
                    return (
                        <option key={option.id} value={option.id}>
                            {option.last_name ? option.last_name + ' ' + option.name : option.name}
                        </option>
                    );
                })}
            </select>
            <CircularProgress
                size={16}
                id={name}
                className="d-none"
                style={{ position: 'absolute', right: '33px', top: '38px' }}
            />
            {/* <span className="spinner-border text-primary d-none" id={name} style={{position:'absolute', right:'33px', top: '38px', width: '1rem', height: '1rem'}}></span> */}
        </div>
    );
};

Select.defaultProps = {
    className: '',
    isLoaded: true
};

export default Select;
