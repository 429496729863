import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Input from '../../components/Ui/components-form/Input';
import Alert from '../../components/Ui/components-ui/Alert';
import { Permissions, User, Rules } from '../../utils/rules';
import { AbacProvider, AllowedTo } from 'react-abac';
import { Eventinfo } from '../types';
import { postFetchData } from '../../api';
import { SubTicketContext } from '../../Context/SubTicketContext';

const SolutionCreate = () => {
    const [role, setRole] = useState('');
    const { solutionsUpdate } = useContext(SubTicketContext);

    useEffect(() => {
        const storage = localStorage.getItem('datanorm.user');
        setRole(storage ? storage.toUpperCase() : 'USER');
    }, []);

    const history = useHistory();
    const [solution, setSolution] = useState({
        name: '',
        comment: ''
    });
    const [errors, setErrors] = useState<string[]>([]);
    const [alert, setAlert] = useState({
        type: 'd-none',
        message: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setSolution({ ...solution, [name]: value });
    };

    const commentChange = (event: Eventinfo, editor: ClassicEditor) => {
        const data = editor.getData();
        setSolution({ ...solution, comment: data });
    };

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errs = [];
        if (solution.name === '') errs.push('name');

        setErrors(errs);
        if (errs.length > 0) return false;

        const data = new FormData(e.target);
        const payload = Object.fromEntries(data.entries());

        postFetchData('/api/solutions/', payload)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    solutionsUpdate();
                    history.push({
                        pathname: '/admin/solutions'
                    });
                }
            })
            .catch(() => {
                setAlert({
                    type: 'alert-danger',
                    message: 'Не удалось создать запись'
                });
            });
    };

    const hasError = (key: number | string) => {
        return errors.indexOf(String(key as number)) !== -1;
    };

    return (
        <main className="content">
            <div className="container-fluid p-0">
                <div className="row mb-2 mb-xl-3">
                    <div className="col-auto d-sm-block">
                        <h3>Новое решение</h3>
                    </div>
                    <div className="col-auto ms-auto text-end mt-n1" />
                </div>
            </div>
            <form onSubmit={onSubmit}>
                <div className="col-md-6 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <Alert type={alert.type} message={alert.message} />
                            <Input
                                title=""
                                name="comment"
                                type="hidden"
                                value={solution.comment || ''}
                                errorDiv={'d-none'}
                                errorMsg={''}
                            />
                            <Input
                                className={hasError('name') ? 'is-invalid' : ''}
                                title="Название"
                                name="name"
                                type="text"
                                value={solution.name || ''}
                                handleChange={handleChange}
                                errorDiv={hasError('name') ? 'text-danger' : 'd-none'}
                                errorMsg={'Поле не может быть пустым'}
                                required={true}
                            />
                            <div className="mb-3">
                                <label className="form-label">Комментарий</label>
                                <CKEditor
                                    id="comment"
                                    name="comment"
                                    data=""
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: [
                                            'Bold',
                                            'Italic',
                                            '|',
                                            'NumberedList',
                                            'BulletedList',
                                            '|',
                                            'Link',
                                            '|',
                                            'Undo',
                                            'Redo'
                                        ]
                                    }}
                                    onReady={(editor) => {
                                        editor.editing.view.change((writer) => {
                                            writer.setStyle(
                                                'height',
                                                '100px',
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                editor.editing.view.document.getRoot()
                                            );
                                        });
                                    }}
                                    onChange={commentChange}
                                />
                            </div>
                            * — обязательные поля
                        </div>
                    </div>
                </div>

                <AbacProvider user={User} roles={[role]} rules={Rules}>
                    <AllowedTo perform={Permissions.WRITE_SUPPORT_SOLUTION}>
                        <button type="submit" className="btn btn-primary">
                            Сохранить
                        </button>{' '}
                    </AllowedTo>
                </AbacProvider>

                <Link to="/admin/solutions" className="btn btn-outline-secondary">
                    Назад
                </Link>
            </form>
        </main>
    );
};

export default SolutionCreate;
