import React from 'react';
import { IColumnFilterBattery } from './type';

export const ColumnFilterBattery = ({ column, onChangeFilter }: IColumnFilterBattery) => {
    return (
        <div>
            <select
                className="form-select"
                onChange={(e) => {
                    onChangeFilter({ key: column.id, value: e.target.value });
                }}>
                <option value="">Не выбрано</option>
                <option value="1">&gt;70</option>
                <option value="2">50-70</option>
                <option value="3">30-50</option>
                <option value="4">10-30</option>
                <option value="5">&lt;10</option>
            </select>
        </div>
    );
};
