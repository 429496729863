import { Cell } from 'react-table';
import { ColumnFilter } from '../../../components/ColumnFilter';
import { IRow } from '../../types';
import { Link } from 'react-router-dom';

export const COLUMNS = [
    {
        Header: 'Id',
        accessor: 'id',
        Filter: ColumnFilter,
        disableFilters: true
    },
    {
        Header: 'Name',
        accessor: 'name',
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'DevEUI',
        accessor: 'deveui',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ row }: IRow) => {
            return (
                <Link to={`/admin/sensors/${row.original.deveui}/edit`}>{row.original.deveui}</Link>
            );
        }
    },
    {
        Header: 'DeviceID',
        accessor: 'did',
        Cell: ({ value }: Cell) => {
            return value ? value : '—';
        },
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'ObjectID',
        accessor: 'oid',
        Cell: ({ value }: Cell) => {
            return value ? value : '—';
        },
        Filter: ColumnFilter,
        FilterLike: true
    }
];
