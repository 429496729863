import React from 'react';
import { IAlert } from '../../../types/contextType';

const Alert = ({ type, message }: IAlert) => {
    return (
        <div className={`alert ${type} alert-dismissible`} role="alert">
            <div className="alert-message">{message}</div>
        </div>
    );
};

export default Alert;
