import React from 'react';
import SettingsEdit from './SettingsEdit';

const Settings = () => {
    return (
        <main className="content">
            <div className="container-fluid p-0">
                <div className="row mb-2 mb-xl-3">
                    <div className="col-auto d-sm-block">
                        <h3>Настройки</h3>
                    </div>
                </div>
            </div>
            <SettingsEdit />
        </main>
    );
};

export default Settings;
