import React, { useMemo } from 'react';
import AllowLayout from '../../Layouts/AllowLayout';
import AdvancedPage from '../../components/Table/AdvancedPage/AdvancedPage';
import { Permissions } from '../../utils/rules';
import { COLUMNS } from './AlarmColumns';

const Alarms = () => {
    const columns = useMemo(() => COLUMNS, []);

    return (
        <main className="content">
            <AllowLayout
                children={
                    <AdvancedPage
                        pageHeader={'Уведомления'}
                        btnAddPermissions={Permissions.WRITE_SENSOR}
                        url={window.env.REACT_APP_API_URL + '/api/alerts'}
                        columns={columns}
                    />
                }
                permissions={Permissions.READ_SENSOR_DRIVER}
            />
        </main>
    );
};

export default Alarms;
