import React, { useEffect } from 'react';
import Input from '../../../components/Ui/components-form/Input';
import Select from '../../../components/Ui/components-form/Select';
import { IDecoderMetric, IMetric, ITypeList } from '../../types';
import { loadingData } from '../../../utils/utils';

interface ICreateDecoder {
    metric: IMetric[];
    formula: ITypeList[];
    decoderMetrics: IDecoderMetric;
    isLoaded: boolean;
    setIsDecoder: (isBool: boolean) => void;
    hasError: (key: string) => boolean;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    handleSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    getErrorMessage: any;
    clearAllErrors: any;
    validateField: (fieldName: string, value: string) => void;
    addDecoder: () => void;
    setDecoderMetrics: (decoder: IDecoderMetric) => void;
}

const transformMetrics = (metrics: IMetric[]) => {
    return metrics.map((item) => {
        return {
            name: `${item.name} [${item.type_metric}] ${item.type_byte}`,
            id: item.id
        };
    });
};

const initialMetric = {
    id: '',
    metric: '',
    typeBytes: '',
    typeMetric: '',
    formula: '',
    pos: '',
    metricSelected: '',
    selectedFormula: ''
};

const CreateDecoder = ({
    hasError,
    metric,
    setIsDecoder,
    handleChange,
    decoderMetrics,
    addDecoder,
    formula,
    getErrorMessage,
    validateField,
    handleSelectChange,
    setDecoderMetrics,
    isLoaded,
    clearAllErrors
}: ICreateDecoder) => {
    const metricList = transformMetrics(metric);

    useEffect(() => {
        setDecoderMetrics(initialMetric);
    }, [setDecoderMetrics]);

    useEffect(() => {
        clearAllErrors();
    }, [clearAllErrors]);

    useEffect(() => {
        loadingData(isLoaded);
    }, [isLoaded]);

    return (
        <div className="card-body text-center">
            <Select
                title="Тип метрики"
                name="metric"
                value={decoderMetrics.metric}
                options={metricList ?? []}
                handleChange={handleSelectChange}
                className={hasError('metric') ? 'is-invalid' : ''}
                required={true}
            />
            <Select
                className={hasError('formula') ? 'is-invalid' : ''}
                title="Формула"
                name="formula"
                value={decoderMetrics.formula}
                options={formula ?? []}
                handleChange={handleSelectChange}
                required={true}
            />
            <Input
                className="pos"
                title="Позиция"
                name="pos"
                type="number"
                placeholder="Введите позицию"
                handleChange={handleChange}
                value={decoderMetrics.pos || ''}
                errorDiv={hasError('pos') ? 'text-danger' : 'd-none'}
                errorMsg={getErrorMessage('pos')}
                required={true}
                onBlur={(e) => validateField(e.target.name, e.target.value)}
            />
            <div className="mt-4 d-flex align-items-center">
                <button
                    onClick={addDecoder}
                    type="submit"
                    className="btn btn-decoder mr-1 btn-primary col-sm-3">
                    Сохранить
                </button>
                <button
                    className="btn btn-decoder btn-outline-secondary col-sm-2"
                    onClick={() => setIsDecoder(false)}>
                    Отмена
                </button>
            </div>
        </div>
    );
};

export default CreateDecoder;
