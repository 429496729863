import React from 'react';
import './Accordion.css';

interface IAccordionProps {
    active: boolean;
    data: string;
}

const Accordion = ({ active, data }: IAccordionProps) => {
    const historyPars = JSON.parse(data);

    return (
        <tr className={active ? 'accordion active' : 'accordion'}>
            <td />
            <td colSpan={2} className="accordion-col">
                <h5>Metrics:</h5>
                <pre>
                    {historyPars.metrics
                        ? JSON.stringify(historyPars.metrics, null, 4)
                        : 'Нет данных'}
                </pre>
            </td>
            <td colSpan={3} className="accordion-col">
                <h5>Rawdata:</h5>
                <pre>
                    {historyPars.rawdata
                        ? JSON.stringify(historyPars.rawdata, null, 4)
                        : 'Нет данных'}
                </pre>
            </td>
        </tr>
    );
};

export default Accordion;
