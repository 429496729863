import { Link } from 'react-router-dom';
import { ColumnFilter } from '../../components/ColumnFilter';
import { ColumnFilterSource } from '../../components/ColumnFilterSource';
import { Cell } from 'react-table';
import { IRow } from '../types';
import { getFormatDate } from '../../utils/utils';

export const COLUMNS = [
    {
        Header: 'Id',
        accessor: 'id',
        Filter: ColumnFilter,
        disableFilters: true
    },
    {
        Header: 'DevEUI',
        accessor: 'deveui',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ row }: IRow) => {
            return (
                <Link to={`/admin/deveui/${row.original.deveui}/edit`}>{row.original.deveui}</Link>
            );
        }
    },
    {
        Header: 'Источник',
        accessor: 'source',
        Cell: ({ value }: Cell) => {
            return value ? value : '—';
        },
        Filter: ColumnFilterSource
    },
    {
        Header: 'Клиент',
        accessor: 'client',
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'Rawdata',
        accessor: 'data',
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'Дата источника',
        accessor: 'source_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        }
    },
    {
        Header: 'Дата создания',
        accessor: 'created_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        }
    }
];
