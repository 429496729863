import React, { useEffect, useState, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Input from '../../components/Ui/components-form/Input';
import Select from '../../components/Ui/components-form/Select';
import Alert from '../../components/Ui/components-ui/Alert';
import { DATA } from './ClientTree';
import { Eventinfo, IClient, IPageOptions } from '../types';
import { getFetchData, postFetchData } from '../../api';
import AllowLayout from '../../Layouts/AllowLayout';
import { Permissions } from '../../utils/rules';

const ClientCreate = () => {
    const history = useHistory();
    const [client, setClient] = useState<IClient>({
        name: '',
        tenant_id: 0
    });
    const [clientTree, setClientTree] = useState<IPageOptions[]>([]);
    const clientOptions = useMemo(() => DATA, []);
    const [tenantOptions, setTenantOptions] = useState<IPageOptions[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [alert, setAlert] = useState({
        type: 'd-none',
        message: ''
    });

    useEffect(() => {
        getFetchData('/api/clients/list').then((response) => {
            if (!response?.length) {
                setClientTree([{ id: 0, name: 'Не выбран' }]);
                return;
            }
            setClientTree(clientOptions(response));
        });

        getFetchData('/api/tenants/list').then((response) => {
            if (!response?.length) {
                setTenantOptions([{ id: 0, name: 'Не выбран' }]);
                return;
            }
            setTenantOptions(response);
        });
    }, [clientOptions]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        if (name === 'tenant_id') {
            setClient({ ...client, [name]: +value });
        }
        setClient({ ...client, [name]: value });
    };

    const commentChange = (event: Eventinfo, editor: ClassicEditor) => {
        const data = editor.getData();
        setClient({ ...client, comment: data });
    };

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errs = [];
        if (!client.name) errs.push('name');
        if (!Number(client.tenant_id)) errs.push('tenant_id');
        setErrors(errs);
        if (errs.length > 0) return false;

        const data = new FormData(e.target);
        const payload = Object.fromEntries(data.entries());

        // TODO: delete mock data using real backend
        const date = new Date();
        const formattedDate = date.toISOString();
        payload.created_at = formattedDate;
        payload.updated_at = formattedDate;

        postFetchData('/api/clients/', payload)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    history.push({
                        pathname: '/admin/clients/'
                    });
                }
            })
            .catch(() => {
                setAlert({
                    type: 'alert-danger',
                    message: 'Не удалось создать запись'
                });
            });
    };

    const hasError = (key: number | string) => {
        return errors.indexOf(String(key as number)) !== -1;
    };

    return (
        <main className="content">
            <AllowLayout
                children={
                    <>
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-sm-block">
                                    <h3>Новый клиент</h3>
                                </div>
                                <div className="col-auto ms-auto text-end mt-n1" />
                            </div>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className="col-md-6 col-xl-6">
                                <div className="card">
                                    <div className="card-body">
                                        <Alert type={alert.type} message={alert.message} />
                                        <Input
                                            title=""
                                            name="comment"
                                            type="hidden"
                                            value={client.comment || ''}
                                            errorDiv={'d-none'}
                                            errorMsg={''}
                                        />
                                        <Input
                                            className={hasError('name') ? 'is-invalid' : ''}
                                            title="Название"
                                            name="name"
                                            type="text"
                                            value={client.name || ''}
                                            handleChange={handleChange}
                                            errorDiv={hasError('name') ? 'text-danger' : 'd-none'}
                                            errorMsg={'Поле не может быть пустым'}
                                            required={true}
                                        />
                                        <Select
                                            title="Тенант"
                                            name="tenant_id"
                                            value={client.tenant_id || 0}
                                            options={tenantOptions}
                                            handleChange={handleChange}
                                            className={hasError('tenant_id') ? 'is-invalid' : ''}
                                            required={true}
                                        />
                                        <Input
                                            className={hasError('city') ? 'is-invalid' : ''}
                                            title="Город"
                                            name="city"
                                            type="text"
                                            value={client.city || ''}
                                            handleChange={handleChange}
                                            errorDiv={hasError('city') ? 'text-danger' : 'd-none'}
                                            errorMsg={'Поле не может быть пустым'}
                                        />
                                        <Input
                                            className={''}
                                            title="Контактное лицо (ФИО)"
                                            name="fio"
                                            type="text"
                                            value={client.fio || ''}
                                            handleChange={handleChange}
                                            errorDiv={'d-none'}
                                            errorMsg={''}
                                            required={false}
                                        />
                                        <Input
                                            className={''}
                                            title="Телефон"
                                            name="phone"
                                            type="text"
                                            value={client.phone || ''}
                                            handleChange={handleChange}
                                            errorDiv={'d-none'}
                                            errorMsg={''}
                                            required={false}
                                        />
                                        <Input
                                            className={''}
                                            title="Email"
                                            name="email"
                                            type="text"
                                            value={client.email || ''}
                                            handleChange={handleChange}
                                            errorDiv={'d-none'}
                                            errorMsg={''}
                                            required={false}
                                        />
                                        <Select
                                            title="Родительский раздел"
                                            name="parent_id"
                                            value={client.parent_id || ''}
                                            options={clientTree}
                                            handleChange={handleChange}
                                        />
                                        <div className="mb-3">
                                            <label className="form-label">Комментарий</label>
                                            <CKEditor
                                                id="comment"
                                                name="comment"
                                                data=""
                                                editor={ClassicEditor}
                                                config={{
                                                    toolbar: [
                                                        'Bold',
                                                        'Italic',
                                                        '|',
                                                        'NumberedList',
                                                        'BulletedList',
                                                        '|',
                                                        'Link',
                                                        '|',
                                                        'Undo',
                                                        'Redo'
                                                    ]
                                                }}
                                                onReady={(editor) => {
                                                    editor.editing.view.change((writer) => {
                                                        writer.setStyle(
                                                            'height',
                                                            '100px',
                                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                            // @ts-ignore
                                                            editor.editing.view.document.getRoot()
                                                        );
                                                    });
                                                }}
                                                onChange={commentChange}
                                            />
                                        </div>
                                        * — обязательные поля
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Сохранить
                            </button>{' '}
                            <Link to="/admin/clients" className="btn btn-outline-secondary">
                                Назад
                            </Link>
                        </form>
                    </>
                }
                permissions={Permissions.WRITE_CLIENT}
            />
        </main>
    );
};

export default ClientCreate;
