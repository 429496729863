import type { Dispatch } from 'redux';
import { setSensors, setLoading, setClientList } from './reducer';
import { clientsList, groupsList } from '../../api/mockUrls';
import { getFetchData } from '../../api';

export const getSensors = () => async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    try {
        const response = await getFetchData(groupsList);

        dispatch(setSensors(response));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setLoading(false));
        console.error(error instanceof Error, 'error');
    }
};

export const getClientList = () => async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    try {
        const response = await getFetchData(clientsList);

        dispatch(setClientList(response));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setLoading(false));
        console.error(error instanceof Error, 'error');
    }
};
