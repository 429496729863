import React, { useEffect, useState } from 'react';
import { AbacProvider, AllowedTo } from 'react-abac';
import { Link, useParams, useHistory } from 'react-router-dom';
import Input from '../../../components/Ui/components-form/Input';
import Alert from '../../../components/Ui/components-ui/Alert';
import { Permissions, User, Rules } from '../../../utils/rules';
import { sensorsGroups } from '../../../api/mockUrls';
import type { TId } from '../../types';
import { updateSensorsData, getFetchData } from '../../../api';
import { loadingData } from '../../../utils/utils';

const SensorsGroupEdit = () => {
    const [role, setRole] = useState('');

    const history = useHistory();
    const { id } = useParams<TId>();

    const [isLoaded, setIsLoaded] = useState(false);
    const [group, setGroup] = useState({
        name: '',
        action: 'update_groups',
        comment: '',
        created_at: new Date(),
        update_at: '',
        sensors: []
    });

    const [errors, setErrors] = useState<string[]>([]);
    const [alert, setAlert] = useState({
        type: 'd-none',
        message: ''
    });

    useEffect(() => {
        const storage = localStorage.getItem('datanorm.user');
        setRole(storage ? storage.toUpperCase() : 'USER');

        getFetchData(`${sensorsGroups}${id}`).then((response) => {
            setGroup(response);
            setIsLoaded(true);
        });
    }, [id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setGroup({ ...group, [name]: value });
    };

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errs = [];
        if (group.name === '') errs.push('name');

        setErrors(errs);
        if (errs.length > 0) return false;

        const data = new FormData(e.target);
        const payload = Object.fromEntries(data.entries());

        updateSensorsData(`${sensorsGroups}${id}`, { ...payload, updated_at: new Date() })
            .then((response) => {
                setIsLoaded(true);
                if (response.ok) {
                    history.goBack();
                }
            })
            .catch((e) => {
                setAlert({
                    type: 'alert-danger',
                    message: 'Не удалось создать запись'
                });
                console.error(e.message, 'message');
            });
    };

    const hasError = (key: number | string) => {
        return errors.indexOf(String(key as number)) !== -1;
    };

    useEffect(() => {
        loadingData(isLoaded);
    }, [isLoaded]);

    return (
        <main className="content">
            <div className="container-fluid p-0">
                <div className="row mb-2 mb-xl-3">
                    <div className="col-auto d-sm-block">
                        <h3>Группа</h3>
                    </div>
                    <div className="col-auto ms-auto text-end mt-n1" />
                </div>
            </div>
            <form onSubmit={onSubmit}>
                <div className="col-md-6 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <Alert type={alert.type} message={alert.message} />
                            <Input
                                className={hasError('name') ? 'is-invalid' : ''}
                                title="Название"
                                name="name"
                                type="text"
                                value={group.name || ''}
                                handleChange={handleChange}
                                errorDiv={hasError('name') ? 'text-danger' : 'd-none'}
                                errorMsg={'Поле не может быть пустым'}
                                required={true}
                            />
                            * — обязательные поля
                        </div>
                    </div>
                </div>

                <AbacProvider user={User} roles={[role]} rules={Rules}>
                    <AllowedTo perform={Permissions.WRITE_SUPPORT_SENSORS_GROUPS}>
                        <button type="submit" className="btn btn-primary">
                            Сохранить
                        </button>{' '}
                    </AllowedTo>
                </AbacProvider>
                <Link to="/admin/sensors/groups/list" className="btn btn-outline-secondary">
                    Назад
                </Link>
            </form>
        </main>
    );
};

export default SensorsGroupEdit;
