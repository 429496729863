import React, { useEffect } from 'react';
import Input from '../../../components/Ui/components-form/Input';
import Select from '../../../components/Ui/components-form/Select';
import { IMetric, ITypeList } from '../../types';
import { metricListLink } from '../../../api/mockUrls';
import { postFetchData } from '../../../api';

type TMetric = Omit<IMetric, 'updated_at' | 'id' | 'user_id'>;
interface ICreateMetric {
    metric: TMetric;
    setMetric: (metric: TMetric) => void;
    typeMetric: ITypeList[];
    typeByte: ITypeList[];
    setIsCreateMetric: (isBoolean: boolean) => void;
    onSubmit: (
        e: React.FormEvent<HTMLFormElement>,
        url: string,
        callback: (url: string, postData: TMetric) => Promise<{ status: string | number }>
    ) => void;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    handleSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    hasError: (key: string) => boolean;
    getErrorMessage: any;
    validateField: (fieldName: string, value: string) => void;
}

const initialMetricItem: TMetric = {
    name: '',
    type: 0,
    type_bytes: 0,
    type_metric: '',
    type_byte: '',
    created_at: ''
};

const CreateMetric = ({
    metric,
    setMetric,
    typeMetric,
    typeByte,
    setIsCreateMetric,
    onSubmit,
    handleChange,
    handleSelectChange,
    hasError,
    getErrorMessage,
    validateField
}: ICreateMetric) => {
    useEffect(() => {
        setMetric(initialMetricItem);
    }, [setMetric]);
    return (
        <form onSubmit={(event) => onSubmit(event, `${metricListLink}/`, postFetchData)}>
            <div className="card-body text-center">
                <Input
                    className={hasError('name') ? 'is-invalid' : ''}
                    title="Metric"
                    name="name"
                    type="text"
                    placeholder="Введите мтерику"
                    handleChange={handleChange}
                    value={metric.name || ''}
                    errorDiv={hasError('name') ? 'text-danger' : 'd-none'}
                    errorMsg={getErrorMessage('name')}
                    required={true}
                    onBlur={(e) => validateField(e.target.name, e.target.value)}
                />
                <Select
                    title="Тип метрики"
                    name="type"
                    value={metric.type}
                    options={typeMetric ?? []}
                    handleChange={handleSelectChange}
                    className={hasError('type') ? 'is-invalid' : ''}
                    required={true}
                />
                <Select
                    title="Тип байта"
                    name="type_bytes"
                    value={metric.type_bytes}
                    options={typeByte ?? []}
                    handleChange={handleSelectChange}
                    className={hasError('type_bytes') ? 'is-invalid' : ''}
                    required={true}
                />
                <div className="mt-4 d-flex align-items-center">
                    <button type="submit" className="btn btn-decoder mr-1 btn-primary col-sm-3">
                        Сохранить
                    </button>
                    <button
                        className="btn btn-decoder btn-outline-secondary col-sm-2"
                        onClick={() => setIsCreateMetric(false)}>
                        Отмена
                    </button>
                </div>
            </div>
        </form>
    );
};

export default CreateMetric;
