import React from 'react';
import { useAbac, AbacProvider } from 'react-abac';
import Alert from '../components/Ui/components-ui/Alert';
import { Rules, User } from '../utils/rules';

interface IChild {
    children: JSX.Element | JSX.Element[];
    permissions: string;
}

const AllowLayout = ({ children, permissions }: IChild) => {
    const storage = localStorage.getItem('datanorm.user');
    const role = storage ? storage.toUpperCase() : 'USER';

    return (
        <AbacProvider user={User} roles={[role]} rules={Rules}>
            <EditPage children={children} permissions={permissions} />
        </AbacProvider>
    );
};

const EditPage = ({ children, permissions }: IChild) => {
    const { userHasPermissions } = useAbac();

    if (!userHasPermissions([permissions])) {
        return <Alert type={'alert-danger'} message={'Недостаточно прав!'} />;
    }

    return <>{children}</>;
};

export default AllowLayout;
