import React from 'react';
import Tenants from './pages/tenants/Tenants';
import TenantCreate from './pages/tenants/TenantCreate';
import TenantEdit from './pages/tenants/TenantEdit';
import Clients from './pages/clients/Clients';
import ClientCreate from './pages/clients/ClientCreate';
import ClientEdit from './pages/clients/ClientEdit';
import Sensors from './pages/sensors/Sensors';
import SensorCreate from './pages/sensors/SensorCreate';
import SensorEdit from './pages/sensors/SensorEdit';
import Rawdata from './pages/rawdata/Rawdata';
import RawdataShow from './pages/rawdata/RawdataShow';
import Metrics from './pages/metrics/Metrics';
import Connectors from './pages/connectors/Connectors';
import ConnectorCreate from './pages/connectors/ConnectorCreate';
import ConnectorEdit from './pages/connectors/ConnectorEdit';
import Drivers from './pages/drivers/Drivers';
import DriverCreate from './pages/drivers/DriverCreate';
import DriverEdit from './pages/drivers/DriverEdit';
import Vendors from './pages/vendors/Vendors';
import VendorCreate from './pages/vendors/VendorCreate';
import VendorEdit from './pages/vendors/VendorEdit';
import Monitor from './pages/monitor/Monitor';
import TicketRoute from './pages/tickets/TicketRoute';
import { Redirect, Route } from 'react-router-dom';
import SensorsGroup from './pages/sensors/SensorsGroup/SensorsGroup';
import SensorsGroupCreate from './pages/sensors/SensorsGroup/SensorsGroupCreate';
import SensorsGroupList from './pages/sensors/SensorsList/SensorsGroupList';
import SensorsGroupEdit from './pages/sensors/SensorsGroup/SensorsGroupEdit';
import Profile from './pages/profile/Profile';
import ChangePassword from './pages/profile/ChangePassword';
import SensorsHistoriesAll from './pages/sensors/SensorsHistoriesAll/SensorsHistoriesAll';
import Alarms from './pages/alarms/Alarms';
import Settings from './pages/settings/Settings';
import Rules from './pages/rules/Rules';
import RulesEdit from './pages/rules/RulesEdit';
import RulesCreate from './pages/rules/RulesCreate';
import { Rules as Rule } from '../src/utils/rules';
import { IWs } from './types/contextType';
import TypeMetric from './pages/typeMetric/TypeMetric';
import TypeMetricCreate from './pages/typeMetric/TypeMetricCreate';
import TypeMetricEdit from './pages/typeMetric/TypeMetricEdit';
import TypeByte from './pages/typeByte/TypeByte';
import TypeByteCreate from './pages/typeByte/TypeByteCreate';
import TypeByteEdit from './pages/typeByte/TypeByteEdit';
import Formula from './pages/formula/Formula';
import FormulaCreate from './pages/formula/FormulaCreate';
import FormulaEdit from './pages/formula/FormulaEdit';
import MetricsList from './pages/metrics-list/MetricsList';
import MetricsListCreate from './pages/metrics-list/MetricsListCreate';
import MetricsListEdit from './pages/metrics-list/MetricsListEdit';
const AppRoute = ({ ws }: IWs) => {
    const storage = localStorage.getItem('datanorm.user')?.toUpperCase();

    return (
        <>
            <Route path="/admin/profile/" exact component={Profile} />
            <Route path="/admin/settings/" exact component={Settings} />
            <Route path="/admin/profile/change-password/" component={ChangePassword} />
            <Route path="/admin/tenants/" exact component={Tenants} />
            <Route path="/admin/tenants/create" exact component={TenantCreate} />
            <Route path="/admin/tenants/:id/edit" exact component={TenantEdit} />
            {/*  */}
            <Route path="/admin/clients/" exact component={Clients} />
            <Route path="/admin/clients/create" exact component={ClientCreate} />
            <Route path="/admin/clients/:id/edit" exact component={ClientEdit} />
            {/*  */}
            <Route path="/admin/sensors/" exact component={Sensors} />
            <Route path="/admin/sensors/create" exact component={SensorCreate} />
            <Route path="/admin/sensors/:id/edit">
                <SensorEdit url={'/api/sensors/'} />
            </Route>
            <Route path={'/admin/sensors/groups'} exact component={SensorsGroup} />
            <Route path={'/admin/sensors/groups/create'} exact component={SensorsGroupCreate} />
            <Route path={'/admin/sensors/groups/list'} component={SensorsGroupList} />
            <Route path="/admin/sensors/groups/:id/edit" component={SensorsGroupEdit} />
            <Route path="/admin/sensors/histories/" component={SensorsHistoriesAll} />
            <Route path="/admin/deveui/:id/edit">
                <SensorEdit url={'/api/deveui/'} />
            </Route>
            {/*  */}
            <Route path="/admin/rawdata/" exact component={Rawdata} />
            <Route path="/admin/rawdata/:id/show" exact component={RawdataShow} />
            {/*  */}
            <Route path="/admin/metrics/" exact component={Metrics} />
            {/*  */}
            <Route path="/admin/connectors/" exact component={Connectors} />
            <Route path="/admin/connectors/create" exact component={ConnectorCreate} />
            <Route path="/admin/connectors/:id/edit" exact component={ConnectorEdit} />
            {/*  */}
            <Route path="/admin/drivers/" exact component={Drivers} />
            <Route path="/admin/drivers/create" exact component={DriverCreate} />
            <Route path="/admin/drivers/:id/edit" exact component={DriverEdit} />
            {/*  */}
            <Route path="/admin/vendors/" exact component={Vendors} />
            <Route path="/admin/vendors/create" exact component={VendorCreate} />
            <Route path="/admin/vendors/:id/edit" exact component={VendorEdit} />
            {/*  */}
            <Route path="/admin/metric-list/" exact component={MetricsList} />
            <Route path="/admin/metric-list/create" exact component={MetricsListCreate} />
            <Route path="/admin/metric-list/:id/edit" exact component={MetricsListEdit} />
            {/*  */}
            <Route path="/admin/type-metric/" exact component={TypeMetric} />
            <Route path="/admin/type-metric/create" exact component={TypeMetricCreate} />
            <Route path="/admin/type-metric/:id/edit" exact component={TypeMetricEdit} />
            {/*  */}
            <Route path="/admin/type-byte/" exact component={TypeByte} />
            <Route path="/admin/type-byte/create" exact component={TypeByteCreate} />
            <Route path="/admin/type-byte/:id/edit" exact component={TypeByteEdit} />
            {/*  */}
            <Route path="/admin/formula/" exact component={Formula} />
            <Route path="/admin/formula/create" exact component={FormulaCreate} />
            <Route path="/admin/formula/:id/edit" exact component={FormulaEdit} />
            {/*  */}
            <Route path="/admin/alarms/" exact component={Alarms} />
            {/*  */}
            <Route path="/admin/rules/" exact component={Rules} />
            <Route path="/admin/rules/create" exact component={RulesCreate} />
            <Route path="/admin/rules/:id/edit" exact component={RulesEdit} />
            {/*  */}
            <Route path="/admin/monitor/" exact component={() => <Monitor ws={ws} />} />
            {/*  */}
            <Route path={'/admin/'} component={TicketRoute} />
            <Route
                exact
                path="/admin"
                render={() => (
                    <Redirect to={storage ? Rule[storage].defaultRoute : '/admin/sensors/'} />
                )}
            />
        </>
    );
};

export default AppRoute;
