import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { AbacProvider, AllowedTo } from 'react-abac';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Tabs from '../../../components/Ui/components-ui/Tabs';
import Tab from '../../../components/Ui/components-ui/Tab';
import { getSensors } from '../../../redux/sensors';
import { Permissions, Rules, User } from '../../../utils/rules';
import { COLUMNS } from './SensorGroupsColumns';
import { sensors } from '../../../api/mockUrls';
import { TStore } from '../../../redux/store';
import { TId } from '../../types';
import { TTab } from '../type';
import AdvancedPage from '../../../components/Table/AdvancedPage/AdvancedPage';

const SensorsGroup = () => {
    const location = useLocation();
    const initialTab = location.hash.replace('#tab-', '');
    const [role, setRole] = useState('');
    const [activeTab, setActiveTab] = useState<string | null>(initialTab);

    const columns = useMemo(() => COLUMNS, []);
    const { id } = useParams<TId>();
    const { group } = useAppSelector((state: TStore) => state?.sensors);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const storage = localStorage.getItem('datanorm.user');
        setRole(storage ? storage.toUpperCase() : 'USER');
        dispatch(getSensors());
    }, []);

    const onClickTab = (tab: TTab) => {
        setActiveTab(tab.target);
    };

    return (
        <main className="content">
            <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-sm-block">
                    <h3>Устройства по группам</h3>
                </div>
                <div className="col-auto ms-auto text-end mt-n1">
                    <AbacProvider user={User} roles={[role]} rules={Rules}>
                        <AllowedTo perform={Permissions.WRITE_SENSOR}>
                            <Link to={'/admin/sensors/groups/create'} className="btn btn-primary">
                                <i className="fas fa-plus" /> Добавить группу
                            </Link>
                        </AllowedTo>
                    </AbacProvider>
                </div>
                <div className="link-groups">
                    <Tabs>
                        {group?.map((item, key: number) => {
                            return (
                                <div key={key}>
                                    <Tab
                                        className="link-groups__tab btn"
                                        onClickTab={onClickTab}
                                        label={item?.name}
                                        tab={item.id}
                                        param={location.search}
                                        activeTab={Number(activeTab) || group[0]?.id}
                                    />
                                </div>
                            );
                        })}
                    </Tabs>
                </div>
                <AdvancedPage
                    url={window.env.REACT_APP_API_URL + `${sensors(id)}`}
                    columns={columns}
                    btnDelPermissions={Permissions.WRITE_SUPPORT_SENSORS_GROUPS}
                    btnAddUrl={'/admin/sensors/groups/create'}
                    activeTab={activeTab || String(group?.[0]?.id)}
                    dltIdArray={true}
                />
            </div>
        </main>
    );
};

export default SensorsGroup;
