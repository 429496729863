import React, { useEffect, useState } from 'react';
import { updateSensorsData } from '../../api';
import { loadingData } from '../../utils/utils';
import PageLayout from '../../Layouts/PageLayout';
import Alert from '../../components/Ui/components-ui/Alert';
import Input from '../../components/Ui/components-form/Input';
import { profileApi } from '../../api/mockUrls';

const ChangePassword = () => {
    const [isLoaded, setIsLoaded] = useState(true);
    const [password, setPassword] = useState({
        password: '',
        password_confirmation: '',
        password_current: ''
    });

    const [errors, setErrors] = useState<string[]>([]);
    const [alert, setAlert] = useState({
        type: 'd-none',
        message: ''
    });

    const errs: string[] = [];

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setPassword({ ...password, [name]: value });
    };

    const checkPasswordConfirmation = (
        e: React.ChangeEvent<HTMLInputElement>,
        password: string
    ) => {
        if (password !== e.target.value) {
            setErrors(['password_confirmation']);
        } else {
            setErrors(['']);
        }
    };

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (password.password === '') errs.push('password');
        if (password.password_confirmation !== password.password)
            errs.push('password_confirmation');
        if (password.password_current === '') errs.push('password_current');

        setErrors(errs);
        if (errs.length > 0) return false;

        const data = new FormData(e.target);
        const payload = Object.fromEntries(data.entries());

        setIsLoaded(false);
        updateSensorsData(`${profileApi}/password`, payload)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setAlert({
                        type: 'alert-success',
                        message: 'Пароль успешно изменен'
                    });

                    setPassword({
                        password: '',
                        password_confirmation: '',
                        password_current: ''
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    type: 'alert-danger',
                    message: 'Ошибка! ' + error.error
                });
            })
            .finally(() => {
                setIsLoaded(true);
            });
    };

    const hasError = (key: number | string) => {
        return errors.indexOf(String(key as number)) !== -1;
    };

    useEffect(() => {
        loadingData(isLoaded);
    }, [isLoaded]);

    return (
        <PageLayout title={'Смена пароля'}>
            <form onSubmit={onSubmit}>
                <div className="col-md-6 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <Alert type={alert.type} message={alert.message} />
                            <Input
                                className={hasError('password') ? 'is-invalid' : ''}
                                title="Новый пароль"
                                name="password"
                                type="text"
                                value={password.password || ''}
                                handleChange={handleChange}
                                errorDiv={hasError('password') ? 'text-danger' : 'd-none'}
                                errorMsg={'Поле не может быть пустым'}
                                required={true}
                            />
                            <Input
                                className={hasError('password_confirmation') ? 'is-invalid' : ''}
                                title="Новый пароль еще раз"
                                name="password_confirmation"
                                type="text"
                                value={password.password_confirmation || ''}
                                handleChange={(e) => {
                                    checkPasswordConfirmation(e, password.password);
                                    handleChange(e);
                                }}
                                errorDiv={
                                    hasError('password_confirmation') ? 'text-danger' : 'd-none'
                                }
                                errorMsg={'Поле не совпадает с новым паролем'}
                                required={true}
                            />
                            <Input
                                className={hasError('password_current') ? 'is-invalid' : ''}
                                title="Текущий пароль"
                                name="password_current"
                                type="text"
                                value={password.password_current || ''}
                                handleChange={handleChange}
                                errorDiv={hasError('password_current') ? 'text-danger' : 'd-none'}
                                errorMsg={'Введите корректный текущий пароль'}
                                required={true}
                            />
                            * — обязательные поля
                        </div>
                    </div>
                </div>
                <button type="submit" className="btn btn-primary">
                    Сохранить
                </button>{' '}
            </form>
        </PageLayout>
    );
};

export default ChangePassword;
