import React, { useMemo } from 'react';
import AdvancedPage from '../../components/Table/AdvancedPage/AdvancedPage';
import { COLUMNS } from '../sensors/SensorColumns';
import AllowLayout from '../../Layouts/AllowLayout';
import { Permissions } from '../../utils/rules';
import { IWs } from '../../types/contextType';

const Monitor = ({ ws }: IWs) => {
    const columns = useMemo(() => COLUMNS, []);
    return (
        <main className="content">
            <AllowLayout
                children={
                    <AdvancedPage
                        pageHeader={'Монитор активности'}
                        url={window.env.REACT_APP_API_URL + '/api/sensors/monitor'}
                        columns={columns}
                        isWs={ws}
                    />
                }
                permissions={Permissions.READ_SUPPORT_MONITOR}
            />
        </main>
    );
};

export default Monitor;
