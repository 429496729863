import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Calendar } from 'react-feather';
import AdvancedPage from '../../../components/Table/AdvancedPage/AdvancedPage';
import { sensors } from '../../../api/mockUrls';
import { COLUMNS } from './SensorHistoryColumns';
import type { TId } from '../../types';
import { useDate } from '../../../hooks/useDate';
import CalendarPicker from '../../../components/Ui/CalendarPicker/CalendarPicker';

interface ISensorHistory {
    deveui: string;
}

const SensorHistory = ({ deveui }: ISensorHistory) => {
    const columns = useMemo(() => COLUMNS, []);
    const { id } = useParams<TId>();
    const { startDate, setStartDate, endDate, setEndDate, formatStartDate, formatEndDate } =
        useDate();

    return (
        <>
            <div className="pt-2 pl-3 calendar">
                <Calendar className="calendar__icon" color="#3b7ddd" size={18} />
                <CalendarPicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                />
            </div>
            <AdvancedPage
                url={window.env.REACT_APP_API_URL + `${sensors(id)}/${deveui}/histories`}
                columns={columns}
                startDate={formatStartDate}
                endDate={formatEndDate}
            />
        </>
    );
};

export default SensorHistory;
