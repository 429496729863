import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isValid } from 'date-fns';

interface IPicker {
    startDate: Date;
    setStartDate: (date: Date) => void;
    endDate: Date;
    setEndDate: (date: Date) => void;
}

const CalendarPicker = ({ startDate, setStartDate, endDate, setEndDate }: IPicker) => {
    const onChange = (dates: [Date, Date]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const [start, end] = event.target.value.replace(/ /g, '').split('-');
        const startDate = new Date(start.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1'));
        const endDate = new Date(end.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1'));

        if (isValid(startDate) && isValid(endDate)) {
            setStartDate(startDate);
            setEndDate(endDate);
        } else {
            console.log('not valid');
        }
    };

    return (
        <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            dateFormat="dd.MM.yyyy"
            className="form-control react-datepicker react-datepicker__input"
            showYearDropdown
            scrollableYearDropdown
            maxDate={new Date()}
            onBlur={handleOnBlur}
            placeholderText="Даты"
            isClearable
        />
    );
};

export default CalendarPicker;
