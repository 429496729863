import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';

export const useDate = () => {
    const history = useHistory();
    const location = useLocation();
    const hash = location.hash;

    const query = new URLSearchParams(location.search);
    const queryStartDate: string | null = query.get('startDate');
    const queryEndDate: string | null = query.get('endDate');

    const initialStartDate = queryStartDate ? new Date(queryStartDate as unknown as Date) : null;
    const initialEndDate = queryEndDate ? new Date(queryEndDate as unknown as Date) : null;

    const [startDate, setStartDate] = useState<any>(initialStartDate);
    const [endDate, setEndDate] = useState<any>(initialEndDate);

    const formatStartDate = startDate ? `${format(new Date(startDate), 'yyyy-MM-dd')}` : '';
    const formatEndDate = endDate ? `${format(new Date(endDate), 'yyyy-MM-dd')}` : '';

    useEffect(() => {
        history.push({
            hash,
            search: `?startDate=${formatStartDate}&endDate=${formatEndDate}`
        });
    }, [endDate, formatEndDate, formatStartDate, hash, history, startDate]);

    return useMemo(
        () => ({
            startDate,
            setStartDate,
            endDate,
            setEndDate,
            formatStartDate,
            formatEndDate
        }),
        [endDate, formatEndDate, formatStartDate, startDate]
    );
};
