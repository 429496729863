import React from 'react';
import Alert from '../components/Ui/components-ui/Alert';
import { Link } from 'react-router-dom';

interface IPageLayout {
    title: string;
    children?: JSX.Element | JSX.Element[];
    id?: string | undefined;
    edit?: JSX.Element;
    subtitle?: JSX.Element;
    breadcrumbs?: boolean;
}

const PageLayout = ({ title, children, id, edit, subtitle, breadcrumbs }: IPageLayout) => {
    return (
        <main className="content">
            <div className="container-fluid p-0">
                <div className="row mb-2 mb-xl-3">
                    <div className="col-auto d-sm-block">
                        <h3>{title}</h3>
                        {subtitle && subtitle}
                    </div>
                    <div className="col-auto ms-auto text-end mt-n1">{edit && edit}</div>
                </div>
            </div>
            {breadcrumbs && (
                <div className="col-md-12 col-xl-12">
                    <Alert />
                    <ul
                        className="nav mb-3"
                        role="tablist"
                        style={{ borderBottom: '1px solid #e4e4e4' }}>
                        <li className="nav-item" style={{ borderBottom: '3px solid #3b7ddd' }}>
                            <Link
                                className="nav-link"
                                style={{ color: '#000', fontSize: '12px' }}
                                to={`/admin/tickets/${id}/subtickets`}>
                                Состав
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                className="nav-link"
                                style={{ color: '#000', fontSize: '12px' }}
                                to={`/admin/tickets/${id}/comments`}>
                                Комментарии
                            </Link>
                        </li>
                    </ul>
                </div>
            )}
            {children}
        </main>
    );
};

export default PageLayout;
