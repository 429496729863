import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ListTable from './ListTable';
import { Column, SortingRule } from 'react-table';
import { IColumn, IField, ISort } from '../type';
import { getPageList } from '../../../api';

interface ILightTable {
    columns: Column[];
    url: string;
}

const LightTable = ({ columns, url }: ILightTable) => {
    const [list, setList] = useState([]);

    const usePathname = () => {
        const location = useLocation();
        return location.pathname;
    };

    const [, setPathname] = useState(usePathname());
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(15);
    const [totalPages, setTotalPages] = useState(0);
    const [sort, setSort] = useState<SortingRule<ISort>>();
    const [filters, setFilters] = useState<[]>([]);
    const [filtersLike, setFiltersLike] = useState([]);

    const pageSizes: number[] = [5, 10, 15, 20, 50, 100];

    const setAllSetters = (
        pageValue: React.SetStateAction<number>,
        pageSizeValue: number,
        totalValue: number,
        sortValue: React.SetStateAction<SortingRule<ISort> | undefined>,
        filtersValue: []
    ) => {
        const totalPages = Math.ceil(totalValue / Number(pageSizeValue));
        setTotalPages(totalPages);
        setTotal(totalValue);
        setPage(pageValue);
        setPageSize(Number(pageSizeValue));
        setSort(sortValue);
        setFilters(filtersValue);
    };

    useEffect(() => {
        setPathname((pathname) => pathname);

        const filterLikeArr: any = [];
        columns.map((column: IColumn<any>) => {
            if (column.FilterLike) return (filterLikeArr[column.accessor] = true);
            return column;
        });
        setFiltersLike(filterLikeArr);

        const getList = async () => {
            const listFromServer = await fetchList(1, pageSize, sort, filters);
            setList(listFromServer.data);
            setAllSetters(1, pageSize, listFromServer.total, sort, filters);
        };
        getList();
    }, [filters, columns]);

    const onSort = async (sort: SortingRule<ISort>) => {
        if (sort === undefined) return;
        const listFromServer = await fetchList(1, pageSize, sort, filters);
        setList(listFromServer.data);
        setAllSetters(1, pageSize, listFromServer.total, sort, filters);
    };

    const fetchList = async (
        page: number,
        limit: number,
        sort: SortingRule<ISort> | undefined,
        filters: []
    ) => {
        // собираем фильтры в строку
        let filterString = '';
        for (const key in filters) {
            if (filtersLike[key]) {
                filterString += `&${key}_like=${filters[key]}`;
            } else {
                filterString += `&${key}=${filters[key]}`;
            }
        }
        // дефолтный урл
        let params = `?_page=${page}&_limit=${limit}${filterString}`;
        params += `&page=${page}&limit=${limit}${filterString}`;

        if (sort !== undefined) {
            params += `&_sort=${sort.id}&_order=${sort.desc ? 'desc' : 'asc'}`;
            params += `&sort=${sort.id}&order=${sort.desc ? 'desc' : 'asc'}`;
        }

        const res = await getPageList(url, params);

        const data = await res.json();
        const count = res.headers.get('X-Total-Count');
        return {
            total: count,
            data: data
        };
    };

    const onPageSizeChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const limit = event.target.value;
        const listFromServer = await fetchList(1, Number(limit), sort, filters);
        setList(listFromServer.data);
        setAllSetters(1, Number(limit), listFromServer.total, sort, filters);
    };

    const onChangeFilter = async (field: IField) => {
        filters[field.key] = field.value;
        if (!filters[field.key]) delete filters[field.key];
        const listFromServer = await fetchList(1, pageSize, sort, filters);
        setList(listFromServer.data);
        setAllSetters(1, pageSize, listFromServer.total, sort, filters);
    };

    return (
        <>
            <ListTable
                columns={columns}
                data={list}
                total={total}
                page={page}
                pageSize={pageSize}
                pageSizes={pageSizes}
                totalPages={totalPages}
                onPageSizeChange={onPageSizeChange}
                onChangeFilter={onChangeFilter}
                onSort={onSort}
            />
        </>
    );
};

export default LightTable;
