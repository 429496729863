import React, { ComponentType } from 'react';
import { Route } from 'react-router-dom';
import auth from './auth';
import ReconnectingWebSocket from 'reconnecting-websocket';

interface IProtectedRoute {
    component: ComponentType<any>;
    path: string;
    exact?: boolean;
    ws?: ReconnectingWebSocket | null;
}

export const ProtectedRoute: React.FC<IProtectedRoute> = ({ component: Component, ...rest }) => {
    return (
        <>
            <Route
                {...rest}
                render={(props) => {
                    if (auth.isAuthenticated()) {
                        return <Component {...props} ws={rest.ws} />;
                    }
                    window.location.href = '/login';
                }}
            />
        </>
    );
};
