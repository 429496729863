import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    Users,
    Disc,
    Briefcase,
    Bell,
    Sliders,
    Share2,
    Layers,
    Database,
    List,
    Monitor,
    CheckSquare,
    Shield,
    Lock,
    User as UserIcon,
    Settings
} from 'react-feather';
import { AbacProvider, AllowedTo } from 'react-abac';
import { Permissions, User, Post, Rules } from '../../utils/rules';

interface IShowSidebarProps {
    showSidebar: boolean;
}

const Sidebar = ({ showSidebar }: IShowSidebarProps) => {
    const [role, setRole] = useState('');

    const ticketArrayLinks: string[] = [
        '/tickets/list',
        '/troubles',
        '/solutions',
        '/ticket-statuses/'
    ];

    useEffect(() => {
        const storage = localStorage.getItem('datanorm.user');
        setRole(storage ? storage.toUpperCase() : 'USER');
    }, []);

    const usePathname = () => {
        const location = useLocation();
        return location.pathname;
    };

    let path = usePathname();
    const logoPath = path.split('/')[1];
    const arrPath = path.replace('/admin', '');
    path = arrPath ? arrPath : 'home';

    return (
        <nav id="sidebar" className={showSidebar ? 'sidebar js-slider' : 'sidebar collapsed'}>
            <div
                className="simplebar-content-wrapper"
                tabIndex={0}
                role="region"
                aria-label="scrollable content">
                <div className="sidebar-content js-simplebar pb-5">
                    <Link className="sidebar-brand" to={`/${logoPath}/sensors`}>
                        <span className="align-middle"># ERCeler</span>
                    </Link>

                    <ul className="sidebar-nav">
                        <AbacProvider user={User} roles={[role]} rules={Rules}>
                            <AllowedTo perform={Permissions.READ_CLIENT_HEADER}>
                                <li className="sidebar-header">Клиенты</li>
                            </AllowedTo>
                            <AllowedTo
                                perform={Permissions.READ_CLIENT_TENANT}
                                data={Post}
                                no={() => <></>}>
                                <li
                                    className={
                                        path === '/tenants' ? 'sidebar-item active' : 'sidebar-item'
                                    }>
                                    <Link
                                        className={
                                            path === '/tenants'
                                                ? 'sidebar-link active'
                                                : 'sidebar-link'
                                        }
                                        to="/admin/tenants">
                                        <Briefcase size={18} strokeWidth={1.5} />{' '}
                                        <span className="align-middle">Тенанты</span>
                                    </Link>
                                </li>
                            </AllowedTo>
                            <AllowedTo perform={Permissions.READ_CLIENT}>
                                <li
                                    className={
                                        path === '/clients' ? 'sidebar-item active' : 'sidebar-item'
                                    }>
                                    <Link
                                        className={
                                            path === '/clients'
                                                ? 'sidebar-link active'
                                                : 'sidebar-link'
                                        }
                                        to="/admin/clients">
                                        <Users size={18} strokeWidth={1.5} />{' '}
                                        <span className="align-middle">Клиенты</span>
                                    </Link>
                                </li>
                            </AllowedTo>

                            <AllowedTo perform={Permissions.READ_SENSOR_HEADER}>
                                <li className="sidebar-header">Устройства</li>
                            </AllowedTo>
                            <AllowedTo perform={Permissions.READ_SENSOR}>
                                <li
                                    className={
                                        path.includes('sensors')
                                            ? 'sidebar-item active'
                                            : 'sidebar-item'
                                    }>
                                    <Link
                                        className="sidebar-link"
                                        to="#"
                                        data-bs-target="#sensors"
                                        data-bs-toggle="collapse"
                                        aria-expanded="true">
                                        <Disc size={18} strokeWidth={1.5} />{' '}
                                        <span className="align-middle">Устройства</span>
                                    </Link>
                                    <ul
                                        id="sensors"
                                        className={
                                            path.includes('sensors')
                                                ? 'sidebar-dropdown list-unstyled collapse show'
                                                : 'sidebar-dropdown list-unstyled collapse'
                                        }
                                        data-bs-parent="#sidebar">
                                        <li
                                            className={
                                                path === '/sensors'
                                                    ? 'sidebar-item active'
                                                    : 'sidebar-item'
                                            }>
                                            <Link className={'sidebar-link'} to="/admin/sensors">
                                                Устройства
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                path === '/sensors/groups'
                                                    ? 'sidebar-item active'
                                                    : 'sidebar-item'
                                            }>
                                            <Link
                                                className="sidebar-link"
                                                to="/admin/sensors/groups">
                                                Группы устройств
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                path === '/sensors/groups/list'
                                                    ? 'sidebar-item active'
                                                    : 'sidebar-item'
                                            }>
                                            <Link
                                                className="sidebar-link"
                                                to="/admin/sensors/groups/list">
                                                Список групп
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                path === '/sensors/histories'
                                                    ? 'sidebar-item active'
                                                    : 'sidebar-item'
                                            }>
                                            <Link
                                                className="sidebar-link"
                                                to="/admin/sensors/histories">
                                                Журнал Uplinks
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </AllowedTo>
                            <AllowedTo perform={Permissions.READ_CLIENT_TENANT}>
                                <li
                                    className={
                                        path === '/rawdata' ? 'sidebar-item active' : 'sidebar-item'
                                    }>
                                    <Link
                                        className={
                                            path === '/rawdata'
                                                ? 'sidebar-link active'
                                                : 'sidebar-link'
                                        }
                                        to="/admin/rawdata">
                                        <Database size={18} strokeWidth={1.5} />{' '}
                                        <span className="align-middle">Rawdata</span>
                                    </Link>
                                </li>
                            </AllowedTo>
                            <AllowedTo perform={Permissions.READ_CLIENT_TENANT}>
                                <li
                                    className={
                                        path === '/metrics' ? 'sidebar-item active' : 'sidebar-item'
                                    }>
                                    <Link
                                        className={
                                            path === '/metrics'
                                                ? 'sidebar-link active'
                                                : 'sidebar-link'
                                        }
                                        to="/admin/metrics">
                                        <i
                                            className="align-middle fa fa-coins"
                                            style={{ fontSize: '18px' }}
                                        />{' '}
                                        <span className="align-middle">Метрики</span>
                                    </Link>
                                </li>
                            </AllowedTo>
                            <AllowedTo perform={Permissions.READ_SENSOR_DRIVER}>
                                <li
                                    className={
                                        path === '/vendors' || path === '/drivers'
                                            ? 'sidebar-item active'
                                            : 'sidebar-item'
                                    }>
                                    <Link
                                        className={'sidebar-link'}
                                        to="#"
                                        data-bs-target="#drivers"
                                        data-bs-toggle="collapse"
                                        aria-expanded="true">
                                        <i className="align-middle fa fa-cogs" />{' '}
                                        <span className="align-middle">Драйвера</span>
                                    </Link>
                                    <ul
                                        id="drivers"
                                        className={
                                            path === '/vendors' ||
                                            path === '/drivers' ||
                                            path === '/metric-list' ||
                                            path === '/type-metric' ||
                                            path === '/type-byte' ||
                                            path === '/formula'
                                                ? 'sidebar-dropdown list-unstyled collapse show'
                                                : 'sidebar-dropdown list-unstyled collapse'
                                        }
                                        data-bs-parent="#sidebar">
                                        <li
                                            className={
                                                path === '/vendors'
                                                    ? 'sidebar-item active'
                                                    : 'sidebar-item'
                                            }>
                                            <Link className={'sidebar-link'} to="/admin/vendors">
                                                Вендоры
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                path === '/drivers'
                                                    ? 'sidebar-item active'
                                                    : 'sidebar-item'
                                            }>
                                            <Link className={'sidebar-link'} to="/admin/drivers">
                                                Драйвера
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                path === '/metric-list'
                                                    ? 'sidebar-item active'
                                                    : 'sidebar-item'
                                            }>
                                            <Link
                                                className={'sidebar-link'}
                                                to="/admin/metric-list">
                                                Список метрик
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                path === '/type-metric'
                                                    ? 'sidebar-item active'
                                                    : 'sidebar-item'
                                            }>
                                            <Link
                                                className={'sidebar-link'}
                                                to="/admin/type-metric">
                                                Типы метрик
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                path === '/type-byte'
                                                    ? 'sidebar-item active'
                                                    : 'sidebar-item'
                                            }>
                                            <Link className={'sidebar-link'} to="/admin/type-byte">
                                                Типы байта
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                path === '/formula'
                                                    ? 'sidebar-item active'
                                                    : 'sidebar-item'
                                            }>
                                            <Link className={'sidebar-link'} to="/admin/formula">
                                                Формулы
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </AllowedTo>

                            <AllowedTo perform={Permissions.READ_SENSOR_PROVIZ}>
                                <li className="sidebar-item">
                                    <Link className="sidebar-link" to="#">
                                        <Sliders size={18} strokeWidth={1.5} />{' '}
                                        <span className="align-middle">Провиженинг</span>
                                    </Link>
                                </li>
                            </AllowedTo>
                            <AllowedTo perform={Permissions.READ_INTEGRATION_HEADER}>
                                <li className="sidebar-header">Интеграции</li>
                            </AllowedTo>
                            <AllowedTo perform={Permissions.READ_INTEGRATION_CONNECTOR}>
                                <li
                                    className={
                                        path === '/connectors'
                                            ? 'sidebar-item active'
                                            : 'sidebar-item'
                                    }>
                                    <Link
                                        className={
                                            path === '/connectors'
                                                ? 'sidebar-link active'
                                                : 'sidebar-link'
                                        }
                                        to="/admin/connectors">
                                        <Share2 size={18} strokeWidth={1.5} />{' '}
                                        <span className="align-middle">Коннекторы</span>
                                    </Link>
                                </li>
                            </AllowedTo>
                            <AllowedTo perform={Permissions.READ_INTEGRATION_PROTOCOL}>
                                <li className="sidebar-item">
                                    <Link className="sidebar-link" to="#">
                                        <Layers size={18} strokeWidth={1.5} />{' '}
                                        <span className="align-middle">Протоколы</span>
                                    </Link>
                                </li>
                            </AllowedTo>

                            <AllowedTo perform={Permissions.READ_NOTIFICATION_HEADER}>
                                <li className="sidebar-header">Уведомления</li>
                            </AllowedTo>
                            <li
                                className={
                                    path === '/rules' ? 'sidebar-item active' : 'sidebar-item'
                                }>
                                <AllowedTo perform={Permissions.READ_NOTIFICATION_RULE}>
                                    <Link
                                        className={
                                            path === '/rules'
                                                ? 'sidebar-link active'
                                                : 'sidebar-link'
                                        }
                                        to="/admin/rules">
                                        <List size={18} strokeWidth={1.5} />{' '}
                                        <span className="align-middle">Правила</span>
                                    </Link>
                                </AllowedTo>
                            </li>
                            <li
                                className={
                                    path === '/alarms' ? 'sidebar-item active' : 'sidebar-item'
                                }>
                                <AllowedTo perform={Permissions.READ_NOTIFICATION_ALARM}>
                                    <Link
                                        className={
                                            path === '/alarms'
                                                ? 'sidebar-link active'
                                                : 'sidebar-link'
                                        }
                                        to="/admin/alarms">
                                        <Bell size={18} strokeWidth={1.5} />{' '}
                                        <span className="align-middle">Уведомления</span>
                                    </Link>
                                </AllowedTo>
                            </li>

                            <AllowedTo perform={Permissions.READ_USER_HEADER}>
                                <li className="sidebar-header">Пользователи</li>
                            </AllowedTo>
                            <AllowedTo perform={Permissions.READ_USER}>
                                <li
                                    className={
                                        path === '/users' ? 'sidebar-item active' : 'sidebar-item'
                                    }>
                                    <Link
                                        className={
                                            path === '/users'
                                                ? 'sidebar-link active'
                                                : 'sidebar-link'
                                        }
                                        to="/admin/users">
                                        <Users size={18} strokeWidth={1.5} />{' '}
                                        <span className="align-middle">Пользователи</span>
                                    </Link>
                                </li>
                                <li
                                    className={
                                        path === '/roles' ? 'sidebar-item active' : 'sidebar-item'
                                    }>
                                    <Link
                                        className={
                                            path === '/roles'
                                                ? 'sidebar-link active'
                                                : 'sidebar-link'
                                        }
                                        to="/admin/roles">
                                        <Shield size={18} strokeWidth={1.5} />{' '}
                                        <span className="align-middle">Роли</span>
                                    </Link>
                                </li>
                            </AllowedTo>

                            <AllowedTo perform={Permissions.READ_SUPPORT_HEADER}>
                                <li className="sidebar-header">Эксплуатация</li>
                            </AllowedTo>
                            <AllowedTo perform={Permissions.READ_SUPPORT_MONITOR}>
                                <li
                                    className={
                                        path === '/monitor' ? 'sidebar-item active' : 'sidebar-item'
                                    }>
                                    <Link
                                        className={
                                            path === '/monitor'
                                                ? 'sidebar-link active'
                                                : 'sidebar-link'
                                        }
                                        to="/admin/monitor">
                                        <Monitor size={18} strokeWidth={1.5} />{' '}
                                        <span className="align-middle">Монитор</span>
                                    </Link>
                                </li>
                            </AllowedTo>
                            <AllowedTo perform={Permissions.WRITE_SUPPORT_SOLUTION}>
                                <li
                                    className={
                                        ticketArrayLinks.includes(path)
                                            ? 'sidebar-item active'
                                            : 'sidebar-item'
                                    }>
                                    <Link
                                        className="sidebar-link"
                                        to="#"
                                        data-bs-target="#tasks"
                                        data-bs-toggle="collapse"
                                        aria-expanded="true">
                                        <CheckSquare size={18} strokeWidth={1.5} />{' '}
                                        <span className="align-middle">Заявки ТТ</span>
                                    </Link>
                                    <ul
                                        id="tasks"
                                        className={
                                            ticketArrayLinks.includes(path)
                                                ? 'sidebar-dropdown list-unstyled collapse show'
                                                : 'sidebar-dropdown list-unstyled collapse'
                                        }
                                        data-bs-parent="#sidebar">
                                        <li
                                            className={
                                                path === '/tickets/list'
                                                    ? 'sidebar-item active'
                                                    : 'sidebar-item'
                                            }>
                                            <Link
                                                className={'sidebar-link'}
                                                to="/admin/tickets/list">
                                                Заявки
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                path === '/troubles'
                                                    ? 'sidebar-item active'
                                                    : 'sidebar-item'
                                            }>
                                            <Link className={'sidebar-link'} to="/admin/troubles">
                                                Списки проблем
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                path === '/solutions'
                                                    ? 'sidebar-item active'
                                                    : 'sidebar-item'
                                            }>
                                            <Link className={'sidebar-link'} to="/admin/solutions">
                                                Списки решений
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                path === '/ticket-statuses/'
                                                    ? 'sidebar-item active'
                                                    : 'sidebar-item'
                                            }>
                                            <Link
                                                className={'sidebar-link'}
                                                to="/admin/ticket-statuses/">
                                                Списки статусов
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </AllowedTo>
                            <AllowedTo perform={Permissions.READ_SUPPORT_EMPLOYEE}>
                                <li
                                    className={
                                        path === '/employees'
                                            ? 'sidebar-item active'
                                            : 'sidebar-item'
                                    }>
                                    <Link
                                        className={
                                            path === '/emploees'
                                                ? 'sidebar-link active'
                                                : 'sidebar-link'
                                        }
                                        to="/admin/employees">
                                        <Users size={18} strokeWidth={1.5} />{' '}
                                        <span className="align-middle">Ремонтники</span>
                                    </Link>
                                </li>
                            </AllowedTo>
                            <AllowedTo perform={Permissions.READ_SUPPORT_HEADER}>
                                <li className="sidebar-header">Профиль</li>
                            </AllowedTo>
                            <AllowedTo perform={Permissions.READ_SUPPORT_MONITOR}>
                                <li
                                    className={
                                        path === '/profile' ? 'sidebar-item active' : 'sidebar-item'
                                    }>
                                    <Link
                                        className={
                                            path === '/profile'
                                                ? 'sidebar-link active'
                                                : 'sidebar-link'
                                        }
                                        to="/admin/profile">
                                        <UserIcon size={18} strokeWidth={1.5} />{' '}
                                        <span className="align-middle">Профиль</span>
                                    </Link>
                                </li>
                            </AllowedTo>
                            <AllowedTo perform={Permissions.READ_SUPPORT_MONITOR}>
                                <li
                                    className={
                                        path === '/profile/change-password'
                                            ? 'sidebar-item active'
                                            : 'sidebar-item'
                                    }>
                                    <Link
                                        className={
                                            path === '/profile/change-password'
                                                ? 'sidebar-link active'
                                                : 'sidebar-link'
                                        }
                                        to="/admin/profile/change-password">
                                        <Lock size={18} strokeWidth={1.5} />{' '}
                                        <span className="align-middle">Обновить пароль</span>
                                    </Link>
                                </li>
                                <AllowedTo perform={Permissions.READ_SUPPORT_MONITOR}>
                                    <li
                                        className={
                                            path === '/settings'
                                                ? 'sidebar-item active'
                                                : 'sidebar-item'
                                        }>
                                        <Link
                                            className={
                                                path === '/settings'
                                                    ? 'sidebar-link active'
                                                    : 'sidebar-link'
                                            }
                                            to="/admin/settings">
                                            <Settings size={18} strokeWidth={1.5} />{' '}
                                            <span className="align-middle">Настройки</span>
                                        </Link>
                                    </li>
                                </AllowedTo>
                            </AllowedTo>
                        </AbacProvider>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Sidebar;
