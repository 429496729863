import React, { SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { TTab } from '../../../pages/sensors/type';

interface ITab {
    label: string;
    tab: number;
    activeTab: number;
    onClickTab: (obj: TTab) => void;
    className?: string;
    param?: string;
}

const Tab = ({ label, tab, activeTab, onClickTab, className = 'nav-link', param }: ITab) => {
    const onClick = (e: SyntheticEvent) => {
        const target = (e?.target as HTMLButtonElement).getAttribute('data-target');

        onClickTab({
            label,
            target
        });
    };

    if (tab === activeTab) className += ' active';
    if (className.includes('btn active')) className = 'btn btn-pill btn-outline-primary';

    return (
        <li className="nav-item">
            <Link
                className={className}
                to={`${param}#tab-${tab}`}
                data-toggle="tab"
                data-target={tab}
                role="tab"
                aria-selected="true"
                onClick={onClick}>
                {label}
            </Link>
        </li>
    );
};

export default Tab;
