import React from 'react';
import ConnectorsHeaderItem from './ConnectorsHeaderItem';
import ConnectorsHeaderInput from './connectorsHeaderInput';
import { IHeaders } from '../../types';

interface IConnectorsHeaderProps {
    connectorHeaders: IHeaders;
    addHeaders: (e: React.MouseEvent<HTMLButtonElement>) => void;
    deleteHeaders: (id: string) => void;
    handleChangeNewInput: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    hasError: (key: string) => boolean;
    handleEditInput: (e: React.ChangeEvent<HTMLInputElement>, name: string, id: string) => void;
    connector: IHeaders[];
}

const ConnectorsHeader = ({
    connectorHeaders,
    addHeaders,
    deleteHeaders,
    handleChangeNewInput,
    hasError,
    handleEditInput,
    connector
}: IConnectorsHeaderProps) => {
    return (
        <div className="row">
            <div className="col-sm-5 mb-2">
                <label>Заголовок</label>
            </div>
            <div className="col-sm-5 mb-2">
                <label>Значение</label>
            </div>
            {connector
                ? connector.map((header) => {
                      return (
                          <ConnectorsHeaderItem
                              key={header.id}
                              id={header.id}
                              header={header.header}
                              value={header.value}
                              deleteHeaders={deleteHeaders}
                              handleEditInput={handleEditInput}
                          />
                      );
                  })
                : ''}
            <ConnectorsHeaderInput
                handleChangeNewInput={handleChangeNewInput}
                connectorHeaders={connectorHeaders}
                addHeaders={addHeaders}
                hasError={hasError}
            />
        </div>
    );
};

export default ConnectorsHeader;
