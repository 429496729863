import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '../../../components/Ui/components-form/Select';
import Multiselect from '../../../components/Ui/components-form/Multiselect';
import PageLayout from '../../../Layouts/PageLayout';
import { SubTicketContext } from '../../../Context/SubTicketContext';
import { subticketsEdit, subticketsStore } from '../../../api/mockUrls';
import { changeInputValue, getFetchData, updateFetchData } from '../../../api';
import { uid } from '../../../utils/id';
import type {
    IidParams,
    ISubTickets,
    TOidsEvent,
    TOids,
    IInputSubTicket,
    ISelectOptionsEdit,
    IDefaultObject
} from '../types';
import { Eventinfo } from '../../types';
import { loadingData } from '../../../utils/utils';

const columnToEditableField: IDefaultObject = {
    deveuis: 'deveuis',
    oids: 'oids',
    trouble: 'trouble_id',
    solution: 'solution_id',
    employees: 'employees',
    user_id: 'user_id',
    created_at: 'created_at'
};

const SubTicketsEdit = () => {
    const history = useHistory();
    const { id, subticketId } = useParams<IidParams>();

    const [subTickets, setSubTickets] = useState<ISubTickets>({
        deveuis: '',
        oids: '',
        ticket_id: id,
        trouble_id: '0',
        solution_id: '0',
        employees: '',
        updated_at: new Date(),
        action: ''
    });

    const { troubleOptions, solutionOptions, employeeOptions } = useContext(SubTicketContext);
    const getOidInitialValue = (value: string) => {
        if (value === subTickets.oids) {
            return value ? value.split(',').map((ar: string) => ({ oid: ar, id: uid() })) : [];
        }
    };
    const [oidOptions, setOidOptions] = useState<TOids[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {
        getFetchData(subticketsEdit(id, subticketId)).then((response: ISubTickets) => {
            setSubTickets(response);
            setLoader(true);
        });
    }, [id, subticketId]);

    useEffect(() => {
        loadingData(loader);
    }, [loader]);

    const setSelectValue = (name: string, { id, value }: IInputSubTicket) => {
        return setSubTickets({
            ...subTickets,
            ...(id ? { [columnToEditableField[name]]: Number(id) } : {}),
            [name]: value
        });
    };

    const onChangeSelect = (
        e: React.ChangeEvent<HTMLSelectElement>,
        options: ISelectOptionsEdit[]
    ) => {
        setSelectValue(e.target.name, {
            id: e.target.value,
            value: options.find((option) => option.id === Number(e.target.value))?.name
        });
    };

    const multiselectChange = ({ target: { options } }: React.ChangeEvent<HTMLSelectElement>) => {
        const value = [...options].filter((o) => o.selected).map((o) => o.value);
        return setSubTickets({ ...subTickets, employees: value.join(', ') });
    };

    const commentChange = (event: Eventinfo, editor: ClassicEditor) => {
        const data = editor.getData();
        setSubTickets({ ...subTickets, comment: data });
    };

    const changeInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (
            event.target.value === '0' ||
            event.target.value.length <= 2 ||
            event.target.value === subTickets.oids
        ) {
            setOidOptions([]);
            return false;
        }

        setLoading(true);

        await changeInputValue(event)
            .then((response) => setOidOptions(response))
            .then(() => setLoading(false));
    };

    const onChangeInput = useAsyncDebounce((event) => {
        changeInput(event);
    }, 1000);

    const autocompleteChange = async (event: React.ChangeEvent<TOidsEvent>, value: TOids[]) => {
        const dataValue = value.map((obj) => obj.oid).join(',');
        setSubTickets({
            ...subTickets,
            oids: dataValue
        });

        setInputValue('');
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = { ...subTickets, updated_at: new Date(), action: 'change_ticket' };
        updateFetchData(subticketsStore(id, subticketId), data)
            .then(() => setLoader(true))
            .then(() => history.goBack());
    };

    return (
        <>
            <PageLayout title={'Редактирование заявки'} id={id} breadcrumbs>
                <form onSubmit={onSubmit}>
                    <div className="col-md-6 col-xl-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3 cell-tag" style={{ position: 'relative' }}>
                                    <label className="form-label">Object ID*</label>
                                    <Autocomplete
                                        multiple
                                        value={getOidInitialValue(subTickets.oids)}
                                        inputValue={inputValue}
                                        id="tags-outlined"
                                        open={open && oidOptions.length > 0}
                                        onOpen={() => {
                                            setOpen(true);
                                        }}
                                        onClose={() => {
                                            setOpen(false);
                                        }}
                                        loading={loading}
                                        options={oidOptions}
                                        getOptionLabel={(option) => option.oid}
                                        onChange={autocompleteChange}
                                        onInputChange={(event: React.ChangeEvent<TOidsEvent>) => {
                                            if (
                                                event !== null &&
                                                typeof event.target.value === 'string'
                                            ) {
                                                setInputValue(event.target.value);
                                                onChangeInput(event);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            {loading ? (
                                                                <CircularProgress size={16} />
                                                            ) : null}
                                                            {params.InputProps.endAdornment}
                                                        </>
                                                    )
                                                }}
                                            />
                                        )}
                                    />
                                    <span
                                        className="spinner-border text-primary d-none"
                                        id="oids"
                                        style={{
                                            position: 'absolute',
                                            right: '33px',
                                            top: '38px',
                                            width: '1rem',
                                            height: '1rem'
                                        }}
                                    />
                                </div>
                                <Select
                                    title="Проблема"
                                    name="trouble"
                                    value={subTickets.trouble_id ?? ''}
                                    options={troubleOptions}
                                    handleChange={(e) => onChangeSelect(e, troubleOptions)}
                                />
                                <Select
                                    title="Решение"
                                    name="solution"
                                    value={subTickets.solution_id ?? ''}
                                    options={solutionOptions}
                                    handleChange={(e) => onChangeSelect(e, solutionOptions)}
                                />
                                <Multiselect
                                    title="Ремонтники"
                                    name="employees"
                                    value={
                                        (subTickets.employees &&
                                            subTickets.employees.split(', ')) ||
                                        []
                                    }
                                    options={employeeOptions}
                                    handleChange={multiselectChange}
                                    className={undefined}
                                    required={undefined}
                                />
                                <div className="mb-3">
                                    <label className="form-label">Комментарий</label>
                                    <CKEditor
                                        id="comment"
                                        name="comment"
                                        data=""
                                        editor={ClassicEditor}
                                        config={{
                                            toolbar: [
                                                'Bold',
                                                'Italic',
                                                '|',
                                                'NumberedList',
                                                'BulletedList',
                                                '|',
                                                'Link',
                                                '|',
                                                'Undo',
                                                'Redo'
                                            ]
                                        }}
                                        onReady={(editor) => {
                                            editor?.editing.view.change((writer) => {
                                                writer.setStyle(
                                                    'height',
                                                    '100px',
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    // @ts-ignore
                                                    editor.editing.view.document.getRoot()
                                                );
                                            });
                                        }}
                                        onChange={commentChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Сохранить
                    </button>{' '}
                    <button
                        type={'button'}
                        onClick={history.goBack}
                        className="btn btn-outline-secondary">
                        Назад
                    </button>
                </form>
            </PageLayout>
        </>
    );
};

export default SubTicketsEdit;
