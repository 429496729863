import React from 'react';
import { Column } from 'react-table';
import { IOnChangeFilter, IOptionsFilter } from '../types';

interface IColumnFilterEmployee {
    column: Column;
    onChangeFilter: (arg: IOnChangeFilter) => void;
    employeeOptions: IOptionsFilter[];
}

export const ColumnFilterEmployee = ({
    column,
    onChangeFilter,
    employeeOptions
}: IColumnFilterEmployee) => {
    return (
        <div>
            <select
                className="form-select"
                onChange={(e) => {
                    onChangeFilter({ key: column.id, value: e.target.value });
                }}>
                <option value="0">Не выбрано</option>
                <option disabled value="0">
                    ---
                </option>
                {employeeOptions.map((option) => {
                    return (
                        <option key={option.id} value={option.id}>
                            - {option.name}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};
