import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Column } from 'react-table';
import Alert from '../../Ui/components-ui/Alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ListTable from './ListTable';
import { SubTicketContext } from '../../../Context/SubTicketContext';
import { useFiltersForTable } from '../../../hooks/useFiltersForTable';
import { IUserSettings, TColumnFromLocalStorage } from '../type';
import ReconnectingWebSocket from 'reconnecting-websocket';

interface IAdvancedPage {
    pageHeader?: string;
    subMenu?: JSX.Element;
    columns: Column[];
    url: string;
    btnAddUrl?: string;
    btnDelPermissions?: string;
    btnAddPermissions?: string;
    csvExport?: boolean;
    csvFilename?: string;
    selectGroups?: boolean;
    isGroup?: boolean;
    activeTab?: string | null;
    isWs?: ReconnectingWebSocket;
    dltIdArray?: boolean;
    dltUrl?: string;
    startDate?: string;
    endDate?: string;
}

const AdvancedPage = ({
    pageHeader,
    subMenu,
    columns,
    url,
    btnAddUrl,
    btnDelPermissions,
    btnAddPermissions,
    csvFilename,
    selectGroups,
    activeTab,
    isWs,
    dltIdArray,
    dltUrl,
    startDate,
    endDate
}: IAdvancedPage) => {
    const { troubleOptions, solutionOptions, employeeOptions, userOptions } =
        useContext(SubTicketContext);

    const {
        list,
        isLoaded,
        error,
        total,
        page,
        pageSize,
        totalPages,
        idBtnExport,
        onPageChange,
        onPageSizeChange,
        onChangeFilter,
        onSort,
        onDelete,
        onExport
    } = useFiltersForTable(
        url,
        activeTab,
        columns,
        csvFilename,
        isWs,
        dltIdArray,
        dltUrl,
        startDate,
        endDate
    );

    const storage = localStorage.getItem('datanorm.userSettings');

    const usePathname = () => {
        const location = useLocation();
        return location.pathname;
    };

    const [pathname, setPathname] = useState(usePathname());
    const [userSettings, setUserSettings] = useState<IUserSettings[]>(
        storage ? JSON.parse(storage) : []
    );

    const pageSizes = [5, 10, 25, 50, 100, 200, 500, 1000, 2000, 5000, 10000];

    useEffect(() => {
        setUserSettings((userSettings) => userSettings);
        setPathname((pathname) => pathname);
    }, []);

    const getColumnFromLocalStorage = () => {
        let hiddenColumnsFromLocalStorage: TColumnFromLocalStorage[] = [];
        let pathnameExist = false;
        const columnsFromLocalStorage = userSettings.filter((item) => item.pathname === pathname);

        if (columnsFromLocalStorage.length) {
            hiddenColumnsFromLocalStorage = columnsFromLocalStorage[0].hiddenColumns;
            pathnameExist = true;
        }

        return { userSettings, hiddenColumnsFromLocalStorage, pathnameExist };
    };

    // Preloader
    if (error) {
        return (
            <div className="col-auto d-sm-block">
                <h3>{pageHeader}</h3>
                <Alert type={'alert-danger'} message={error} />
            </div>
        );
    } else {
        return (
            <>
                <ListTable
                    columns={columns}
                    data={list}
                    total={total}
                    page={page}
                    pageSize={pageSize}
                    pageSizes={pageSizes}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    onChangeFilter={onChangeFilter}
                    onSort={onSort}
                    onDelete={onDelete}
                    pageHeader={pageHeader}
                    subMenu={subMenu}
                    onExport={onExport}
                    idBtnExport={idBtnExport}
                    csvFilename={csvFilename}
                    pathname={pathname}
                    columnFromLocalStorage={getColumnFromLocalStorage()}
                    btnAddUrl={btnAddUrl}
                    btnAddPermissions={btnAddPermissions}
                    btnDelPermissions={btnDelPermissions}
                    troubleOptions={troubleOptions}
                    solutionOptions={solutionOptions}
                    employeeOptions={employeeOptions}
                    userOptions={userOptions}
                    selectGroups={selectGroups}
                    isWs={isWs}
                    isLoaded={isLoaded}
                />
            </>
        );
    }
};

export default AdvancedPage;
