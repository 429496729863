import React, { useMemo, useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { COLUMNS } from './TicketListColumns';
import { Permissions, User, Rules } from '../../utils/rules';
import { AbacProvider, AllowedTo } from 'react-abac';
import AdvancedPage from '../../components/Table/AdvancedPage/AdvancedPage';
import { SubTicketContext } from '../../Context/SubTicketContext';

const TicketList = () => {
    const columns = useMemo(() => COLUMNS, []);
    const [role, setRole] = useState('');
    const { solutionsUpdate, troublesUpdate, employeeUpdate, userUpdate } =
        useContext(SubTicketContext);

    useEffect(() => {
        const storage = localStorage.getItem('datanorm.user');
        setRole(storage ? storage.toUpperCase() : 'USER');
    }, []);

    useEffect(() => {
        troublesUpdate();
        solutionsUpdate();
        employeeUpdate();
        userUpdate();
    }, [employeeUpdate, solutionsUpdate, troublesUpdate, userUpdate]);

    return (
        <main className="content">
            <div className="container-fluid p-0">
                <div className="row mb-2 mb-xl-3">
                    <div className="col-auto d-sm-block">
                        <h3>Заявки</h3>
                    </div>
                    <div className="col-auto ms-auto text-end mt-n1">
                        <AbacProvider user={User} roles={[role]} rules={Rules}>
                            <AllowedTo perform={Permissions.WRITE_SUPPORT_TICKET}>
                                <Link to="/admin/tickets/create" className="btn btn-primary">
                                    <i className="fas fa-plus" />
                                    Добавить
                                </Link>
                            </AllowedTo>
                        </AbacProvider>
                    </div>
                </div>
            </div>

            <div className="col-md-12 col-xl-12">
                <ul
                    className="nav mb-3"
                    role="tablist"
                    style={{ borderBottom: '1px solid #e4e4e4' }}>
                    <li className="nav-item" style={{ borderBottom: '3px solid #3b7ddd' }}>
                        <Link
                            className="nav-link"
                            style={{ color: '#000', fontSize: '12px' }}
                            to="/admin/tickets/list">
                            Список
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            className="nav-link"
                            style={{ color: '#000', fontSize: '12px' }}
                            to="/admin/tickets/">
                            Подробно
                        </Link>
                    </li>
                </ul>
            </div>

            <AdvancedPage
                url={window.env.REACT_APP_API_URL + '/api/tickets/list'}
                columns={columns}
                btnDelPermissions={Permissions.DELETE_SUPPORT_TICKET}
                dltUrl={`${window.env.REACT_APP_API_URL}/api/tickets`}
            />
        </main>
    );
};

export default TicketList;
