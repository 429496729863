import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Input from '../../components/Ui/components-form/Input';
import Alert from '../../components/Ui/components-ui/Alert';
import { Eventinfo } from '../types';
import { postFetchData } from '../../api';
import AllowLayout from '../../Layouts/AllowLayout';
import { Permissions } from '../../utils/rules';
import { vendorsLink } from '../../api/mockUrls';

const VendorCreate = () => {
    const history = useHistory();
    const [vendor, setVendor] = useState({
        name: '',
        comment: ''
    });
    const [errors, setErrors] = useState<string[]>([]);
    const [alert, setAlert] = useState({
        type: 'd-none',
        message: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setVendor({ ...vendor, [name]: value });
    };

    const commentChange = (event: Eventinfo, editor: ClassicEditor) => {
        const data = editor.getData();
        setVendor({ ...vendor, comment: data });
    };

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        // vendor side validation
        const errs = [];
        if (vendor.name === '') errs.push('name');
        setErrors(errs);
        if (errs.length > 0) return false;

        const data = new FormData(e.target);
        const payload = Object.fromEntries(data.entries());

        postFetchData(`${vendorsLink}/`, payload)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    history.push({
                        pathname: '/admin/vendors'
                    });
                }
            })
            .catch(() => {
                setAlert({
                    type: 'alert-danger',
                    message: 'Не удалось создать запись'
                });
            });
    };

    const hasError = (key: number | string) => {
        return errors.indexOf(String(key as number)) !== -1;
    };

    return (
        <main className="content">
            <AllowLayout
                children={
                    <>
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-sm-block">
                                    <h3>Новый вендор</h3>
                                </div>
                                <div className="col-auto ms-auto text-end mt-n1" />
                            </div>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className="col-md-6 col-xl-6">
                                <div className="card">
                                    <div className="card-body">
                                        <Alert type={alert.type} message={alert.message} />
                                        <Input
                                            title=""
                                            name="comment"
                                            type="hidden"
                                            value={vendor.comment || ''}
                                            errorDiv={'d-none'}
                                            errorMsg={''}
                                        />
                                        <Input
                                            className={hasError('name') ? 'is-invalid' : ''}
                                            title="Название"
                                            name="name"
                                            type="text"
                                            value={vendor.name || ''}
                                            handleChange={handleChange}
                                            errorDiv={hasError('name') ? 'text-danger' : 'd-none'}
                                            errorMsg={'Укажите название'}
                                            required={true}
                                        />
                                        <div className="mb-3">
                                            <label className="form-label">Комментарий</label>
                                            <CKEditor
                                                id="comment"
                                                name="comment"
                                                data=""
                                                editor={ClassicEditor}
                                                config={{
                                                    toolbar: [
                                                        'Bold',
                                                        'Italic',
                                                        '|',
                                                        'NumberedList',
                                                        'BulletedList',
                                                        '|',
                                                        'Link',
                                                        '|',
                                                        'Undo',
                                                        'Redo'
                                                    ]
                                                }}
                                                onReady={(editor) => {
                                                    editor.editing.view.change((writer) => {
                                                        writer.setStyle(
                                                            'height',
                                                            '100px',
                                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                            // @ts-ignore
                                                            editor.editing.view.document.getRoot()
                                                        );
                                                    });
                                                }}
                                                onChange={commentChange}
                                            />
                                        </div>
                                        * — обязательные поля
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Сохранить
                            </button>{' '}
                            <Link to="/admin/vendors" className="btn btn-outline-secondary">
                                Назад
                            </Link>
                        </form>
                    </>
                }
                permissions={Permissions.READ_SENSOR_DRIVER}
            />
        </main>
    );
};

export default VendorCreate;
