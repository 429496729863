import React, { useState } from 'react';
import { Column, useAsyncDebounce } from 'react-table';

interface IColumnFilter {
    column: Column;
    onChangeFilter: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ColumnFilter = ({ column, onChangeFilter }: IColumnFilter) => {
    const [value, setValue] = useState('');

    const onChangeInput = useAsyncDebounce((value) => {
        onChangeFilter(value);
    }, 2000);

    return (
        <div>
            <input
                type="text"
                className="form-control"
                value={value || ''}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChangeInput({ key: column.id, value: e.target.value });
                }}
            />
        </div>
    );
};
