import React from 'react';
import { IColumnFilterBattery } from './type';

export const ColumnFilterState = ({ column, onChangeFilter }: IColumnFilterBattery) => {
    return (
        <div>
            <select
                className="form-select"
                onChange={(e) => {
                    onChangeFilter({ key: column.id, value: e.target.value });
                }}>
                <option value="">Не выбрано</option>
                <option value="test">Тест</option>
                <option value="norm">Норма</option>
                <option value="warn">Тревога</option>
                <option value="danger">Авария</option>
            </select>
        </div>
    );
};
