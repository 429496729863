import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { getData, updateFetchData } from '../../api';
import { uid } from '../../utils/id';
import { showError, sortByProperty } from '../../utils/utils';
import Tabs from '../../components/Ui/components-ui/Tabs';
import Tab from '../../components/Ui/components-ui/Tab';
import Alert from '../../components/Ui/components-ui/Alert';
import TabContent from '../../components/Ui/components-ui/TabContent';
import RulesCondition from './rulesCondition/RulesCondition';
import RulesEditChannel from './RulesEditChannel';
import RulesEditMain from './RulesEditMain';
import { ITabs, TId, IRules, IRule } from '../types';
import { TTab } from '../sensors/type';
import AllowLayout from '../../Layouts/AllowLayout';
import { Permissions } from '../../utils/rules';

const tabAlarms: ITabs[] = [
    { id: 0, name: 'Основные' },
    { id: 1, name: 'Условия' },
    { id: 2, name: 'Каналы' }
];

const initialAlarm: IRules = {
    name: '',
    comment: '',
    body: [],
    user_id: 0,
    created_at: '',
    updated_at: ''
};

const initialAlarmRules: IRule = {
    id: uid(),
    key: '',
    condition: '',
    value: ''
};

const RulesEdit = () => {
    const history = useHistory();
    const location = useLocation();
    const initialTab = location.hash.replace('#tab-', '');
    const { id } = useParams<TId>();

    const [activeTab, setActiveTab] = useState<string | null>(initialTab);
    const [rule, setRule] = useState(initialAlarm);
    const [alarmRules, setAlarmRules] = useState(initialAlarmRules);
    const [errors, setErrors] = useState<string[]>([]);
    const [alert, setAlert] = useState({ type: 'd-none', message: '' });

    const transferToObject = () => {
        let rules: IRule[];

        if (alarmRules.key !== '' && alarmRules.condition !== '' && alarmRules.value !== '') {
            rules = [...rule.body[0].rules, alarmRules];
        } else {
            rules = rule.body[0].rules;
        }

        rules.map((rule) => delete rule.id);

        return JSON.stringify({
            rules,
            channel: rule.body[0].channel
        });
    };

    useEffect(() => {
        getData(`/api/alerts/rules/${id}/`).then((response) => {
            const data = response.body && JSON.parse(response.body.split(', '));
            const rules = data.rules.map((rule: IRule) => ({ ...rule, id: uid() }));
            const sortedRules: IRule[] = sortByProperty(rules, 'key');
            const body = [{ ...data, rules: sortedRules, channel: data.channel }];
            setRule({ ...response, body });
        });
    }, [id]);

    const handleAddNewField = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        e.preventDefault();
        const value = e.target.value;
        const name = e.target.name;
        setAlarmRules({ ...alarmRules, id: uid(), [name]: value });
        if (value !== '') {
            setErrors([]);
        }
    };

    const handleAddHeaderInput = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (!alarmRules) return;
        if (alarmRules.key !== '' && alarmRules.condition !== '' && alarmRules.value !== '') {
            const body = [
                {
                    rules: rule.body[0] ? rule.body[0].rules.concat(alarmRules) : [alarmRules],
                    channel: rule.body[0].channel
                }
            ];
            setRule({ ...rule, body });

            setAlarmRules({
                id: '',
                key: '',
                condition: '',
                value: ''
            });
        }

        showError(alarmRules, setErrors);
    };

    const onClickTab = (tab: TTab) => {
        setActiveTab(tab.target);
    };

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        showError(rule, setErrors);

        const date = new Date();
        const formattedDate = date.toISOString();

        const data = {
            ...rule,
            created_at: formattedDate,
            updated_at: formattedDate,
            body: transferToObject()
        };

        updateFetchData(`/api/alerts/rules/${id}/`, data)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    history.push({
                        pathname: '/admin/rules/'
                    });
                }
            })
            .catch(() => {
                setAlert({
                    type: 'alert-danger',
                    message: 'Не удалось создать запись'
                });
            });
    };

    const hasError = (key: number | string) => {
        return errors.indexOf(String(key as number)) !== -1;
    };

    return (
        <main className="content">
            <AllowLayout
                children={
                    <>
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-sm-block">
                                    <h3>Правило</h3>
                                </div>
                                <div className="col-auto ms-auto text-end mt-n1" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-7 col-xl-7">
                                <div className="tab">
                                    <Tabs>
                                        {tabAlarms.map((tab) => {
                                            return (
                                                <Tab
                                                    key={tab.id}
                                                    onClickTab={onClickTab}
                                                    label={tab.name}
                                                    tab={tab.id}
                                                    param={location.search}
                                                    activeTab={Number(activeTab)}
                                                />
                                            );
                                        })}
                                    </Tabs>
                                    <form onSubmit={onSubmit}>
                                        <Alert
                                            type={`mt-3 ${alert.type}`}
                                            message={alert.message}
                                        />
                                        <TabContent activeContent={Number(activeTab)}>
                                            <div className="tab-pane" id="tab-1" role="tabpanel">
                                                <RulesEditMain
                                                    rule={rule}
                                                    setRule={setRule}
                                                    hasError={hasError}
                                                />
                                            </div>
                                            <div className="tab-pane" id="tab-2" role="tabpanel">
                                                {rule ? (
                                                    <RulesCondition
                                                        rule={rule}
                                                        setRule={setRule}
                                                        alarmRules={alarmRules}
                                                        handleAddNewField={handleAddNewField}
                                                        handleAddHeaderInput={handleAddHeaderInput}
                                                        hasError={hasError}
                                                    />
                                                ) : (
                                                    'упс, что-то пошло не так!'
                                                )}
                                            </div>
                                            <div className="tab-pane" id="tab-3" role="tabpanel">
                                                <RulesEditChannel
                                                    name="websocket"
                                                    rule={rule}
                                                    setRule={setRule}
                                                />
                                                <RulesEditChannel
                                                    name="telegram"
                                                    rule={rule}
                                                    setRule={setRule}
                                                />
                                            </div>
                                        </TabContent>
                                        <div className="mt-3">
                                            <button className="btn btn-primary mr-2">
                                                Сохранить
                                            </button>
                                            <Link
                                                to="/admin/rules"
                                                className="btn btn-outline-secondary">
                                                Назад
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </>
                }
                permissions={Permissions.READ_SENSOR_DRIVER}
            />
        </main>
    );
};

export default RulesEdit;
