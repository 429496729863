import React, { useState } from 'react';
import { Row } from 'react-table';
import { TStore } from '../../../redux/store';
import { useAppSelector } from '../../../redux/hooks';
import Select from '../../../components/Ui/components-form/Select';
import Alert from '../../../components/Ui/components-ui/Alert';
import { sensorsGroups } from '../../../api/mockUrls';
import { postFetchData } from '../../../api';

interface TId {
    id?: string | number | [];
}

interface IOriginal extends Row {
    original: TId;
}

interface IRow {
    rows: IOriginal[];
}

const SensorsGroupsSelect = ({ rows }: IRow) => {
    const { group } = useAppSelector((state: TStore) => state.sensors);
    const [groupsValue, setGroupsValue] = useState<string>();
    const [alert, setAlert] = useState({
        type: 'd-none',
        message: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setGroupsValue(e.target.value);
    };

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        const rowsOriginalId = rows.map((row: IOriginal) => row.original.id);

        groupsValue && rowsOriginalId.length > 0
            ? postFetchData(`${sensorsGroups}${groupsValue}/sensors/`, {
                  ids: rowsOriginalId
              })
                  .then((response) => {
                      if (response.ok) {
                          window.location.href = `sensors/groups#tab-${groupsValue}`;
                      }
                  })
                  .catch((e) => {
                      setAlert({
                          type: 'alert-danger',
                          message: 'Invalid response code: ' + e.message
                      });
                  })
            : setAlert({
                  type: 'alert-danger',
                  message: 'Выберите нужные поля'
              });
    };

    return (
        <form onSubmit={onSubmit} className="col-auto ms-auto form-group">
            <Alert type={alert.type} message={alert.message} />
            <div className="text-end">
                <Select
                    title="Добавить в группу"
                    name="groups_id"
                    value={groupsValue ?? ''}
                    options={group}
                    handleChange={handleChange}
                />

                <button type="submit" className="btn btn-primary">
                    Ok
                </button>
            </div>
        </form>
    );
};

export default SensorsGroupsSelect;
