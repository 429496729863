import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { format } from 'date-fns';
import { COLUMNS } from './SubticketColumns';
import { Permissions, User, Rules } from '../../../utils/rules';
import { AbacProvider, AllowedTo } from 'react-abac';
import TicketEdit from '../TicketEdit';
import AdvancedPage from '../../../components/Table/AdvancedPage/AdvancedPage';
import PageLayout from '../../../Layouts/PageLayout';
import { subtickets } from '../../../api/mockUrls';
import { TId } from '../../types';
import { getFetchData } from '../../../api';

const SubTickets = () => {
    const columns = useMemo(() => [...COLUMNS], []);
    const { id } = useParams<TId>();

    const [role, setRole] = useState('');
    const [showEditTicket, setShowEditTicket] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState(true);
    const [statusOldID, setStatusOldID] = useState('');
    const [ticket, setTicket] = useState({
        uid: '',
        status: '',
        firstname: '',
        lastname: '',
        created_at: ''
    });

    useEffect(() => {
        const storage = localStorage.getItem('datanorm.user');
        setRole(storage ? storage.toUpperCase() : 'USER');

        setIsLoaded(false);
        getFetchData(`/api/tickets/${id}`)
            .then((response) => {
                setTicket(response);
                setStatusOldID(response.status_id);
            })
            .then(() => setIsLoaded(true));
    }, [showEditTicket]);

    const onTicketSubmit = () => {
        setShowEditTicket(!showEditTicket);
    };

    // Preloader
    if (!isLoaded) {
        const elem = document.getElementById('preloader');
        if (elem) elem.classList.remove('d-none');
    } else if (isLoaded) {
        const elem = document.getElementById('preloader');
        if (elem) elem.classList.add('d-none');
    }

    return (
        <PageLayout
            title={`Заявка ${ticket.uid}`}
            subtitle={
                <div style={{ position: 'relative', top: '-5px' }}>
                    <span className="badge bg-info">{ticket.status}</span>&nbsp;
                    <span className="badge bg-light text-dark">
                        {ticket.lastname ? ticket.lastname + ' ' + ticket.firstname : ''}
                    </span>
                    <span className="badge bg-light text-dark" style={{ fontWeight: 'normal' }}>
                        {ticket.created_at
                            ? format(new Date(ticket.created_at), 'dd-MM-yyyy HH:mm:ss')
                            : ''}
                    </span>
                </div>
            }
            edit={
                <AbacProvider user={User} roles={[role]} rules={Rules}>
                    <AllowedTo perform={Permissions.WRITE_SUPPORT_TICKET}>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => setShowEditTicket(!showEditTicket)}>
                            {!showEditTicket ? 'Редактировать' : 'Скрыть'}
                        </button>
                    </AllowedTo>
                </AbacProvider>
            }
            id={id}
            breadcrumbs>
            <>
                {showEditTicket && (
                    <TicketEdit
                        onTicketSubmit={onTicketSubmit}
                        ticket={ticket}
                        statusOldID={statusOldID}
                        setTicket={setTicket}
                    />
                )}
            </>
            <AdvancedPage
                url={window.env.REACT_APP_API_URL + `${subtickets(id)}`}
                columns={columns}
                btnDelPermissions={Permissions.DELETE_SUPPORT_TICKET}
            />
            <div className="float-end p-0">
                <AbacProvider user={User} roles={[role]} rules={Rules}>
                    <AllowedTo perform={Permissions.WRITE_SUPPORT_TICKET}>
                        <Link
                            className="btn btn-primary"
                            to={`/admin/tickets/${id}/subtickets/create`}>
                            <i className="fas fa-plus" /> Добавить
                        </Link>
                    </AllowedTo>
                </AbacProvider>
            </div>
        </PageLayout>
    );
};

export default SubTickets;
