import React, { useState } from 'react';
import CreateMetric from './CreateMetric';
import { Dialog } from '@material-ui/core';
import EditMetric from './EditMetric';
import { IMetric, ITypeList } from '../../types';
import useError from '../../../hooks/useError';
import { Edit2, Trash2 } from 'react-feather';

type TMetric = Omit<IMetric, 'updated_at' | 'id' | 'user_id' | 'formula'>;

interface IMetrics {
    metric: IMetric[];
    typeMetric: ITypeList[];
    typeByte: ITypeList[];
    isLoaded: boolean;
    reload: boolean;
    setReload: (isReload: boolean) => void;
    deleteMetric: (id: number) => void;
    showAlert: (type: string, message: string) => void;
}

interface IDriverFormValid {
    name: string;
    type: number;
    type_bytes: number;
}

const initialMetric: IMetric = {
    created_at: '',
    id: 0,
    name: '',
    type: 0,
    type_byte: '',
    type_bytes: 0,
    type_metric: '',
    updated_at: '',
    user_id: 0,
    formula: ''
};

const initialMetricItem: TMetric = {
    name: '',
    type: 0,
    type_bytes: 0,
    type_metric: '',
    type_byte: '',
    created_at: ''
};

const Metric = ({
    metric,
    typeMetric,
    typeByte,
    isLoaded,
    reload,
    deleteMetric,
    showAlert,
    setReload
}: IMetrics) => {
    const {
        setError,
        clearError,
        getErrorMessage,
        hasError,
        hasErrors,
        validateField,
        clearAllErrors
    } = useError();

    const [metricItem, setMetricItem] = useState(initialMetricItem);
    const [selectedMetric, setSelectedMetric] = useState<IMetric>(initialMetric);
    const [isCreateMetric, setIsCreateMetric] = useState(false);
    const [isEditMetric, setIsEditMetric] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setMetricItem({ ...metricItem, [name]: value });
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;

        if (value !== '0') {
            clearError(name);
            setMetricItem({ ...metricItem, [name]: value });
        }
    };

    const validateForm = (): boolean => {
        clearAllErrors();

        const validations: {
            [K in keyof IDriverFormValid]: (value: IDriverFormValid[K]) => boolean;
        } = {
            name: (value) => value.trim() === '',
            type: (value) => value === 0,
            type_bytes: (value) => value === 0
        };

        return Object.entries(validations).reduce((isValid: boolean, [key, validate]) => {
            const value = metricItem[key as keyof IDriverFormValid];
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (validate(value)) {
                setError(key, 'Это поле необходимо заполнить');
                return false;
            }
            return isValid;
        }, true);
    };

    const editMetric = (metric: IMetric) => {
        setSelectedMetric(metric);
    };

    const onSubmit = async (
        e: React.FormEvent<HTMLFormElement>,
        url: string,
        callback: (url: string, postData: TMetric) => Promise<{ status: string | number }>
    ) => {
        e.preventDefault();

        const isFormValid = validateForm();

        if (hasErrors() || !isFormValid) {
            console.log('Форма содержит ошибки. Отправка данных отменена.');
            return;
        }

        const date = new Date();
        const typeMetricName = typeMetric.find(
            (item: ITypeList) => item.id === +metricItem.type
        )?.name;
        const typeByteName = typeByte.find(
            (item: ITypeList) => item.id === +metricItem.type_bytes
        )?.name;

        const postData = {
            ...metricItem,
            type: +metricItem.type,
            type_bytes: +metricItem.type_bytes,
            type_metric: typeMetricName,
            type_byte: typeByteName,
            created_at: date.toISOString(),
            updated_at: date.toISOString()
        };

        await callback(url, postData)
            .then((response: { status: string | number }) => {
                if (response.status === 200 || response.status === 201) {
                    setIsCreateMetric(false);
                    setIsEditMetric(false);
                    setReload(!reload);
                } else {
                    showAlert('alert-danger', 'Unexpected response status: ' + response.status);
                }
            })
            .catch((error: { status: string }) => {
                showAlert('alert-danger', 'Invalid response code: ' + error.status);
            })
            .finally(() => {
                setIsCreateMetric(false);
                setIsEditMetric(false);
            });
    };

    return (
        <section>
            <div className="row align-items-center">
                <h5 className="col">
                    <b>Метрики</b>
                </h5>
                <div className="col text-end">
                    <button className="btn btn-primary" onClick={() => setIsCreateMetric(true)}>
                        <i className="fas fa-plus" />
                    </button>
                </div>
            </div>
            {isLoaded && (
                <ul className="metrics-list mt-3">
                    {metric?.length ? (
                        metric
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((item: IMetric) => {
                                return (
                                    <li key={item.id} className="metrics-list__item">
                                        <div className="metrics-list__content">
                                            <span>{item.name}</span>
                                            <span>{`[${item?.type_metric}]`}</span>{' '}
                                            <span>{item?.type_byte}</span>
                                        </div>
                                        <div className="metrics-list__action">
                                            <span
                                                onClick={() => {
                                                    editMetric(item);
                                                    setIsEditMetric(true);
                                                }}>
                                                <Edit2
                                                    className="metrics-list__edit mr-2"
                                                    size={18}
                                                />
                                            </span>{' '}
                                            <span onClick={() => deleteMetric(item.id)}>
                                                {' '}
                                                <Trash2
                                                    className="metrics-list__delete"
                                                    size={18}
                                                />
                                            </span>
                                        </div>
                                    </li>
                                );
                            })
                    ) : (
                        <li>Нет заданных метрик</li>
                    )}
                </ul>
            )}
            {isCreateMetric && (
                <Dialog
                    onClose={() => setIsCreateMetric(false)}
                    className="dialog-decoder"
                    open={isCreateMetric}
                    PaperProps={{
                        style: { width: '600px', fontFamily: 'Inter' }
                    }}>
                    <CreateMetric
                        setIsCreateMetric={setIsCreateMetric}
                        typeMetric={typeMetric}
                        typeByte={typeByte}
                        hasError={hasError}
                        metric={metricItem}
                        onSubmit={onSubmit}
                        handleChange={handleChange}
                        handleSelectChange={handleSelectChange}
                        getErrorMessage={getErrorMessage}
                        validateField={validateField}
                        setMetric={setMetricItem}
                    />
                </Dialog>
            )}
            {isEditMetric && (
                <Dialog
                    onClose={() => setIsEditMetric(false)}
                    className="dialog-decoder"
                    open={isEditMetric}
                    PaperProps={{
                        style: { width: '600px', fontFamily: 'Inter' }
                    }}>
                    <EditMetric
                        selectedMetric={selectedMetric}
                        typeMetric={typeMetric}
                        typeByte={typeByte}
                        setIsEditMetric={setIsEditMetric}
                        metric={metricItem}
                        setMetric={setMetricItem}
                        handleChange={handleChange}
                        handleSelectChange={handleSelectChange}
                        getErrorMessage={getErrorMessage}
                        hasError={hasError}
                        validateField={validateField}
                        onSubmit={onSubmit}
                        isLoaded={isLoaded}
                    />
                </Dialog>
            )}
        </section>
    );
};

export default Metric;
