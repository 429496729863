export const Roles = {
    SUPERADMIN: 'SUPERADMIN',
    ADMIN: 'ADMIN',
    USER: 'USER',
    SUPPORT: 'SUPPORT',
    SUPPORTUSER: 'SUPPORTUSER'
};

export const Permissions = {
    //
    READ_CLIENT_HEADER: 'READ_CLIENT_HEADER',
    READ_CLIENT_TENANT: 'READ_CLIENT_TENANT',
    READ_CLIENT: 'READ_CLIENT',
    //
    READ_SENSOR_HEADER: 'READ_SENSOR_HEADER',
    READ_SENSOR: 'READ_SENSOR',
    READ_SENSOR_RAWDATA: 'READ_SENSOR_RAWDATA',
    READ_SENSOR_METRIC: 'READ_SENSOR_METRIC',
    READ_SENSOR_DRIVER: 'READ_SENSOR_DRIVER',
    READ_SENSOR_VENDOR: 'READ_SENSOR_VENDOR',
    READ_SENSOR_PROVIZ: 'READ_SENSOR_PROVIZ',
    //
    READ_INTEGRATION_HEADER: 'READ_INTEGRATION_HEADER',
    READ_INTEGRATION_CONNECTOR: 'READ_INTEGRATION_CONNECTOR',
    READ_INTEGRATION_PROTOCOL: 'READ_INTEGRATION_PROTOCOL',
    //
    READ_NOTIFICATION_HEADER: 'READ_NOTIFICATION_HEADER',
    READ_NOTIFICATION_RULE: 'READ_NOTIFICATION_RULE',
    READ_NOTIFICATION_ALARM: 'READ_NOTIFICATION_ALARM',
    //
    READ_USER_HEADER: 'READ_USER_HEADER',
    READ_USER: 'READ_USER',
    //
    READ_SUPPORT_HEADER: 'READ_SUPPORT_HEADER',
    READ_SUPPORT_MONITOR: 'READ_SUPPORT_MONITOR',
    READ_SUPPORT_TICKET: 'READ_SUPPORT_TICKET',
    READ_SUPPORT_TICKET_STATUS: 'READ_SUPPORT_TICKET_STATUS',
    READ_SUPPORT_TROUBLE: 'READ_SUPPORT_TROUBLE',
    READ_SUPPORT_SOLUTION: 'READ_SUPPORT_SOLUTION',
    READ_SUPPORT_EMPLOYEE: 'READ_SUPPORT_EMPLOYEE',
    //
    READ_TOP_NOTIFICATION: 'READ_TOP_NOTIFICATION',
    READ_TOP_GLOBAL_SEARCH: 'READ_TOP_GLOBAL_SEARCH',
    //
    READ_PROFILE_URL: 'READ_PROFILE_URL',
    READ_PROFILE_ANALYTICS: 'READ_PROFILE_ANALYTICS',
    READ_PROFILE_DELIMETER_1: 'READ_PROFILE_DELIMETER_1',
    READ_PROFILE_SETUP: 'READ_PROFILE_SETUP',
    READ_PROFILE_HELP: 'READ_PROFILE_HELP',
    READ_PROFILE_DELIMETER_2: 'READ_PROFILE_DELIMETER_2',

    WRITE_CLIENT_TENANT: 'WRITE_CLIENT_TENANT',
    WRITE_CLIENT: 'WRITE_CLIENT',
    WRITE_SENSOR: 'WRITE_SENSOR',
    WRITE_SENSOR_DRIVER: 'WRITE_SENSOR_DRIVER',
    WRITE_SENSOR_VENDOR: 'WRITE_SENSOR_VENDOR',
    WRITE_INTEGRATION_CONNECTOR: 'WRITE_INTEGRATION_CONNECTOR',
    WRITE_SUPPORT_EMPLOYEE: 'WRITE_SUPPORT_EMPLOYEE',
    WRITE_SUPPORT_TICKET: 'WRITE_SUPPORT_TICKET',
    WRITE_SUPPORT_TICKET_STATUS: 'WRITE_SUPPORT_TICKET_STATUS',
    WRITE_SUPPORT_SENSORS_GROUPS: 'WRITE_SUPPORT_SENSORS_GROUPS',
    WRITE_SUPPORT_TROUBLE: 'WRITE_SUPPORT_TROUBLE',
    WRITE_SUPPORT_SOLUTION: 'WRITE_SUPPORT_SOLUTION',
    WRITE_USER: 'WRITE_USER',

    DELETE_CLIENT_TENANT: 'DELETE_CLIENT_TENANT',
    DELETE_CLIENT: 'DELETE_CLIENT',
    DELETE_SENSOR: 'DELETE_SENSOR',
    DELETE_SENSOR_RAWDATA: 'DELETE_SENSOR_RAWDATA',
    DELETE_SENSOR_METRIC: 'DELETE_SENSOR_METRIC',
    DELETE_SENSOR_DRIVER: 'DELETE_SENSOR_DRIVER',
    DELETE_SENSOR_VENDOR: 'DELETE_SENSOR_VENDOR',
    DELETE_INTEGRATION_CONNECTOR: 'DELETE_INTEGRATION_CONNECTOR',
    DELETE_SUPPORT_EMPLOYEE: 'DELETE_SUPPORT_EMPLOYEE',
    DELETE_SUPPORT_TICKET: 'DELETE_SUPPORT_TICKET',
    DELETE_SUPPORT_TICKET_STATUS: 'DELETE_SUPPORT_TICKET_STATUS',
    DELETE_SUPPORT_SOLUTION: 'DELETE_SUPPORT_SOLUTION',
    DELETE_SUPPORT_TROUBLE: 'DELETE_SUPPORT_TROUBLE',
    DELETE_USER: 'DELETE_USER'
};

export const User = {
    id: 1,
    roles: [Roles.USER]
    // roles: [Roles.USER], // или на уровне роли, которая все доступы описывает подробно
    // permissions: [permissions.READ_CLIENT_TENANT], // или на уровне списка доступов
};

export const Post = {
    id: 1,
    owner: 2
};

export const Rules: any = {
    [Roles.SUPERADMIN]: {
        [Permissions.READ_TOP_NOTIFICATION]: false,
        [Permissions.READ_TOP_GLOBAL_SEARCH]: true,

        [Permissions.READ_PROFILE_URL]: true,
        [Permissions.READ_PROFILE_ANALYTICS]: true,
        [Permissions.READ_PROFILE_DELIMETER_1]: true,
        [Permissions.READ_PROFILE_SETUP]: true,
        [Permissions.READ_PROFILE_HELP]: true,
        [Permissions.READ_PROFILE_DELIMETER_2]: true,

        [Permissions.READ_CLIENT_HEADER]: true,
        [Permissions.READ_CLIENT_TENANT]: true,
        [Permissions.READ_CLIENT]: true,
        [Permissions.WRITE_CLIENT_TENANT]: true,
        [Permissions.WRITE_CLIENT]: true,
        [Permissions.DELETE_CLIENT]: true,
        [Permissions.DELETE_CLIENT_TENANT]: true,

        [Permissions.READ_SENSOR_HEADER]: true,
        [Permissions.READ_SENSOR]: true,
        [Permissions.WRITE_SENSOR]: true,
        [Permissions.DELETE_SENSOR]: true,

        [Permissions.READ_SENSOR_RAWDATA]: true,
        [Permissions.DELETE_SENSOR_RAWDATA]: true,

        [Permissions.READ_SENSOR_METRIC]: true,
        [Permissions.DELETE_SENSOR_METRIC]: true,

        [Permissions.READ_SENSOR_DRIVER]: true,
        [Permissions.READ_SENSOR_VENDOR]: true,
        [Permissions.READ_SENSOR_PROVIZ]: true,

        [Permissions.READ_INTEGRATION_HEADER]: true,
        [Permissions.READ_INTEGRATION_CONNECTOR]: true,
        [Permissions.READ_INTEGRATION_PROTOCOL]: true,
        [Permissions.WRITE_INTEGRATION_CONNECTOR]: true,
        [Permissions.DELETE_INTEGRATION_CONNECTOR]: true,

        [Permissions.READ_NOTIFICATION_HEADER]: true,
        [Permissions.READ_NOTIFICATION_RULE]: true,
        [Permissions.READ_NOTIFICATION_ALARM]: true,

        [Permissions.READ_USER_HEADER]: true,
        [Permissions.READ_USER]: true,

        [Permissions.READ_SUPPORT_HEADER]: true,
        [Permissions.READ_SUPPORT_MONITOR]: true,
        [Permissions.READ_SUPPORT_TICKET]: true,
        [Permissions.WRITE_SUPPORT_TICKET]: true,
        [Permissions.DELETE_SUPPORT_TICKET]: true,
        [Permissions.READ_SUPPORT_TICKET_STATUS]: true,
        [Permissions.WRITE_SUPPORT_TICKET_STATUS]: true,
        [Permissions.WRITE_SUPPORT_SENSORS_GROUPS]: true,
        [Permissions.DELETE_SUPPORT_TICKET_STATUS]: true,
        [Permissions.READ_SUPPORT_TROUBLE]: true,
        [Permissions.WRITE_SUPPORT_TROUBLE]: true,
        [Permissions.DELETE_SUPPORT_TROUBLE]: true,
        [Permissions.READ_SUPPORT_SOLUTION]: true,
        [Permissions.WRITE_SUPPORT_SOLUTION]: true,
        [Permissions.DELETE_SUPPORT_SOLUTION]: true,
        [Permissions.READ_SUPPORT_EMPLOYEE]: true,
        [Permissions.WRITE_SUPPORT_EMPLOYEE]: true,
        [Permissions.DELETE_SUPPORT_EMPLOYEE]: true,

        ['defaultRoute']: '/admin/sensors'
    },
    [Roles.USER]: {
        [Permissions.READ_CLIENT_HEADER]: true,
        [Permissions.READ_CLIENT]: true,
        ['defaultRoute']: '/admin/sensors'
        // если нужно проверять владельца контента
        // [permissions.READ_CLIENT_TENANT]: (post, user) => post.owner === user.id
    },
    [Roles.SUPPORT]: {
        [Permissions.READ_SENSOR_HEADER]: true,
        [Permissions.READ_SENSOR]: true,
        [Permissions.WRITE_SENSOR]: true,
        [Permissions.DELETE_SENSOR]: true,

        [Permissions.READ_SENSOR_RAWDATA]: true,
        [Permissions.DELETE_SENSOR_RAWDATA]: true,

        [Permissions.READ_SENSOR_METRIC]: true,
        [Permissions.DELETE_SENSOR_METRIC]: true,

        [Permissions.READ_SUPPORT_HEADER]: true,
        [Permissions.READ_SUPPORT_MONITOR]: true,
        [Permissions.READ_SUPPORT_TICKET]: true,
        [Permissions.WRITE_SUPPORT_TICKET]: true,
        // [Permissions.DELETE_SUPPORT_TICKET]: true,
        [Permissions.READ_SUPPORT_TICKET_STATUS]: true,
        [Permissions.WRITE_SUPPORT_TICKET_STATUS]: true,
        [Permissions.WRITE_SUPPORT_SENSORS_GROUPS]: true,
        [Permissions.DELETE_SUPPORT_TICKET_STATUS]: true,
        [Permissions.READ_SUPPORT_TROUBLE]: true,
        [Permissions.WRITE_SUPPORT_TROUBLE]: true,
        [Permissions.DELETE_SUPPORT_TROUBLE]: true,
        [Permissions.READ_SUPPORT_SOLUTION]: true,
        [Permissions.WRITE_SUPPORT_SOLUTION]: true,
        [Permissions.DELETE_SUPPORT_SOLUTION]: true,
        [Permissions.READ_SUPPORT_EMPLOYEE]: true,
        [Permissions.WRITE_SUPPORT_EMPLOYEE]: true,
        [Permissions.DELETE_SUPPORT_EMPLOYEE]: true,
        ['defaultRoute']: '/admin/tickets/list'
    },
    [Roles.SUPPORTUSER]: {
        [Permissions.READ_SENSOR_HEADER]: true,
        [Permissions.READ_SENSOR]: true,

        [Permissions.READ_SENSOR_RAWDATA]: true,
        [Permissions.READ_SENSOR_METRIC]: true,
        [Permissions.READ_SUPPORT_HEADER]: true,
        [Permissions.READ_SUPPORT_MONITOR]: true,
        [Permissions.READ_SUPPORT_TICKET]: true,
        [Permissions.READ_SUPPORT_TROUBLE]: true,
        [Permissions.READ_SUPPORT_SOLUTION]: true,
        [Permissions.READ_SUPPORT_EMPLOYEE]: true,
        [Permissions.WRITE_SUPPORT_EMPLOYEE]: true,
        ['defaultRoute']: '/admin/tickets/list'
    }
    // если без роли давать доступ
    // [permissions.READ_CLIENT_TENANT]: (post, user) => post.owner === user.id // если нужно проверять владельца контента
};
