import React from 'react';
import { Column } from 'react-table';
import { IOnChangeFilter, IUserOptions } from '../types';

interface IColumnFilterUsers {
    column: Column;
    onChangeFilter: (arg: IOnChangeFilter) => void;
    userOptions: IUserOptions[];
}

export const ColumnFilterUsers = ({ column, onChangeFilter, userOptions }: IColumnFilterUsers) => {
    return (
        <div>
            <select
                className="form-select"
                onChange={(e) => {
                    onChangeFilter({ key: column.id, value: e.target.value });
                }}>
                <option value="0">Не выбран</option>
                <option disabled value="0">
                    ---
                </option>
                {userOptions.map((option) => {
                    return (
                        <option key={option.id} value={option.id}>
                            {option.last_name + ' ' + option.name}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};
