import React, { ChangeEvent, forwardRef } from 'react';

interface IInput {
    title?: string;
    name: string;
    type: string;
    value?: string | number | readonly string[] | undefined;
    handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    errorDiv?: string;
    errorMsg?: string;
    required?: boolean;
    requiredWithoutStar?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    placeholder?: string;
}

const Input = forwardRef<HTMLInputElement, IInput>(
    (
        {
            title,
            name,
            type,
            value,
            handleChange,
            onBlur,
            className,
            errorDiv,
            errorMsg,
            required,
            requiredWithoutStar,
            disabled,
            readOnly,
            placeholder
        },
        ref
    ) => {
        return (
            <div className={type === 'hidden' || className === 'mb-0' ? 'mb-0' : 'mb-3'}>
                {title && (
                    <label className={type === 'hidden' ? 'd-none' : 'form-label'}>
                        {title}
                        {required && !requiredWithoutStar ? '*' : ''}
                    </label>
                )}

                <input
                    ref={ref}
                    type={type}
                    className={`form-control ${className}`}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    disabled={disabled}
                    readOnly={readOnly}
                    placeholder={placeholder}
                    onBlur={onBlur}
                />
                {errorMsg && <div className={errorDiv}>{errorMsg}</div>}
            </div>
        );
    }
);

Input.defaultProps = {
    disabled: false
};

export default Input;
