import React, { ChangeEvent, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Input from '../../components/Ui/components-form/Input';
import Alert from '../../components/Ui/components-ui/Alert';
import { Eventinfo, ITypeList, TId } from '../types';
import { getFetchData, updateFetchData } from '../../api';
import AllowLayout from '../../Layouts/AllowLayout';
import { Permissions } from '../../utils/rules';
import { metricListLink, typeByteLink, typeMetricLink } from '../../api/mockUrls';
import { loadingData } from '../../utils/utils';
import Select from '../../components/Ui/components-form/Select';

interface IMetricOption {
    name: string;
    type_metric: string;
    type_byte: string;
    type_bytes: string | number;
    type: string | number;
    updated_at: string | number;
    comment: string;
}
const initialTypeMetric: IMetricOption = {
    name: '',
    type: 0,
    type_bytes: 0,
    type_metric: '',
    type_byte: '',
    updated_at: '',
    comment: ''
};

const MetricsListEdit = () => {
    const history = useHistory();
    const { id } = useParams<TId>();

    const [isLoaded, setIsLoaded] = useState(true);
    const [metric, setMetric] = useState(initialTypeMetric);
    const [typeByte, setTypeByte] = useState<ITypeList[]>([]);
    const [typeMetric, setTypeMetric] = useState<ITypeList[]>([]);

    const [errors, setErrors] = useState<string[]>([]);
    const [alert, setAlert] = useState({
        type: 'd-none',
        message: ''
    });
    const [data, setData] = useState('');

    useEffect(() => {
        setIsLoaded(false);
        getFetchData(`${metricListLink}/${id}/`)
            .then((response) => {
                setMetric(response);
                setData(response.comment ?? '');
            })
            .then(() => setIsLoaded(true));

        getFetchData(`${typeByteLink}/`)
            .then((response) => {
                setTypeByte(response);
                setData(response.comment ?? '');
            })
            .then(() => setIsLoaded(true));
        getFetchData(`${typeMetricLink}/`)
            .then((response) => {
                setTypeMetric(response);
            })
            .then(() => setIsLoaded(true));
    }, [id]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
    ) => {
        const value = e.target.value;
        const name = e.target.name;
        setMetric({ ...metric, [name]: value });
    };

    const commentChange = (event: Eventinfo, editor: ClassicEditor) => {
        const data = editor.getData();
        setMetric({ ...metric, comment: data });
    };

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        // vendor side validation
        const errs = [];
        if (metric.name === '') errs.push('name');
        setErrors(errs);
        if (errs.length > 0) return false;

        const date = new Date();
        const typeMetricName = typeMetric.find((item: ITypeList) => item.id === +metric.type)?.name;
        const typeByteName = typeByte.find(
            (item: ITypeList) => item.id === +metric.type_bytes
        )?.name;

        const postData = {
            ...metric,
            type: +metric.type,
            type_bytes: +metric.type_bytes,
            type_metric: typeMetricName,
            type_byte: typeByteName,
            updated_at: date.toISOString()
        };

        updateFetchData(`${metricListLink}/${id}/`, postData)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    history.push({
                        pathname: '/admin/metric-list/'
                    });
                }
            })
            .catch(() => {
                setAlert({
                    type: 'alert-danger',
                    message: 'Не удалось создать запись'
                });
            });
    };

    const hasError = (key: number | string) => {
        return errors.indexOf(String(key as number)) !== -1;
    };

    useEffect(() => {
        loadingData(isLoaded);
    }, [isLoaded]);

    console.log(metric, 'metric');

    return (
        <main className="content">
            <AllowLayout
                children={
                    <>
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-sm-block">
                                    <h3>Тип метрики</h3>
                                </div>
                                <div className="col-auto ms-auto text-end mt-n1" />
                            </div>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className="col-md-6 col-xl-6">
                                <div className="card">
                                    <div className="card-body">
                                        <Alert type={alert.type} message={alert.message} />
                                        <Input
                                            title=""
                                            name="comment"
                                            type="hidden"
                                            value={metric.comment || ''}
                                            errorDiv={'d-none'}
                                            errorMsg={''}
                                        />
                                        <Input
                                            className={hasError('name') ? 'is-invalid' : ''}
                                            title="Название"
                                            name="name"
                                            type="text"
                                            value={metric.name || ''}
                                            handleChange={handleChange}
                                            errorDiv={hasError('name') ? 'text-danger' : 'd-none'}
                                            errorMsg={'Укажите название'}
                                            required={true}
                                        />
                                        <Select
                                            title="Тип метрики"
                                            name="type"
                                            value={metric.type || 0}
                                            options={typeMetric}
                                            handleChange={handleChange}
                                            className={hasError('type') ? 'is-invalid' : ''}
                                            required={true}
                                        />
                                        <Select
                                            title="Тип байта"
                                            name="type_bytes"
                                            value={metric.type_bytes || 0}
                                            options={typeByte || []}
                                            handleChange={handleChange}
                                            className={hasError('type') ? 'is-invalid' : ''}
                                            required={true}
                                        />
                                        <div className="mb-3">
                                            <label className="form-label">Комментарий</label>
                                            <CKEditor
                                                id="comment"
                                                name="comment"
                                                data={data}
                                                editor={ClassicEditor}
                                                config={{
                                                    toolbar: [
                                                        'Bold',
                                                        'Italic',
                                                        '|',
                                                        'NumberedList',
                                                        'BulletedList',
                                                        '|',
                                                        'Link',
                                                        '|',
                                                        'Undo',
                                                        'Redo'
                                                    ]
                                                }}
                                                onReady={(editor) => {
                                                    editor.editing.view.change((writer) => {
                                                        writer.setStyle(
                                                            'height',
                                                            '100px',
                                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                            // @ts-ignore
                                                            editor.editing.view.document.getRoot()
                                                        );
                                                    });
                                                }}
                                                onChange={commentChange}
                                            />
                                        </div>
                                        * — обязательные поля
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Сохранить
                            </button>{' '}
                            <Link to="/admin/metric-list" className="btn btn-outline-secondary">
                                Назад
                            </Link>
                        </form>
                    </>
                }
                permissions={Permissions.READ_SENSOR_DRIVER}
            />
        </main>
    );
};

export default MetricsListEdit;
