import { useState, useCallback } from 'react';

type ErrorState = {
    [key: string]: string;
};

const useError = () => {
    const [errors, setErrors] = useState<ErrorState>({});

    const setError = useCallback((field: string, message: string) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: message
        }));
    }, []);

    const clearError = useCallback((field: string) => {
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[field];
            return newErrors;
        });
    }, []);

    const clearAllErrors = useCallback(() => {
        setErrors({});
    }, []);

    const hasError = useCallback(
        (field: string) => {
            return !!errors[field];
        },
        [errors]
    );

    const hasErrors = useCallback(() => {
        return Object.keys(errors).length > 0;
    }, [errors]);

    const getErrorMessage = useCallback(
        (field: string) => {
            return errors[field] || '';
        },
        [errors]
    );

    const validateField = (fieldName: string, value: string) => {
        if (value.trim() === '') {
            setError(fieldName, 'Это поле не может быть пустым');
        } else {
            clearError(fieldName);
        }
    };

    return {
        setError,
        clearError,
        clearAllErrors,
        hasError,
        getErrorMessage,
        errors,
        hasErrors,
        validateField
    };
};

export default useError;
