import { createContext } from 'react';
import { ISubTicketContext } from '../types/contextType';

const globalObject: ISubTicketContext = {
    troubleOptions: [],
    solutionOptions: [],
    employeeOptions: [],
    userOptions: [],
    oidOptions: [],
    isLoadedSolution: true,
    isLoadedTrouble: true,
    isLoadedEmployee: true,
    troublesUpdate: Function,
    solutionsUpdate: Function,
    employeeUpdate: Function,
    userUpdate: Function
};

export const SubTicketContext = createContext(globalObject);
