import { Link } from 'react-router-dom';
import { ColumnFilter } from '../../components/ColumnFilter';
import { Cell } from 'react-table';
import { IRow } from '../types';
import { getFormatDate } from '../../utils/utils';

export const COLUMNS = [
    {
        Header: 'Id',
        accessor: 'id',
        Filter: ColumnFilter,
        disableFilters: true
    },
    {
        Header: 'Название',
        accessor: 'name',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ row }: IRow) => {
            return (
                <Link to={`/admin/connectors/${row.original.id}/edit`}>{row.original.name}</Link>
            );
        }
    },
    {
        Header: 'Клиент',
        accessor: 'client',
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'Url',
        accessor: 'url',
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'Token',
        accessor: 'token',
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'Headers',
        accessor: 'headers',
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'Body',
        accessor: 'body',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ row }: IRow) => {
            return <span style={{ maxWidth: '300px', display: 'block' }}>{row.original.body}</span>;
        }
    },
    {
        Header: 'Matching',
        accessor: 'matching',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ row }: IRow) => {
            return (
                <span style={{ maxWidth: '300px', display: 'block' }}>{row.original.matching}</span>
            );
        }
    },
    {
        Header: 'Example',
        accessor: 'example',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ row }: IRow) => {
            return (
                <span style={{ maxWidth: '300px', display: 'block' }}>{row.original.example}</span>
            );
        }
    },
    {
        Header: 'Preview',
        accessor: 'preview',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ row }: IRow) => {
            return (
                <span style={{ maxWidth: '300px', display: 'block' }}>{row.original.preview}</span>
            );
        }
    },
    {
        Header: 'Дата создания',
        accessor: 'created_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        }
    },
    {
        Header: 'Дата изменения',
        accessor: 'updated_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        }
    }
];
