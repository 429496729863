import auth from '../components/auth';
import { uid } from './id';

interface IncomingHttpHeaders {
    'accept-patch'?: string;
    'accept-ranges'?: string;
    accept?: string;
    warning?: string;
    'www-authenticate'?: string;
    [header: string]: string | string[] | undefined | any;
}

interface IOptions {
    method: string;
    url: string;
    params?: any;
    accessToken?: string;
    headers?: IncomingHttpHeaders;
}

// eslint-disable-next-line
export default async <DataType = any>(options: IOptions) => {
    const method = options.method || 'GET';
    const params = options.params || {};
    const accessToken = auth.jwt();

    if (['GET', 'DELETE'].includes(method) && Object.keys(params).length !== 0) {
        options.url +=
            '?' +
            Object.keys(params)
                .map(
                    (key) =>
                        encodeURIComponent(key) +
                        '=' +
                        encodeURIComponent(params ? params[key] : '')
                )
                .join('&');
    }

    let headers: IncomingHttpHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, no-redux, must-revalidate, max-age=40000',
        'X-Request-ID': uid()
    };

    if (options.accessToken) headers.Authorization = 'Bearer ' + options.accessToken;
    else if (Object.keys(options).length !== 0) {
        headers.Authorization = 'Bearer ' + accessToken;
    }

    if (options.headers) {
        headers = {
            ...headers,
            ...options.headers
        };
    }

    let body = null;

    if (['POST', 'PUT', 'PATCH'].includes(method)) body = JSON.stringify(params);

    return fetch(options.url, {
        headers,
        method,
        body
    }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
            return Promise.resolve(response as DataType);
        } else if (response.status >= 400) {
            const { status, statusText } = response;

            return response
                .json()
                .then((response) => Promise.reject({ ...response, status, statusText }))
                .catch((error) => Promise.reject({ ...error, status, statusText }));
        } else {
            return Promise.reject({ ...response, status: 405, message: 'Network request failed' });
        }
    });
};
