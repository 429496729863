import { Link } from 'react-router-dom';
import { ColumnFilter } from './Filters/SensorEditColumnFilter';
import { IRow } from '../types';

export const COLUMNS = [
    {
        Header: 'DevEUI',
        accessor: 'deveui',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ row }: IRow) => {
            return <Link to={`/admin/sensors/${row.original.id}/edit`}>{row.original.deveui}</Link>;
        }
    }
];
