import { IBodies } from '../pages/types';

interface IArray {
    oid: string;
    id: number | string;
}

interface IError {
    [key: string]: string | undefined | number | IBodies[];
}

export const uniqArray = (array: IArray[]) => {
    return Array.from(new Set(array.map((item) => JSON.stringify(item)))).map((item) =>
        JSON.parse(item)
    );
};

export const setParamsForSensorsGroup = (array: (string | undefined)[] | string) => {
    let url = '?';
    for (let i = 0; i < Number(array[i]); i++) {
        url += `ids[${i}]=${array[i]}&`;
    }
    return url;
};

export const loadingData = (isLoaded: boolean) => {
    if (!isLoaded) {
        const elem = document.getElementById('preloader');
        if (elem) elem.classList.remove('d-none');
    } else if (isLoaded) {
        const elem = document.getElementById('preloader');
        if (elem) elem.classList.add('d-none');
    }
};

export const isEmail = (email: string) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export const getFormatDate = (value: string) => {
    if (!value) {
        return '—';
    }
    const dateParts = value.split('T');
    const datePart = dateParts[0];
    const timePart = dateParts[1].split('.')[0];
    let time: string;

    if (timePart.includes('+')) {
        time = timePart.split('+')[0];
    } else {
        time = dateParts[1].split('.')[0];
    }

    return `${datePart} ${time.replace('Z', '')}`;
};

export const showError = (obj: IError, setErrors: (arg: string[]) => void) => {
    const errs = [];
    for (const key in obj) {
        if (obj[key] === '') errs.push(key);
    }
    setErrors(errs);
    if (errs.length > 0) return false;
};

export const sortByProperty = <T, K extends keyof T>(arr: T[], property: K): T[] => {
    return arr.sort((a, b) => {
        if (a[property] < b[property]) {
            return -1;
        }
        if (a[property] > b[property]) {
            return 1;
        }
        return 0;
    });
};
