import React, { useEffect, useState } from 'react';

interface IEditTextareaProps {
    body: string;
    setBodies: (body: string) => void;
    hasError: (key: string) => boolean;
}

const JsonDisplay = ({ body, setBodies, hasError }: IEditTextareaProps) => {
    const [rows, setRows] = useState(1);
    const textAreaClassName = hasError('json') ? 'is-invalid' : '';
    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setBodies(event.target.value);
    };

    useEffect(() => {
        body && setRows(Object.keys(body).length);
    }, [body]);

    return (
        <div>
            <pre>
                <textarea
                    className={`form-control ${textAreaClassName}`}
                    name="json"
                    rows={rows / 40}
                    cols={112}
                    value={body || ''}
                    onChange={handleInputChange}
                />
            </pre>
        </div>
    );
};

export default JsonDisplay;
