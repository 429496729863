import React from 'react';

type IBadge = {
    value: string;
    color: string;
};

const Badge = ({ value, color }: IBadge) => {
    const o: { [key: string]: string } = {};

    o['active'] = 'bg-success';
    o['wait'] = 'bg-light text-dark';
    o['blocked'] = 'bg-danger';

    o['norm'] = 'bg-light text-dark';
    o['test'] = 'bg-info text-dark';
    o['warn'] = 'bg-warning text-dark';
    o['danger'] = 'bg-danger';

    return <span className={'badge ' + o[color]}>{value}</span>;
};

export default Badge;
