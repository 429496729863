import React, { useEffect, useState } from 'react';
import { Bell } from 'react-feather';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
    getSettings,
    setSettingsError,
    setSettingsSuccess,
    updateSettings
} from '../../redux/settings';
import Toast from '../../components/Ui/toast/Toast';
import { loadingData } from '../../utils/utils';
import { TStore } from '../../redux/store';

interface ISetting {
    alert: boolean;
}

const initialSetting: ISetting = {
    alert: false
};

const SettingsEdit = () => {
    const [setting, setSetting] = useState<ISetting>(initialSetting);
    const [postAlert, setPostAlert] = useState(0);

    const dispatch = useAppDispatch();
    const { settings, loading, error, success } = useAppSelector(
        (state: TStore) => state?.settings
    );

    useEffect(() => {
        dispatch(getSettings());
        dispatch(setSettingsError(null));
        dispatch(setSettingsSuccess(false));
    }, [dispatch]);

    useEffect(() => {
        setSetting({ ...setting, alert: settings.alert });
        setPostAlert(Number(settings.alert));
    }, [settings.alert]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked;
        const name = e.target.name;

        value ? setPostAlert(1) : setPostAlert(0);
        setSetting({ ...setting, [name]: value });
    };

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(updateSettings({ alert: postAlert }));
    };

    useEffect(() => {
        loadingData(!loading);
    }, [loading]);

    return (
        <div className="container-fluid p-0">
            {error && <Toast message={`Invalid response code:`} type={'alert-danger'} />}
            {success && <Toast message={'Данные сохранены'} type={'alert-success'} />}
            <div className="row">
                <form onSubmit={onSubmit}>
                    <div className="col-md-6 col-xl-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="row mb-3">
                                    <span className="col-auto pr-0">
                                        <input
                                            name="alert"
                                            className="form-check"
                                            type="checkbox"
                                            id="isBell"
                                            checked={Boolean(setting?.alert)}
                                            onChange={handleChange}
                                        />
                                    </span>
                                    <label className="form-label col-9" htmlFor="isBell">
                                        <span className="pr-1">Показать уведомления</span>
                                        "<Bell strokeWidth={1.5} width={18} />"
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="btn btn-primary">Сохранить</button>
                </form>
            </div>
        </div>
    );
};

export default SettingsEdit;
