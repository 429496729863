import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISensorGroup } from './types';
import { IClient } from '../../pages/types';

const initialState: TSensors = {
    loading: false,
    group: [],
    clientList: []
};

export const projectSensorsSlice = createSlice({
    name: 'sensors',
    initialState,
    reducers: {
        setSensors: (state, action) => {
            state.group = action.payload;
        },
        setClientList: (state, action) => {
            state.clientList = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        }
    }
});

export const sensorsReducer = projectSensorsSlice.reducer;

export const { setSensors, setClientList, setLoading } = projectSensorsSlice.actions;

export type TSensors = {
    loading: boolean;
    group: ISensorGroup[];
    clientList: IClient[];
};
