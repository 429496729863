import React from 'react';
import Input from '../../../components/Ui/components-form/Input';
import { IHeaders } from '../../types';

interface IConnectorsHeaderInputProps {
    handleChangeNewInput: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    connectorHeaders: IHeaders;
    addHeaders: (e: React.MouseEvent<HTMLButtonElement>) => void;
    hasError: (key: string) => boolean;
}

const ConnectorsHeaderInput = ({
    handleChangeNewInput,
    connectorHeaders,
    addHeaders,
    hasError
}: IConnectorsHeaderInputProps) => {
    return (
        <>
            <div className="col-sm-5">
                <Input
                    className={hasError('header') ? 'is-invalid' : ''}
                    name="header"
                    type="text"
                    value={connectorHeaders?.header ?? ''}
                    handleChange={handleChangeNewInput}
                    errorDiv={hasError('header') ? 'text-danger' : 'd-none'}
                    errorMsg={'Поле не может быть пустым'}
                />
            </div>
            <div className="col-sm-6">
                <Input
                    className={hasError('value') ? 'is-invalid' : ''}
                    name="value"
                    type="text"
                    value={connectorHeaders?.value ?? ''}
                    handleChange={handleChangeNewInput}
                    errorDiv={hasError('value') ? 'text-danger' : 'd-none'}
                    errorMsg={'Поле не может быть пустым'}
                />
            </div>
            <div className="col-sm-1 text-end pl-0 mb-3">
                <button className="btn btn-primary" onClick={addHeaders}>
                    <i className="fas fa-plus" />
                </button>
            </div>
        </>
    );
};

export default ConnectorsHeaderInput;
