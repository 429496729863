import React, { useEffect, useMemo, useState } from 'react';
import '../assets/App.css';
import Sidebar from '../components/SideBar/Sidebar';
import Navigation from '../components/Navigation';
import AppRoute from '../AppRoute';
import Footer from '../components/Footer';
import { AvatarContext } from '../Context/AvatarContext';
import { IAvatarContext, IWs } from '../types/contextType';

interface DocumentWithFullscreen extends HTMLDocument {
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
    msExitFullscreen?: () => void;
    mozCancelFullScreen?: () => void;
    webkitExitFullscreen?: () => void;
    exitFullscreen: () => Promise<void>;
}

interface Element {
    msRequestFullscreen?: () => void;
    requestFullscreen?: () => void;
    webkitRequestFullscreen?: () => void;
}

const DefaultLayout = ({ ws }: IWs) => {
    const [showSidebar, setShowSidebar] = useState(true);
    const [fullscreen, setFullscreen] = useState(false);
    const [isPost, setIsPost] = useState(false);
    const appRef = React.createRef<HTMLDivElement>();

    useEffect(() => {
        setFullscreen((fullscreen) => fullscreen);
    }, [fullscreen]);

    const toggleFullscreen = () => {
        const elem = appRef.current as Element;
        const document = window.document as DocumentWithFullscreen;

        if (document.fullscreenElement === null) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) {
                /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                /* IE11 */
                elem.msRequestFullscreen();
            }
            setFullscreen(true);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                /* IE11 */
                document.msExitFullscreen();
            }
            setFullscreen(false);
        }
    };

    const value: IAvatarContext = useMemo(
        () => ({
            isPost,
            setIsPost
        }),
        [isPost]
    );

    return (
        <div className="wrapper" ref={appRef}>
            <Sidebar showSidebar={showSidebar} />
            <div className="main">
                <AvatarContext.Provider value={value}>
                    <Navigation
                        onSidebar={() => setShowSidebar(!showSidebar)}
                        onFullscreen={() => toggleFullscreen()}
                        ws={ws}
                    />
                    <div className="wrapper-spinner d-none" id="preloader">
                        <div className="spinner-border text-primary me-2" role="status" />
                    </div>
                    <AppRoute ws={ws} />
                </AvatarContext.Provider>
                <Footer />
            </div>
        </div>
    );
};

export default DefaultLayout;
