import React, { useMemo } from 'react';
import { COLUMNS } from './RulesColumns';
import AllowLayout from '../../Layouts/AllowLayout';
import AdvancedPage from '../../components/Table/AdvancedPage/AdvancedPage';
import { Permissions } from '../../utils/rules';

const Rules = () => {
    const columns = useMemo(() => COLUMNS, []);

    return (
        <main className="content">
            <AllowLayout
                children={
                    <AdvancedPage
                        pageHeader={'Правила'}
                        btnAddUrl={'/admin/rules/create'}
                        btnAddPermissions={Permissions.WRITE_INTEGRATION_CONNECTOR}
                        btnDelPermissions={Permissions.DELETE_INTEGRATION_CONNECTOR}
                        url={window.env.REACT_APP_API_URL + '/api/alerts/rules'}
                        columns={columns}
                    />
                }
                permissions={Permissions.READ_SENSOR_DRIVER}
            />
        </main>
    );
};

export default Rules;
