interface IUser {
    created_at: string;
    email: string;
    email_verified_at: string;
    id: number;
    last_name: string;
    middle_name: string;
    name: string;
    phone: string;
    role: string;
    timezone_id: number;
    updated_at: string;
    user_id: number;
}

interface IAuth {
    token: string;
    user: IUser;
}

class Auth {
    authenticated: boolean;
    user: IUser | undefined;
    constructor() {
        this.authenticated = false;
    }

    checkJwtToken() {
        this.authenticated = !!localStorage.getItem('datanorm.jwt');
    }

    login(userData: IAuth, cb?: () => void) {
        this.authenticated = true;
        this.user = userData.user;
        cb && cb();
    }

    jwt() {
        return localStorage.getItem('datanorm.jwt');
    }

    logout() {
        this.authenticated = false;
        localStorage.removeItem('datanorm.jwt');
        localStorage.removeItem('datanorm.user');
        window.location.href = '/login';
    }

    isAuthenticated() {
        return this.authenticated;
    }
}

export default new Auth();
