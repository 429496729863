import { Link } from 'react-router-dom';
import { ColumnFilter } from '../../components/ColumnFilter';
import { ColumnFilterSolution } from './Filters/ColumnFilterSolution';
import { ColumnFilterTrouble } from './Filters/ColumnFilterTrouble';
import { ColumnFilterStatus } from './Filters/ColumnFilterStatus';
import { ColumnFilterEmployee } from './Filters/ColumnFilterEmployee';
import { ColumnFilterUsersLike } from './Filters/ColumnFilterUsersLike';
import { Cell } from 'react-table';
import { IRow } from '../types';
import { getFormatDate } from '../../utils/utils';

export const COLUMNS = [
    {
        Header: 'Id',
        accessor: 'id',
        Filter: ColumnFilter,
        disableFilters: true
    },
    {
        Header: '№ заявки',
        accessor: 'uid',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ row }: IRow) => {
            return (
                <Link to={`/admin/tickets/${row.original.id}/subtickets`}>{row.original.uid}</Link>
            );
        }
    },
    {
        Header: 'Статус',
        accessor: 'status',
        Filter: ColumnFilterStatus
    },
    {
        Header: 'DevEUI',
        accessor: 'deveuis',
        Cell: ({ value }: Cell) => {
            return value ? value.split(',').join(', ') : '—';
        },
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'ObjectID',
        accessor: 'oids',
        Cell: ({ value }: Cell) => {
            return value ? value.split(',').join(', ') : '—';
        },
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'Ремонтники',
        accessor: 'employees',
        Cell: ({ value }: Cell) => {
            return value ? value : '—';
        },
        Filter: ColumnFilterEmployee,
        FilterLike: true
    },
    {
        Header: 'Проблема',
        accessor: 'trouble',
        Cell: ({ value }: Cell) => {
            return value ? value : '—';
        },
        Filter: ColumnFilterTrouble
    },
    {
        Header: 'Решение',
        Cell: ({ value }: Cell) => {
            return value ? value : '—';
        },
        accessor: 'solution',
        Filter: ColumnFilterSolution
    },
    {
        Header: 'Адрес',
        accessor: 'address',
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'Участники',
        accessor: 'users',
        Filter: ColumnFilterUsersLike,
        FilterLike: true
    },
    {
        Header: 'Дата задачи',
        accessor: 'sub_created_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        }
    },
    {
        Header: 'Создание заявки',
        accessor: 'created_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        }
    },
    {
        Header: 'Изменение заявки',
        accessor: 'updated_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        }
    }
];
