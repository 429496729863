import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { getClientList, getSensors } from '../../redux/sensors';
import AdvancedPage from '../../components/Table/AdvancedPage/AdvancedPage';

import { COLUMNS } from './SensorColumns';
import { Permissions } from '../../utils/rules';
import type { TId } from '../types';
import { sensors } from '../../api/mockUrls';
import AllowLayout from '../../Layouts/AllowLayout';

const Sensors = () => {
    const columns = useMemo(() => COLUMNS, []);
    const dispatch = useAppDispatch();
    const { id } = useParams<TId>();

    useEffect(() => {
        dispatch(getSensors());
        dispatch(getClientList());
    }, []);

    return (
        <main className="content">
            <AllowLayout
                children={
                    <AdvancedPage
                        pageHeader={'Устройства'}
                        btnAddUrl={'/admin/sensors/create'}
                        btnAddPermissions={Permissions.WRITE_SENSOR}
                        btnDelPermissions={Permissions.DELETE_SENSOR}
                        url={window.env.REACT_APP_API_URL + `${sensors(id)}`}
                        columns={columns}
                        csvExport={true}
                        csvFilename={'dh-sensors'}
                        selectGroups={true}
                        dltIdArray={true}
                    />
                }
                permissions={Permissions.READ_SENSOR}
            />
        </main>
    );
};

export default Sensors;
