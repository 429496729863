import React, { useMemo } from 'react';
import AdvancedPage from '../../components/Table/AdvancedPage/AdvancedPage';
import { COLUMNS } from './MetricColumns';
import AllowLayout from '../../Layouts/AllowLayout';
import { Permissions } from '../../utils/rules';

const Metrics = () => {
    const columns = useMemo(() => COLUMNS, []);

    return (
        <main className="content">
            <AllowLayout
                children={
                    <AdvancedPage
                        pageHeader={'Метрики'}
                        url={window.env.REACT_APP_API_URL + '/api/metrics'}
                        columns={columns}
                    />
                }
                permissions={Permissions.READ_CLIENT_TENANT}
            />
        </main>
    );
};

export default Metrics;
