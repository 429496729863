import React, { useMemo } from 'react';
import AdvancedPage from '../../components/Table/AdvancedPage/AdvancedPage';
import { COLUMNS } from './EmployeeColumns';
import { Permissions } from '../../utils/rules';
import AllowLayout from '../../Layouts/AllowLayout';

const Employees = () => {
    const columns = useMemo(() => COLUMNS, []);

    return (
        <main className="content">
            <AllowLayout
                children={
                    <AdvancedPage
                        pageHeader={'Ремонтники'}
                        btnAddUrl={'/admin/employees/create'}
                        btnAddPermissions={Permissions.WRITE_SUPPORT_EMPLOYEE}
                        btnDelPermissions={Permissions.DELETE_SUPPORT_EMPLOYEE}
                        url={window.env.REACT_APP_API_URL + '/api/employees'}
                        columns={columns}
                    />
                }
                permissions={Permissions.WRITE_SUPPORT_EMPLOYEE}
            />
        </main>
    );
};

export default Employees;
