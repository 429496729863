import { ColumnFilter } from '../../../components/ColumnFilter';
import { ColumnFilterEmployee } from '../Filters/ColumnFilterEmployee';
import { ColumnFilterSolution } from '../Filters/ColumnFilterSolution';
import { ColumnFilterTrouble } from '../Filters/ColumnFilterTrouble';
import { ColumnFilterUsers } from '../Filters/ColumnFilterUsers';
import { Cell } from 'react-table';
import { IRow } from '../../types';
import { getFormatDate } from '../../../utils/utils';

export const COLUMNS = [
    {
        Header: 'DevEUI',
        accessor: 'deveuis',
        Cell: ({ value }: Cell) => {
            return value ? value.split(',').join(', ') : '—';
        },
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'ObjectID',
        accessor: 'oids',
        Cell: ({ value }: Cell) => {
            return value ? value.split(',').join(', ') : '—';
        },
        Filter: ColumnFilter,
        FilterLike: true
    },
    {
        Header: 'Проблема',
        accessor: 'trouble',
        Filter: ColumnFilterTrouble
    },
    {
        Header: 'Решение',
        accessor: 'solution',
        Filter: ColumnFilterSolution
    },
    {
        Header: 'Ремонтники',
        accessor: 'employees',
        Filter: ColumnFilterEmployee,
        FilterLike: true
    },
    {
        Header: 'Автор',
        accessor: 'user_id',
        Filter: ColumnFilterUsers,
        Cell: ({ row }: IRow) => {
            return row.original.last_name + ' ' + row.original.first_name;
        }
    },
    {
        Header: 'Дата создания',
        accessor: 'created_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        }
    },
    {
        Header: 'Дата изменения',
        accessor: 'updated_at',
        Filter: ColumnFilter,
        FilterLike: true,
        Cell: ({ value }: Cell) => {
            return getFormatDate(value);
        }
    },
    {
        id: 'Edit',
        Header: 'Действия',
        accessor: 'actions',
        Filter: []
    }
];
