import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { TAppError } from './types';
import { ISettings } from './types';

const initialBells = {
    alert: false,
    avatar: '',
    id: 0,
    name: '',
    email: '',
    email_verified_at: '',
    created_at: '',
    updated_at: '',
    user_id: 0,
    role: '',
    last_name: '',
    middle_name: '',
    phone: '',
    timezone_id: null,
    timezone: ''
};

const initialState: TSettings = {
    loading: false,
    error: null,
    success: false,
    settings: initialBells
};

export const projectSettingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setSettings: (state, action: PayloadAction<ISettings>) => {
            state.settings = action.payload;
        },
        setSettingsError: (state, action: PayloadAction<TAppError | null>) => {
            state.error = action.payload;
        },
        setSettingsLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setSettingsSuccess: (state, action: PayloadAction<boolean>) => {
            state.success = action.payload;
        }
    }
});

export const settingsReducer = projectSettingsSlice.reducer;

export const { setSettingsLoading, setSettings, setSettingsError, setSettingsSuccess } =
    projectSettingsSlice.actions;

export type TSettings = {
    loading: boolean;
    error: null | TAppError;
    success: null | boolean;
    settings: ISettings;
};
