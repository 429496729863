import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { Disc } from 'react-feather';
import './PopupWebsocket.css';
import type { INotification } from '../../../types/contextType';
import { getFormatDate } from '../../../utils/utils';
import { updateData } from '../../../api';

interface IPopupWebsocket {
    messages: INotification[];
    handleDelete: (id: number) => void;
    handleDeleteAllBells: () => void;
}

interface IClose {
    onClose: () => void;
}

const PopupWebsocket = ({ messages, handleDelete, handleDeleteAllBells }: IPopupWebsocket) => {
    const options = (title: string, message: string, id: number) => ({
        afterClose: () => {
            handleDelete(id);
            updateData(`/api/alerts/bells/${id}`, { message });
        },
        customUI: ({ onClose }: IClose) => {
            return (
                <div className="react-confirm-alert-body popup-notification">
                    <span
                        className="popup-notification__close"
                        onClick={() => {
                            handleDelete(id);
                            onClose();
                        }}
                    />
                    <h1>{title}</h1>
                    <p>{message}</p>
                </div>
            );
        }
    });

    return (
        <div
            className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0"
            aria-labelledby="alertsDropdown"
            data-bs-popper="none">
            <div className="dropdown-menu-header">{messages.length} новых уведомления</div>
            <div className="list-group">
                {messages.map((message: INotification) => {
                    return (
                        <div
                            key={message.id}
                            className="list-group-item"
                            onClick={() => {
                                confirmAlert(options(message.name, message.body, message.id));
                            }}>
                            <div className="row g-0 align-items-center">
                                <div className="col-2">
                                    <Disc strokeWidth={1.5} size={22} />
                                </div>
                                <div className="col-10">
                                    <div>
                                        <div className="text-dark">{message.name}</div>
                                        <div className="text-muted small mt-1">{message.body}</div>
                                        <div className="text-muted small mt-1">
                                            {getFormatDate(message.created_at)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="dropdown-menu-footer bell-footer">
                <Link to="/admin/alarms" className="text-muted">
                    <span>Показать все уведомления</span>
                </Link>
                {messages.length > 0 && (
                    <div className="text-muted pt-1">
                        <span className="link cursor-pointer" onClick={handleDeleteAllBells}>
                            Прочитатать все уведомления
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(PopupWebsocket);
