import React, { FC } from 'react';
import Input from '../../../components/Ui/components-form/Input';
import { ICondArr, IKeyArr, IRule } from '../../types';

interface IOptionProps {
    option: IRule;
    conditionArr: ICondArr[];
    keyArr: IKeyArr[];
    deleteField: (id?: number | string) => void;
    handleEditSelect: (
        e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
        name: string,
        id?: number | string
    ) => void;
}

const RulesConditionItem: FC<IOptionProps> = ({
    option,
    conditionArr,
    keyArr,
    handleEditSelect,
    deleteField
}) => {
    return (
        <>
            <div className="col-sm-5">
                <select
                    className="form-select form-select-sm form-control"
                    name="key"
                    onChange={(e) => handleEditSelect(e, 'key', option.id)}
                    value={option.key || ''}>
                    {keyArr.map((option) => (
                        <option key={option.id} value={option.key}>
                            {option.key}
                        </option>
                    ))}
                </select>
            </div>
            <div className="col-sm-3">
                <select
                    className="form-select form-select-sm form-control"
                    name="condition"
                    onChange={(e) => handleEditSelect(e, 'condition', option.id)}
                    value={option.condition || ''}>
                    {conditionArr.map((option) => (
                        <option key={option.id} value={option.condition}>
                            {option.condition}
                        </option>
                    ))}
                </select>
            </div>
            <div className="col-sm-3">
                <Input
                    name="value"
                    type="text"
                    handleChange={(e) => handleEditSelect(e, 'value', option.id)}
                    value={option.value || ''}
                />
            </div>
            <div className="col-sm-1 text-end pl-0 mb-3">
                <button className="btn btn-primary" onClick={() => deleteField(option.id)}>
                    <i className="fas fa-minus" />
                </button>
            </div>
        </>
    );
};

export default RulesConditionItem;
