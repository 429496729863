export let employees: string;
export let solutions: string;
export let troubles: string;
export let support: string;
export let clients: string;
export let devius: string;
export let connectors: string;
export let subticketsEdit: (ticket?: string, subticket?: string) => string;
export let subtickets: (id?: string) => string;
export let sensors: (id?: string) => string;
export let subticketsEvent: string;
export let subticketsStore: (ticket?: string, subticket?: string) => string;
export const ticketsCreate = `/api/tickets/`;
export let groupsCreate: string;
export let groupsList: string;
export let sensorsGroups: string;
export let profileApi: string;
export let clientsList: string;
export let driversLink: string;
export let vendorsLink: string;
export let typeMetricLink: string;
export let typeByteLink: string;
export let formulaLink: string;
export let metricListLink: string;
export let decoderCheck: string;

if (window.env.REACT_APP_API_URL === 'http://localhost:5004') {
    employees = `/employees`;
    solutions = `/solutions`;
    troubles = `/troubles`;
    support = `/support`;
    clients = `/list`;
    clientsList = `/clients`;
    devius = `/oid/?oid=`;
    connectors = `/connectors`;
    groupsCreate = `/groups`;
    subticketsEvent = `/oids/?`;
    groupsList = `/list`;
    sensorsGroups = `/sensor-groups/`;
    subtickets = () => `/subtickets`;
    sensors = () => `/sensors`;
    subticketsEdit = (subticket) => `/subtickets/${subticket}`;
    subticketsStore = (subticket) => `/subtickets/${subticket}`;
    profileApi = '/profile';
    driversLink = '/drivers';
    vendorsLink = '/vendors';
    typeMetricLink = '/typeMetric';
    typeByteLink = '/typeByte';
    formulaLink = '/formula';
    metricListLink = '/metricList';
    decoderCheck = '/decoderCheck';
} else {
    employees = `/api/employees/list`;
    solutions = `/api/solutions/list`;
    troubles = `/api/troubles/list`;
    support = `/api/users/support/list`;
    clients = `/api/clients/list`;
    devius = `/oid/?oid=`;
    connectors = `/api/connectors`;
    groupsCreate = `/api/groups/`;
    subticketsEvent = `/api/sensors/oids/?`;
    groupsList = `/api/sensor-groups/list`;
    clientsList = `/api/clients/list`;
    sensorsGroups = `/api/sensor-groups/`;
    sensors = () => `/api/sensors`;
    subtickets = (id?: string) => `/api/tickets/${id}/subtickets`;
    subticketsEdit = (ticket?: string, subticket?: string) =>
        `/api/tickets/${ticket}/subtickets/${subticket}/edit`;
    subticketsStore = (ticket?: string, subticket?: string) =>
        `/api/tickets/${ticket}/subtickets/${subticket}`;
    profileApi = '/api/profile';
    driversLink = '/api/drivers';
    vendorsLink = '/api/vendors';
    typeMetricLink = '/api/driver/metrictypes';
    typeByteLink = '/api/driver/bytetypes';
    formulaLink = '/api/driver/formulas';
    metricListLink = '/api/driver/metrics';
    decoderCheck = '/api/driver/metrics/check/json?payload=';
}
