import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import useError from '../../hooks/useError';
import { useAlert } from '../../hooks/useAlert';
import { useDriver } from '../../hooks/useDriver';
import { updateFetchData } from '../../api';
import Alert from '../../components/Ui/components-ui/Alert';
import AllowLayout from '../../Layouts/AllowLayout';
import Tabs from '../../components/Ui/components-ui/Tabs';
import TabContent from '../../components/Ui/components-ui/TabContent';
import Tab from '../../components/Ui/components-ui/Tab';
import Decoder from './Decoder/Decoder';
import Metric from './Metric/Metric';
import DriverForm from './DriverForm';
import { loadingData } from '../../utils/utils';
import { Permissions } from '../../utils/rules';
import JsonEditor from '../connectors/JsonEditor';
import { TTab } from '../sensors/type';
import { Eventinfo, ITabs, TId } from '../types';

interface IDriverFormValid {
    name: string;
    version: string;
    payload: string;
    vendor_id: number | 0;
}

const driverTabs: ITabs[] = [
    { id: 0, name: 'Main' },
    { id: 1, name: 'Metrics' },
    { id: 2, name: 'Decoder' }
];

const DriverEdit = () => {
    const {
        isLoaded,
        driver,
        data,
        formula,
        metric,
        typeMetric,
        typeByte,
        driverOptions,
        setDriver,
        deleteMetric,
        setReload,
        reload,
        decoder,
        setDecoder
    } = useDriver();

    const {
        validateField,
        clearError,
        getErrorMessage,
        hasError,
        hasErrors,
        setError,
        clearAllErrors
    } = useError();

    const { alert, showAlert, clearAlert } = useAlert();

    const history = useHistory();
    const location = useLocation();
    const { id } = useParams<TId>();
    const initialTab = location.hash.replace('#tab-', '');
    const [activeTab, setActiveTab] = useState<string | null>(initialTab);
    const [result, setResult] = useState(null);

    useEffect(() => {
        loadingData(isLoaded);
    }, [isLoaded]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setDriver({ ...driver, [name]: value });
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;

        if (value !== '0') {
            clearError(name);
            setDriver({ ...driver, [name]: value });
        }
    };

    const checkboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked;
        const name = event.target.name;
        setDriver({ ...driver, [name]: value });
    };

    const commentChange = (event: Eventinfo, editor: ClassicEditor) => {
        const data = editor.getData();
        setDriver({ ...driver, comment: data });
    };

    const onClickTab = (tab: TTab) => {
        setActiveTab(tab.target);
    };

    const validateForm = (): boolean => {
        clearAllErrors();

        const validations: {
            [K in keyof IDriverFormValid]: (value: IDriverFormValid[K]) => boolean;
        } = {
            name: (value) => value.trim() === '',
            payload: (value) => value.trim() === '',
            version: (value) => value.trim() === '',
            vendor_id: (value) => value === 0
        };

        return Object.entries(validations).reduce((isValid: boolean, [key, validate]) => {
            const value = driver[key as keyof IDriverFormValid];
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (validate(value)) {
                setError(key, 'Это поле необходимо заполнить');
                return false;
            }
            return isValid;
        }, true);
    };

    const onSubmit = async (
        e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>
    ) => {
        e.preventDefault();
        clearAlert();
        const isFormValid = validateForm();

        if (hasErrors() || !isFormValid) {
            console.log('Форма содержит ошибки. Отправка данных отменена.');
            return;
        }

        const postData = {
            ...driver,
            preview: JSON.stringify(result),
            metrics: JSON.stringify(decoder)
        };

        updateFetchData(`/api/drivers/${id}/`, postData)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    history.push({
                        pathname: '/admin/drivers/'
                    });
                } else {
                    showAlert('alert-danger', 'Unexpected response status: ' + response.status);
                }
            })
            .catch((error) => {
                showAlert('alert-danger', 'Invalid response code: ' + error.status);
            });
    };

    return (
        <main className="content">
            <AllowLayout
                children={
                    <>
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-sm-block">
                                    <h3>Драйвер</h3>
                                </div>
                                <div className="col-auto ms-auto text-end mt-n1" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-7 col-xl-7">
                                {alert && (
                                    <Alert type={`mt-3 ${alert.type}`} message={alert.message} />
                                )}
                                <div className="tab">
                                    <Tabs>
                                        {driverTabs.map((tab) => {
                                            return (
                                                <Tab
                                                    key={tab.id}
                                                    onClickTab={onClickTab}
                                                    label={tab.name}
                                                    tab={tab.id}
                                                    param={location.search}
                                                    activeTab={Number(activeTab)}
                                                />
                                            );
                                        })}
                                    </Tabs>
                                    <TabContent activeContent={Number(activeTab)}>
                                        <div className="tab-pane" id="tab-0" role="tabpanel">
                                            <DriverForm
                                                driver={driver}
                                                data={data}
                                                hasError={hasError}
                                                handleChange={handleChange}
                                                driverOptions={driverOptions}
                                                commentChange={commentChange}
                                                onSubmit={onSubmit}
                                                validateField={validateField}
                                                getErrorMessage={getErrorMessage}
                                                handleSelectChange={handleSelectChange}
                                                checkboxChange={checkboxChange}
                                            />
                                        </div>
                                        <div className="tab-pane" id="tab-1" role="tabpanel">
                                            <Metric
                                                metric={metric}
                                                typeMetric={typeMetric}
                                                typeByte={typeByte}
                                                isLoaded={isLoaded}
                                                deleteMetric={deleteMetric}
                                                showAlert={showAlert}
                                                setReload={setReload}
                                                reload={reload}
                                            />
                                        </div>
                                        <div className="tab-pane" id="tab-2" role="tabpanel">
                                            <Decoder
                                                driver={driver}
                                                metric={metric}
                                                formula={formula}
                                                handleChange={handleChange}
                                                showAlert={showAlert}
                                                setResult={setResult}
                                                decoder={decoder}
                                                isLoaded={isLoaded}
                                                setDecoder={setDecoder}
                                            />
                                        </div>
                                    </TabContent>
                                    <div className="mt-3">
                                        <button
                                            onClick={onSubmit}
                                            type="submit"
                                            className="btn btn-primary">
                                            Сохранить
                                        </button>{' '}
                                        <Link
                                            to="/admin/drivers"
                                            className="btn btn-outline-secondary">
                                            Назад
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {activeTab === '2' && (
                                <div className="col-md-5 col-xl-5 mt-5">
                                    <div className="col-12">
                                        <h4
                                            className={`mb-3 ${
                                                hasError('example') ? 'is-invalid' : ''
                                            }`}>
                                            Вы разобрали следующие метрики:
                                            <div className="mt-3">
                                                <JsonEditor
                                                    value={result || JSON.parse(driver.preview)}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                }
                permissions={Permissions.READ_SENSOR_DRIVER}
            />
        </main>
    );
};

export default DriverEdit;
