import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import Input from '../../components/Ui/components-form/Input';
import Alert from '../../components/Ui/components-ui/Alert';
import { Permissions, User, Rules } from '../../utils/rules';
import { AbacProvider, AllowedTo } from 'react-abac';
import { TId } from '../types';
import { getFetchData, updateFetchData } from '../../api';

const TicketStatusEdit = () => {
    const [role, setRole] = useState('');

    const history = useHistory();
    const { id } = useParams<TId>();

    const [isLoaded, setIsLoaded] = useState(true);
    const [status, setStatus] = useState({
        name: ''
    });

    const [errors, setErrors] = useState<string[]>([]);
    const [alert, setAlert] = useState({
        type: 'd-none',
        message: ''
    });
    const [data, setData] = useState('');

    useEffect(() => {
        const storage = localStorage.getItem('datanorm.user');
        setRole(storage ? storage.toUpperCase() : 'USER');

        setIsLoaded(false);
        getFetchData(`/api/tickets/statuses/${id}/`)
            .then((response) => {
                setAlert({
                    type: 'd-none',
                    message: ''
                });
                setStatus(response);
            })
            .then(() => setIsLoaded(true))
            .catch((error) => {
                setAlert({
                    type: 'alert-danger',
                    message: 'Invalid response code: ' + error.status
                });
            });

        setData((data) => data);
    }, [id, data]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setStatus({ ...status, [name]: value });
    };

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errs = [];
        if (status.name === '') errs.push('name');

        setErrors(errs);
        if (errs.length > 0) return false;

        const data = new FormData(e.target);
        const payload = Object.fromEntries(data.entries());

        updateFetchData(`/api/tickets/statuses/${id}/`, payload)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    history.push({
                        pathname: '/admin/ticket-statuses/'
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    type: 'alert-danger',
                    message: 'Invalid response code: ' + error.status
                });
            });
    };

    const hasError = (key: number | string) => {
        return errors.indexOf(String(key as number)) !== -1;
    };

    // Preloader
    if (!isLoaded) {
        const elem = document.getElementById('preloader');
        if (elem) elem.classList.remove('d-none');
    } else if (isLoaded) {
        const elem = document.getElementById('preloader');
        if (elem) elem.classList.add('d-none');
    }

    return (
        <main className="content">
            <div className="container-fluid p-0">
                <div className="row mb-2 mb-xl-3">
                    <div className="col-auto d-sm-block">
                        <h3>Статус</h3>
                    </div>
                    <div className="col-auto ms-auto text-end mt-n1" />
                </div>
            </div>
            <form onSubmit={onSubmit}>
                <div className="col-md-6 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <Alert type={alert.type} message={alert.message} />
                            <Input
                                className={hasError('name') ? 'is-invalid' : ''}
                                title="Название"
                                name="name"
                                type="text"
                                value={status.name || ''}
                                handleChange={handleChange}
                                errorDiv={hasError('name') ? 'text-danger' : 'd-none'}
                                errorMsg={'Поле не может быть пустым'}
                                required={true}
                            />
                            * — обязательные поля
                        </div>
                    </div>
                </div>

                <AbacProvider user={User} roles={[role]} rules={Rules}>
                    <AllowedTo perform={Permissions.WRITE_SUPPORT_TICKET_STATUS}>
                        <button type="submit" className="btn btn-primary">
                            Сохранить
                        </button>{' '}
                    </AllowedTo>
                </AbacProvider>
                <Link to="/admin/ticket-statuses" className="btn btn-outline-secondary">
                    Назад
                </Link>
            </form>
        </main>
    );
};

export default TicketStatusEdit;
