import React, { useState } from 'react';
import Avatar from 'react-avatar-edit';
import { Avatar as Ava, Dialog } from '@material-ui/core';
import { Edit2, Save, FilePlus } from 'react-feather';
import Alert from '../../components/Ui/components-ui/Alert';
import imgAva from '../../assets/img/ava.jpeg';
import type { IProfile } from '../types';
import { updateSensorsData } from 'src/api';

interface IPreview {
    preview: string;
}

interface AvatarDialogProps {
    profile: IProfile;
    setIsPost: (isPost: boolean) => void;
}

const AvatarDialog = ({ profile, setIsPost }: AvatarDialogProps) => {
    const [preview, setPreview] = useState('');
    const [file, setFile] = useState<File>();
    const [profileAvatar, setProfileAvatar] = useState<IPreview | null>(null);
    const [imageCrop, setImageCrop] = useState(false);

    const [alert, setAlert] = useState({
        type: 'd-none',
        message: ''
    });

    const onClose = () => {
        setPreview('');
    };

    const onCrop = async (view: string) => {
        setPreview(view);
    };

    const onDialogClose = () => {
        setImageCrop(false);
        setProfileAvatar(null);
        setFile(undefined);
        setIsPost(false);
    };

    const onBeforeFileLoad = (elem: React.ChangeEvent) => {
        const target = elem.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        setFile(file);
        if (file.size > 171680) {
            setAlert({
                type: 'alert-danger',
                message: 'Слишком большой файл'
            });
            setFile(undefined);
            target.value = '';
        } else {
            setAlert({
                type: '',
                message: ''
            });
        }
    };

    const saveCorpImage = async () => {
        setProfileAvatar({ preview });
        setImageCrop(false);
        onDialogClose();
        await updateSensorsData('/api/profile/avatar', { avatar: preview }).then(() =>
            setIsPost(true)
        );
    };

    return (
        <div className="card">
            <div className="card-body text-center">
                <div className="d-flex justify-content-center">
                    <Ava
                        className="mb-2 avatar-img"
                        src={profile.avatar || imgAva}
                        alt="avatar"
                        style={{ width: '128px', height: '128px' }}
                    />
                </div>
                <h5 className="card-title mb-0 min-h-17">{profile.name}</h5>
                <div className="text-muted mb-2 min-h-21">{profile.role}</div>
                <Dialog onClose={onDialogClose} open={imageCrop}>
                    <div style={{ position: 'relative', overflow: 'hidden' }}>
                        <div style={{ position: 'absolute', width: '100%' }}>
                            <Alert type={alert.type} message={alert.message} />
                        </div>
                        <Avatar
                            width={390}
                            height={295}
                            onCrop={onCrop}
                            onClose={onClose}
                            onBeforeFileLoad={onBeforeFileLoad}
                            src={profileAvatar?.preview}
                            label={
                                <div className="d-flex align-items-center">
                                    <FilePlus color="#00000080" size={25} className="mr-2" />{' '}
                                    <span
                                        style={{ color: '#00000080' }}
                                        className="font-weight-normal">
                                        Выберите файл
                                    </span>
                                </div>
                            }
                        />
                        {file && (
                            <button
                                className="btn btn-primary btn-sm"
                                style={{
                                    position: 'absolute',
                                    bottom: '20px',
                                    left: '0',
                                    right: '0',
                                    width: '100px',
                                    margin: 'auto',
                                    fontFamily: 'Roboto, sans-serif',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                                onClick={async () => {
                                    await saveCorpImage();
                                }}>
                                <Save size={12} className="mr-1" />
                                Сохранить
                            </button>
                        )}
                    </div>
                </Dialog>

                <button className="btn btn-primary btn-sm" onClick={() => setImageCrop(true)}>
                    <Edit2 size={12} className="mr-1" />
                    Сменить
                </button>
            </div>
        </div>
    );
};

export default AvatarDialog;
