import React, { useMemo } from 'react';
import AdvancedPage from '../../components/Table/AdvancedPage/AdvancedPage';
import { COLUMNS } from './FormulaColumns';
import { Permissions } from '../../utils/rules';
import AllowLayout from '../../Layouts/AllowLayout';
import { formulaLink } from '../../api/mockUrls';

const Formula = () => {
    const columns = useMemo(() => COLUMNS, []);

    return (
        <main className="content">
            <AllowLayout
                children={
                    <AdvancedPage
                        pageHeader={'Формула'}
                        btnAddUrl={'/admin/formula/create'}
                        btnAddPermissions={Permissions.READ_SENSOR_DRIVER}
                        btnDelPermissions={Permissions.READ_SENSOR_DRIVER}
                        url={window.env.REACT_APP_API_URL + formulaLink}
                        columns={columns}
                    />
                }
                permissions={Permissions.READ_SENSOR_DRIVER}
            />
        </main>
    );
};

export default Formula;
