import React, { useMemo } from 'react';
import { Calendar } from 'react-feather';
import { useDate } from '../../../hooks/useDate';
import AdvancedPage from '../../../components/Table/AdvancedPage/AdvancedPage';
import CalendarPicker from '../../../components/Ui/CalendarPicker/CalendarPicker';
import PageLayout from '../../../Layouts/PageLayout';
import { COLUMNS } from './SensorHistoryColumnsAll';

const SensorsHistoriesAll = () => {
    const columns = useMemo(() => COLUMNS, []);

    const { startDate, setStartDate, endDate, setEndDate, formatStartDate, formatEndDate } =
        useDate();

    return (
        <PageLayout title={'Журнал Uplinks'}>
            <div className="calendar">
                <Calendar
                    className="calendar__icon calendar__icon--uplinks"
                    color="#3b7ddd"
                    size={18}
                />
                <CalendarPicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                />
            </div>
            <AdvancedPage
                url={window.env.REACT_APP_API_URL + '/api/sensors/histories/all'}
                columns={columns}
                startDate={formatStartDate}
                endDate={formatEndDate}
            />
        </PageLayout>
    );
};

export default SensorsHistoriesAll;
