import React, { FC, memo } from 'react';
import { uid } from '../../../utils/id';
import RulesConditionItem from './RulesConditionItem';
import RulesConditionAddField from './RulesConditionAddField';
import { ICondArr, IKeyArr, IRule, IRules } from '../../types';

interface IRuleConditionProp {
    rule: IRules;
    alarmRules: IRule;
    setRule: (arr: IRules) => void;
    handleAddNewField: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    handleAddHeaderInput: (e: React.MouseEvent<HTMLButtonElement>) => void;
    hasError: (key: string) => boolean;
}

const conditionArr: ICondArr[] = [
    { id: 1, condition: '>' },
    { id: 2, condition: '<' },
    { id: 3, condition: '=' }
];

const keyArr: IKeyArr[] = [
    { id: 1, key: 'battery_voltage' },
    { id: 2, key: 'battery' },
    { id: 3, key: 'status' }
];

const RulesCondition: FC<IRuleConditionProp> = ({
    rule,
    alarmRules,
    setRule,
    handleAddNewField,
    handleAddHeaderInput,
    hasError
}) => {
    const handleEditSelect = (
        e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
        name: string,
        id?: number | string
    ) => {
        setRule({
            ...rule,
            body: [
                {
                    rules: rule.body[0].rules.map((item) => {
                        if (item.id === id && e.target.name === name) {
                            return { ...item, [name]: e.target.value };
                        }
                        return item;
                    }),
                    channel: rule.body[0].channel
                }
            ]
        });
    };

    const deleteField = async (ids?: number | string) => {
        setRule({
            ...rule,
            body: [
                {
                    ...rule.body,
                    rules: rule.body[0].rules.filter((obj) => obj.id !== ids),
                    channel: rule.body[0].channel
                }
            ]
        });
    };

    return (
        <div className="row">
            <div className="col-sm-5 mb-2">
                <label>Свойство</label>
            </div>
            <div className="col-sm-3 mb-2">
                <label>Условие</label>
            </div>
            <div className="col-sm-3 mb-2">
                <label>Значение</label>
            </div>
            {rule.body
                ? rule.body[0]?.rules.map((option) => {
                      return (
                          <RulesConditionItem
                              key={uid()}
                              option={option}
                              conditionArr={conditionArr}
                              keyArr={keyArr}
                              handleEditSelect={handleEditSelect}
                              deleteField={deleteField}
                          />
                      );
                  })
                : ''}
            <RulesConditionAddField
                alarmRules={alarmRules}
                conditionArr={conditionArr}
                keyArr={keyArr}
                handleAddNewField={handleAddNewField}
                handleAddHeaderInput={handleAddHeaderInput}
                hasError={hasError}
            />
        </div>
    );
};

export default memo(RulesCondition);
