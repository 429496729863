import React, { useEffect } from 'react';
import { Column, SortingRule, TableState, useFilters, useSortBy, useTable } from 'react-table';
import { File } from 'react-feather';
import { IField, ISort } from '../type';

interface IListTable {
    columns: Column[];
    data: TableState[];
    total: number | string;
    page: number;
    pageSize: number;
    pageSizes: number[];
    totalPages: number;
    onPageSizeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    onChangeFilter: (field: IField) => Promise<void>;
    onSort: (sortByElement: SortingRule<ISort>) => void;
}

const ListTable = ({
    columns,
    data,
    total,
    page,
    pageSize,
    pageSizes,
    totalPages,
    onPageSizeChange,
    onChangeFilter,
    onSort
}: IListTable) => {
    const firstPageItem = page * pageSize + 1 - pageSize;
    const lastPageItem = page === totalPages ? total : page * pageSize;

    const {
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            manualSortBy: true,
            onChangeFilter
        },
        useFilters,
        useSortBy
    );

    // server-side by sort
    useEffect(() => {
        onSort(sortBy[0]);
    }, [sortBy]); // onSort будет вызывать бесконечность

    return (
        <>
            <div className="card">
                <div className="px-4 d-md-block">
                    <div className="d-flex align-items-center">
                        {headerGroups.map((headerGroup) =>
                            headerGroup.headers.map((column) => (
                                <div className="flex-grow-1 mt-3" key={'div' + column.id}>
                                    {column.canFilter ? column.render('Filter') : null}
                                </div>
                            ))
                        )}
                    </div>
                </div>
                <div className="card-body">
                    <ul className="list-group list-group-flush custom">
                        {rows.map((row) => {
                            prepareRow(row);
                            return row.cells.map((cell) => {
                                return (
                                    <li
                                        className="list-group-item"
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        key={'li' + cell?.id}>
                                        <span className="span-pr-w">
                                            <File size={14} />
                                            <span className="align-middle plc-2">
                                                {cell.render('Cell')}
                                            </span>
                                        </span>
                                        <span className="span-pl-w float-right">
                                            <i className="size-14 btn-del" data-feather="trash-2" />
                                        </span>
                                    </li>
                                );
                            });
                        })}
                    </ul>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="mb-3 pl-2">
                                {firstPageItem} &mdash; {lastPageItem} из {total}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-title">
                    <div className="pt-0 pl-3 pb-2">
                        <div className="wrapper-sizepages">
                            Показывать по{' '}
                            <select
                                className="form-select form-select-sm"
                                onChange={onPageSizeChange}
                                value={pageSize}>
                                {pageSizes.map((size: number) => (
                                    <option key={size} value={size}>
                                        {size}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListTable;
